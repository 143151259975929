<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Hotel</h4>
                  <h1>Calendario de Reservas</h1>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="calendar" class="mb-lg">
          <FullCalendar :events="events" :options="options" />
        </section>
      </div>
      <Footer></Footer>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline } from 'ionicons/icons';

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import iCalendarPlugin from '@fullcalendar/icalendar'
import esLocale from '@fullcalendar/core/locales/es';

import moment from 'moment';
import { defineComponent } from 'vue'
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Calendar',
  components: {
    FullCalendar,
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer
  },
    data() {
      return {
            options: {
              plugins: [
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin, // needed for dateClick
                iCalendarPlugin
              ],
              firstDay: 1,
              locale: esLocale,
              eventClick: this.details,
              headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
              },
              initialView: 'dayGridMonth',
              initialEvents: [],
              editable: false,
              selectable: true,
              selectMirror: true,
              dayMaxEvents: true,
              weekends: true,
              events: [],
            },


      };
    },
    computed: {
      ...mapState('hotel', {
        bookings: state => state.bookings,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('hotel',['getBookings', 'deleteBooking', 'updateBooking']),
      ...mapActions(['openDetails']),

      dateFormatter(date) {
        return moment(date).format('YYYY-MM-DD');
      },
      details(ev){
        const booking = this.bookings.find(x => x.objectID === ev.event._def.publicId);
        if(booking){
          this.openDetails({component: 'hotel-booking', data: booking});
        }
      }
    },
    created(){
        this.getBookings();

        setTimeout(() => {
          const colors= ['#03a9f4', '#9c27b0', '#f44336', '#4caf50', '#ff9800', '#009688', '#e91e63', '#e91e63', '#3f51b5']

          this.bookings.forEach((b, index) => {
            this.options.events.push({
                id: b.objectID,
                title  : b.customer.firstName + ', ' + b.customer.lastName + ' - ' + b.room,
                start  : moment(b.arrivalDate).format('YYYY-MM-DD'),
                end    : moment(b.departureDate).format('YYYY-MM-DD'),
                color  : colors[index],
                allDay : true // will make the time show
            });
          });
        }, 1000);
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, addCircleOutline };
    }
});
</script>

<style scoped>
  
</style>