import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBh_m545L-nLr3x4Tus63-Z0CYnlfZNFuE",
    authDomain: "evolbe-87cdd.firebaseapp.com",
    databaseURL: "https://evolbe-87cdd.firebaseio.com",
    projectId: "evolbe-87cdd",
    storageBucket: "evolbe-87cdd.appspot.com",
    messagingSenderId: "949599463070",
    appId: "1:949599463070:web:dd676b3ef1709f96e7bd8f",
    measurementId: "G-TQFEGR9C8V"
  };
  
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();

export const getFirestoreClient = (evolbeUser) => {
  let app: any = {};

  if (firebase.apps.length > 1) {
    app = firebase.app('app');
  } else {
    const firebaseConfig2 = evolbeUser.business[0].firebaseConfig;
    app = firebase.initializeApp(firebaseConfig2, 'app');

    app.firestore().settings({
      ignoreUndefinedProperties: true,
    });
  }

  return app.firestore();
}

export const getStorageClient = (evolbeUser) => {
  let app: any = {};

  if (firebase.apps.length > 1) {
    app = firebase.app('app');
  } else {
    const firebaseConfig2 = evolbeUser.business[0].firebaseConfig;
    app = firebase.initializeApp(firebaseConfig2, 'app');

    app.firestore().settings({
      ignoreUndefinedProperties: true,
    });
  }

  return app.storage();
}
