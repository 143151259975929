<template>
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="closeDetails">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Detalles</h5>
            <ion-buttons slot="end" style="margin: 0">
              <ion-button @click="confirmDeleteElement($event, data.objectID)" v-if="data.status === 5 && evolbeUser.business[0].rol !== 'analyst'">
                <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
              </ion-button>
              <ConfirmPopup></ConfirmPopup>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <div class="mt-xxs mb-xxs">
          <ion-list class="datos" lines="none">
              <ion-item>
                <ion-label><h6><b>#{{ data.objectID }}</b></h6></ion-label>
                <ion-chip style="margin: 0;" slot="end" @click="changeStatusToggle" :color="statuses[data.status].color">
                      <ion-label>{{ statuses[data.status].label }}</ion-label>
                </ion-chip>
                <Menu ref="menu" :model="statuses" :popup="true" />
              </ion-item>

              <ion-item>
                <ion-label text-wrap>
                  <h3 style="text-transform: capitalize;"><b>{{ data.customer.firstName }}, {{ data.customer.lastName }}</b></h3>
                </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Teléfono:</span>
                      <p><a :href="'tel:' + data.customer.phone" v-if="data.customer.phone">{{ data.customer.phone }}</a></p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Email:</span>
                      <p><a :href="'mailto:' + data.customer.email" v-if="data.customer.email">{{ data.customer.email }}</a></p>
                  </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha Reserva</span>
                      <p>{{ fecha(data.reservationDate) }}</p>
                  </ion-label>
              </ion-item>    

              <ion-item>
                  <ion-label text-wrap>
                      <span>Zona:</span>
                      <p>{{ data.zone }}</p>
                  </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Personas:</span>
                      <p>{{ data.persons }}</p>
                  </ion-label>
              </ion-item>    

              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha de realización de la Reserva:</span>
                      <p>{{ fecha(data.dateCreated) }}</p>
                  </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Observaciones:</span>
                      <p>{{ data.observations }}</p>
                  </ion-label>
              </ion-item>    
          </ion-list>

        </div>
    </div>
</template>

<script>
import { IonIcon, IonChip, IonButton, IonButtons, IonLabel, IonList, IonItem, IonHeader, IonToolbar, modalController } from '@ionic/vue';
import { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline } from 'ionicons/icons';

import ConfirmPopup from 'primevue/confirmpopup';

import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Menu from 'primevue/menu';

import bluetoothPrint from '@/utils/bluetooth-print.vue';
import otherPrint from '@/utils/other-print.vue';

export default defineComponent({
  name: 'BookingDetails',
  props: ['data'],
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonLabel,
    IonList,
    IonItem,
    ConfirmPopup,
    IonChip,
    IonHeader,
    IonToolbar,
    Menu
  },
     data() {
      return {
        statuses: [
          {
            label: 'Abandodano',
            color: 'dark',
            value: 0,
            command: () => {
              this.changeStatus(0);
            }
          },
          {
            label: 'Nuevo',
            color: 'secondary',
            value: 1,
            command: () => {
              this.changeStatus(1);
            }
          },
          {
            label: 'Confirmado',
            color: 'primary',
            value: 2,
            command: () => {
              this.changeStatus(2);
            }
          },
          {
            label: 'Sirviendo',
            color: 'warning',
            value: 3,
            command: () => {
              this.changeStatus(3);
            }
          },
          {
            label: 'Completado',
            color: 'success',
            value: 4,
            command: () => {
              this.changeStatus(4);
            }
          },
          {
            label: 'Cancelado',
            color: 'danger',
            value: 5,
            command: () => {
              this.changeStatus(5);
            }
          },          
        ],
      };
    },
    computed: {
        ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
         ...mapActions('bookings',['deleteBooking', 'updateBooking']),
         ...mapActions(['closeDetails']),

        fecha(date){
          return moment(date).format('DD/MM/YYYY - HH:mm');
        },

        fechaDia(date){
            return moment(date).format('DD/MM/YYYY');
        },

        nextStatus(){
          const newData = this.data;
          newData.status = this.data.status + 1;
          this.updateBooking({data: newData, id: newData.objectID});
        },

        changePaymentStatus(){
          if(this.evolbeUser.business[0].rol !== 'analyst'){
            if(this.data.payment.status === 'pending'){
              const newData = this.data;
              newData.payment.status = 'succeeded';
              this.updateBooking({data: newData, id: newData.objectID});
            } else if(this.data.payment.status === 'succeeded'){
              const newData = this.data;
              newData.payment.status = 'error';
              this.updateBooking({data: newData, id: newData.objectID});
            } else {
              const newData = this.data;
              newData.payment.status = 'pending';
              this.updateBooking({data: newData, id: newData.objectID});
            }
          }
        },

        async printTicket(){
            if(this.preferences && this.preferences.printType === 'bluetooth'){
              const modal = await modalController
                .create({
                  component: bluetoothPrint,
                  cssClass: 'auto-height',
                  mode: 'ios',
                  componentProps: {
                    data: this.data,
                    type: 'ticket',
                    copies: 1
                  },
                })
              return modal.present();
            } else{
              const modal = await modalController
                .create({
                  component: otherPrint,
                  cssClass: 'auto-height',
                  mode: 'ios',
                  componentProps: {
                    data: this.data,
                    type: 'ticket',
                    copies: 1
                  },
                })
              return modal.present();
            }
        },

        changeStatusToggle(event){
          if(this.evolbeUser.business[0].rol !== 'analyst'){
            this.$refs.menu.toggle(event);
          }
        },

        changeStatus(status){
          const newData = this.data;
          newData.status = status;
          this.updateBooking({data: newData, id: newData.objectID});
        },

        confirmCancelProduct(event){
          this.$confirm.require({
              target: event.currentTarget,
              message: '¿Seguro de que quieres cancelar este pedido?',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                  //callback to execute when evolbeUser confirms the action
                    const newData = this.data;
                    newData.status = 5;
                    this.updateBooking({data: newData, id: newData.objectID});
              },
              reject: () => {
                  //callback to execute when evolbeUser rejects the action
              }
          });
        },

        confirmDeleteElement(event){
          this.$confirm.require({
              target: event.currentTarget,
              message: '¿Seguro de que quieres eliminar este pedido permanentemente?',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                  //callback to execute when evolbeUser confirms the action
                    this.deleteBooking(this.data.objectID);
                    this.closeDetails();
              },
              reject: () => {
                  //callback to execute when evolbeUser rejects the action
              }
          });
        },
         
    },
    created(){
      moment.locale('es');
    },
    setup() {
        return { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline };
    }
});
</script>

<style scoped>

    .header-aside-container{
        max-width: 96rem;
        margin: 0 auto;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .header-details{
      height: 5rem;
      padding: 1rem 0;
    }

    .datos{
      padding: 0;
    }

    .datos ion-item{
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
    }

    .datos p{
        margin-top: 0.25rem;
    }


    .totals, .subplatos, .platos{
      padding: 0;
    }

    .plato{
      border-bottom: 1px solid var(--ion-color-light-shade);
      padding-bottom: 1rem;
    }

    .dish, .subdish{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .totals ion-item, .subplatos ion-item{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .dish ion-lablel, .subdish ion-lablel, .totals ion-item ion-label{
        margin: 0;
    }

    .dish .variation, .subdish .variation{
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: var(--ion-color-medium);
        min-height: 0.8rem;
    }

    .price{
        margin: 0;
        width: 108px;
        text-align: right;
    }

    .quantitys{
        width: 68px;
        margin-left: 0;
    }

    .quantitys ion-label{
        margin: 0 auto;
    }

    .subplatos{
        padding: 0;
    }

</style>