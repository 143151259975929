<template>
    <div class="item-details">
        <ion-header class="ion-no-border" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="closeDetails">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h4 style="padding-left: 0.5rem">Detalles</h4>
            <ion-buttons slot="end" style="margin: 0">
              <ion-button target="_blank" @click="printTicket" >
                <ion-icon color="dark" slot="icon-only" :icon="printOutline"></ion-icon>
              </ion-button>
              <ion-button @click="confirmDeleteElement($event, data.objectID)" v-if="data.status === 5 && evolbeUser.business[0].rol !== 'analyst'">
                <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
              </ion-button>
              <ConfirmPopup></ConfirmPopup>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <div class="py-xs">
          <ion-list class="datos" lines="none">
              <ion-item>
                <ion-label><h6><b>#{{ data.objectID }}</b></h6></ion-label>
                <ion-chip style="margin: 0;" slot="end" @click="changeStatusToggle" :color="statuses[data.status].color">
                      <ion-label>{{ statuses[data.status].label }}</ion-label>
                </ion-chip>
                <Menu ref="menu" :model="statuses" :popup="true" />
              </ion-item>

              <ion-item>
                <ion-label text-wrap>
                  <h2 style="text-transform: capitalize;"><b>{{ data.customer.firstName }}, {{ data.customer.lastName }}</b></h2>
                </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha de pedido:</span>
                      <p>{{ fecha(data.dateCreated) }}</p>
                  </ion-label>
              </ion-item>
              <ion-item  v-if="data.platform">
                  <ion-label text-wrap>
                      <span>Platafoma:</span>
                      <p>{{ data.platform }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Entrega:</span>
                      <p style="text-transform: capitalize;" v-if="data.delivery">{{ data.customer.address }}<span v-if="data.customer.city">, {{ data.customer.city  }}</span><span v-if="data.customer.zipCode">, {{ data.customer.zipCode  }}</span></p>
                      <p style="text-transform: capitalize;" v-if="!data.delivery || !data.customer.address">Recogida en el local</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Teléfono:</span>
                      <p><a :href="'tel:' + data.customer.phone" v-if="data.customer.phone">{{ data.customer.phone }}</a></p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha de entrega:</span>
                      <p>{{ fechaEntrega(data.deliveryDate) }}</p>
                  </ion-label>
              </ion-item>
              <ion-item v-if="data.payment">
                  <ion-label text-wrap>
                      <span>Pago</span>
                      <p>
                        <span v-if="data.payment.method === 'stripe'">Pago Online  -</span>
                        <span v-if="data.payment.method === 'cash'">Pago en Efectivo  -</span>
                        <span v-if="data.payment.method === 'dataphone'">Pago con Datáfono  -</span>
                        <ion-chip style="margin: 0; margin-left: 0.25rem" outline @click="changePaymentStatus" :color="data.payment.status === 'succeeded' ? 'success' : (data.payment.status === 'pending' ? 'warning' : 'danger')">
                          <ion-label v-if="data.payment.status === 'succeeded'">Exitoso</ion-label>
                          <ion-label v-if="data.payment.status === 'pending'">Pendiente</ion-label>
                          <ion-label v-if="data.payment.status !== 'succeeded' && data.payment.status !== 'pending'">Erroneo</ion-label>
                        </ion-chip>
                      </p>
                  </ion-label>
              </ion-item>
              <ion-item v-if="data.coupon">
                  <ion-label text-wrap>
                      <span>Cupón:</span>
                      <p>{{data.coupon.code}}</p>
                  </ion-label>
              </ion-item>
              <ion-item  v-if="data.observations">
                  <ion-label text-wrap>
                      <span>Observaciones:</span>
                      <p>{{ data.observations }}</p>
                  </ion-label>
              </ion-item>
          </ion-list>

        </div>

        <div class="bt py-xs">
            <ion-list lines="none" class="platos">
                <div v-for="dish in data.items" :key="dish.id" class="plato">
                    <ion-item class="dish">
                        <ion-label>
                            <span><b>{{ dish.name }}</b></span><br>
                            <span class="variation" v-if="dish.variations">
                                <p v-for="option in dish.variations[0].options.filter(x => x.selected === true)" :key="option">{{ option.text }} </p>
                            </span>
                        </ion-label>
                        
                        <ion-buttons slot="end" class="quantitys">                                    
                            <ion-label>{{ dish.quantity }}</ion-label>
                        </ion-buttons>

                        <p class="price" slot="end" v-if="dish.price">{{ (dish.price * dish.quantity).toFixed(2) }} €</p>
                        <p class="price" slot="end" v-if="!dish.price">GRATIS</p>
                    </ion-item>

                    <ion-list class="subplatos" v-if="dish.items" lines="none">
                        <ion-item v-for="item in dish.items" :key="item.id" class="subdish">
                            <ion-label class="name" text-wrap>
                                <span>{{ item.name }}</span>
                                <span class="variation" v-if="item.variations">
                                    <p v-for="option in item.variations[0].options.filter(x => x.selected === true)" :key="option">{{ option.text }} </p>
                                </span>
                            </ion-label>
                        
                            <ion-buttons slot="end" class="quantitys">                                       
                                <ion-label>{{ dish.quantity }}</ion-label>
                            </ion-buttons>

                            <p class="price" slot="end" v-if="dish.type === 'PROMO'">PROMO</p>
                            <p class="price" slot="end" v-if="dish.type === 'MENU'">MENU</p>
                        </ion-item>
                    </ion-list>
                </div>
            </ion-list>

            <ion-list class="totals" lines="none">
                <ion-item>
                    <ion-label class="weight-bold">Subtotal</ion-label>
                    <ion-label class="price weight-bold" slot="end">{{ data.subtotal.toFixed(2) }}€</ion-label>
                </ion-item>
                <ion-item v-if="data.coupon">
                    <ion-label class="weight-bold">Descuento Cupón ({{ data.coupon.discount }}%)</ion-label>
                    <ion-label class="price weight-bold" slot="end">-{{ data.discount.toFixed(2) }}€</ion-label>
                </ion-item>
                <ion-item v-if="data.surcharge">
                    <ion-label class="weight-bold">Recargo (Pedido Inferior a 15€)</ion-label>
                    <ion-label class="price weight-bold" slot="end">{{ (3).toFixed(2) }}€</ion-label>
                </ion-item>
                <ion-item>
                    <ion-label class="weight-bold">Envío</ion-label>
                    <ion-label class="price weight-bold" slot="end">{{ data.shipping.toFixed(2) }}€</ion-label>
                </ion-item>
                <ion-item class="total">
                    <ion-label><h5><b>Total</b></h5></ion-label>
                    <ion-label class="price" slot="end"><h5><b>{{ data.total.toFixed(2) }}€</b></h5></ion-label>
                </ion-item>
                <ion-item>
                    <h6 class="text-right" style="width: 100%">Incluye ({{ data.tax.toFixed(2) }}€) de I.V.A.</h6>
                </ion-item>
            </ion-list>
        </div>
    </div>


<div id="singleTicket" v-if="generateTicket">
  <div style="max-width: 260px; font-family: Helvetica">
    <h1 style="text-align: center; padding: 0; margin:16px;">{{ evolbeUser.business[0].name}}</h1>
    <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.name}} </p>
    <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.address}} </p>
    <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.cif}} </p>
    <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.phone}} </p>

    <hr>

      <p style="padding: 0; margin:0;">PEDIDO: #{{ ticket.objectID }}</p>
      <p style="padding: 0; margin:0;">FECHA: {{ fecha(ticket.dateCreated) }}</p>

    <hr>

      <p style="text-transform: capitalize; padding: 0; margin:0;">CLIENTE: {{ ticket.customer.firstName }}, {{ ticket.customer.lastName }}</p>
      <p style="text-transform: capitalize; padding: 0; margin:0;" v-if="ticket.delivery">DIRECCIÓN: {{ ticket.customer.address }}<span v-if="ticket.customer.city">, {{ ticket.customer.city  }}</span><span v-if="ticket.customer.zipCode">, {{ ticket.customer.zipCode  }}</span></p>
      <p style="text-transform: capitalize; padding: 0; margin:0;" v-if="!ticket.delivery">DIRECCIÓN: Recogida en el local</p>
      <p style="padding: 0; margin:0;">TELÉFONO: {{ ticket.customer.phone }}</p>

    <hr>

    <table>
      <tr>
        <td width="10%">UD.</td>
        <td width="65%">ARTICULO</td>
        <td width="25%">PRECIO</td>
      </tr>

      <tr v-for="dish of ticket.items" :key="dish.id">
        <td colspan="3"> 
          <table width="100%">
            <tr>
              <td width="10%"><span>{{ dish.quantity }}</span></td>
              <td width="65%"><b>{{ dish.name }}</b></td>
              <td width="25%" align="right">{{ dish.price.toFixed(2) }}€</td>
            </tr>
      
            <tr v-if="dish.variations">
              <td></td>
              <td><p v-for="option in dish.variations[0].options.filter(x => x.selected === true)" :key="option"><i>{{ option.text }}</i></p></td>
            </tr>

            <tr v-for="subdish of dish.items" :key="subdish.id">
              <td colspan="3">
                <table width="100%">
                    <tr>
                      <td width="10%"><span>{{ subdish.quantity }}</span></td>
                      <td width="65%">{{ subdish.name }}</td>
                      <td width="25%" align="right">{{ subdish.type }}</td>
                    </tr>
              
                    <tr v-if="subdish.variations">
                      <td></td>
                      <td><p v-for="option in subdish.variations[0].options.filter(x => x.selected === true)" :key="option"><i>{{ option.text }}</i></p></td>
                    </tr>
                </table>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>

    <hr>

    <table>
      <tr>
        <td width="90%">SUBTOTAL:</td>
        <td width="10%">{{ ticket.subtotal.toFixed(2) }}€</td>
      </tr>
      <tr v-if="ticket.coupon">
        <td width="90%">DESCUENTO CUPON ({{ ticket.coupon.discount }}%):</td>
        <td width="10%">{{ ticket.discount.toFixed(2) }}€</td>
      </tr>
      <tr v-if="ticket.surcharge">
        <td width="90%">RECARGO:</td>
        <td width="10%">{{ (3).toFixed(2) }}€</td>
      </tr>
      <tr>
        <td width="90%">ENVíO:</td>
        <td width="10%">{{ ticket.shipping.toFixed(2) }}€</td>
      </tr>
      <tr>
        <td width="90%"><b>TOTAL:</b></td>
        <td width="10%"><b>{{ ticket.total.toFixed(2) }}€</b></td>
      </tr>
    </table>

    <h6 style="text-align: center; margin: 16px;">IVA 10% INCLUIDO</h6>

    <hr>

    <p style="text-transform: capitalize; padding: 0; margin:0;">FORMA DE PAGO: <span v-if="ticket.payment.method === 'stripe'">Pago Online</span><span v-if="ticket.payment.method === 'cash'">Efectivo</span><span v-if="ticket.payment.method === 'dataphone'">Tarjeta</span> - <span v-if="ticket.payment.status === 'succeeded'">Exitoso</span><span v-if="ticket.payment.status === 'pending'">Pendiente</span><span v-if="ticket.payment.status === 'error'">Error</span></p>
    <p style="text-transform: capitalize; padding: 0; margin:0;">OBSERVACIONES: {{ ticket.observations }}</p>

  </div>
</div>
</template>


<script>
import { IonIcon, IonChip, IonButton, IonButtons, IonLabel, IonList, IonItem, loadingController, IonHeader, IonToolbar} from '@ionic/vue';
import { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline } from 'ionicons/icons';

import ConfirmPopup from 'primevue/confirmpopup';

import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Menu from 'primevue/menu';

import printJS from 'print-js'

export default defineComponent({
  name: 'ProductPreview',
  props: ['data'],
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonLabel,
    IonList,
    IonItem,
    ConfirmPopup,
    IonChip,
    IonHeader,
    IonToolbar,
    Menu
  },
      data() {
      return {
        statuses: [
          {
            label: 'Abandodano',
            color: 'dark',
            command: () => {
              this.changeStatus(0);
            }
          },
          {
            label: 'Nuevo',
            color: 'secondary',
            command: () => {
              this.changeStatus(1);
            }
          },
          {
            label: 'Cocina',
            color: 'primary',
            command: () => {
              this.changeStatus(2);
            }
          },
          {
            label: 'Reparto',
            color: 'warning',
            command: () => {
              this.changeStatus(3);
            }
          },
          {
            label: 'Completado',
            color: 'success',
            command: () => {
              this.changeStatus(4);
            }
          },
          {
            label: 'Cancelado',
            color: 'danger',
            command: () => {
              this.changeStatus(5);
            }
          },          
        ],

        generateTicket: false,
        ticket: {},
      };
    },
    computed: {
        ...mapState(['evolbeUser'])
    },
    methods: {
         ...mapActions('navidad121',['deleteStoredOrder', 'updateStoredOrder']),
         ...mapActions(['closeDetails']),

        fecha(date){
          return moment(date).format('DD/MM/YYYY - HH:mm');
        },

        fechaEntrega(date){
          return moment(date).format('dddd, DD/MM/YYYY');
        },

        nextStatus(){
          const newData = this.data;
          newData.status = this.data.status + 1;
          this.updateStoredOrder({data: newData, id: newData.objectID});
        },

        changePaymentStatus(){
          if(this.evolbeUser.business[0].rol !== 'analyst'){
            if(this.data.payment.status === 'pending'){
              const newData = this.data;
              newData.payment.status = 'succeeded';
              this.updateStoredOrder({data: newData, id: newData.objectID});
            } else if(this.data.payment.status === 'succeeded'){
              const newData = this.data;
              newData.payment.status = 'error';
              this.updateStoredOrder({data: newData, id: newData.objectID});
            } else {
              const newData = this.data;
              newData.payment.status = 'pending';
              this.updateStoredOrder({data: newData, id: newData.objectID});
            }
          }
        },

        async printTicket(){
            const loading = await loadingController
                .create({
                message: 'Generando...',
                duration: 10000,
                });

            await loading.present();


            this.ticket = this.data;
            this.generateTicket = true;

            setTimeout(async() => {
              await printJS({
                printable: "singleTicket",
                type: "html",
                scanStyles: false,
              });

              loading.dismiss();
            },500);
        },

        changeStatusToggle(event){
          if(this.evolbeUser.business[0].rol !== 'analyst'){
            this.$refs.menu.toggle(event);
          }
        },

        changeStatus(status){
          const newData = this.data;
          newData.status = status;
          this.updateStoredOrder({data: newData, id: newData.objectID});
        },

        confirmCancelProduct(event){
          this.$confirm.require({
              target: event.currentTarget,
              message: '¿Seguro de que quieres cancelar este pedido?',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                  //callback to execute when evolbeUser confirms the action
                    const newData = this.data;
                    newData.status = 5;
                    this.updateStoredOrder({data: newData, id: newData.objectID});
              },
              reject: () => {
                  //callback to execute when evolbeUser rejects the action
              }
          });
        },

        confirmDeleteElement(event){
          this.$confirm.require({
              target: event.currentTarget,
              message: '¿Seguro de que quieres eliminar este pedido permanentemente?',
              acceptLabel: 'Si',
              rejectLabel: 'No',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                  //callback to execute when evolbeUser confirms the action
                    this.deleteStoredOrder(this.data.objectID);
                    this.closeDetails();
              },
              reject: () => {
                  //callback to execute when evolbeUser rejects the action
              }
          });
        },
         
    },
    created(){
      moment.locale('es');
    },
    setup() {
        return { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline };
    }
});
</script>

<style scoped>

    .header-aside-container{
        max-width: 96rem;
        margin: 0 auto;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .datos{
      padding: 0;
    }

    .datos ion-item{
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
    }

    .datos p{
        margin-top: 0.25rem;
    }


    .totals, .subplatos, .platos{
      padding: 0;
    }

    .plato{
      border-bottom: 1px solid var(--ion-color-light-shade);
      padding-bottom: 1rem;
    }

    .dish, .subdish{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .totals ion-item, .subplatos ion-item{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
    }

    .dish ion-lablel, .subdish ion-lablel, .totals ion-item ion-label{
        margin: 0;
    }

    .dish .variation, .subdish .variation{
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: var(--ion-color-medium);
        min-height: 0.8rem;
    }

    .price{
        margin: 0;
        width: 88px;
        text-align: right;
    }

    .quantitys{
        width: 68px;
        margin-left: 0;
    }

    .quantitys ion-label{
        margin: 0 auto;
    }

    .subplatos{
        padding: 0;
    }

    #singleTicket{
      position: absolute;
      z-index: -100;
      right: 10000px;
      top: 0;
    }


</style>