<template>
  <ion-page>
    <ion-content class="aside-nav">
        <div v-if="previewContent">

          <!-- Restaurant -->
          <OrdersPreviewRestaurant v-if="previewContent.component === 'restaurant-order'" :data="previewContent.data"></OrdersPreviewRestaurant>
          <StoredOrdersPreviewRestaurant v-if="previewContent.component === 'restaurant-storedOrder'" :data="previewContent.data"></StoredOrdersPreviewRestaurant>
          <AbandonedOrdersPreviewRestaurant v-if="previewContent.component === 'restaurant-abandonedOrder'" :data="previewContent.data"></AbandonedOrdersPreviewRestaurant>

            <!-- Bookings -->
            <BookingDetailsRestaurant v-if="previewContent.component === 'restaurant-booking'" :data="previewContent.data"></BookingDetailsRestaurant>

          <!-- Shop -->
          <OrdersPreviewShop v-if="previewContent.component === 'shop-order'" :data="previewContent.data"></OrdersPreviewShop>
          <StoredOrdersPreviewShop v-if="previewContent.component === 'shop-storedOrder'" :data="previewContent.data"></StoredOrdersPreviewShop>
          <AbandonedOrdersPreviewShop v-if="previewContent.component === 'shop-abandonedOrder'" :data="previewContent.data"></AbandonedOrdersPreviewShop>

          <!-- Hotel -->
          <BookingDetailsHotel v-if="previewContent.component === 'hotel-booking'" :data="previewContent.data"></BookingDetailsHotel>


          <!-- Clients -->
          <UsersDetailsClients v-if="previewContent.component === 'clients-user'" :data="previewContent.data"></UsersDetailsClients>

          <!-- Experiencias 12 + 1 -->
          <OrdersPreviewExperiencias121 v-if="previewContent.component === 'experiencias121-order'" :data="previewContent.data"></OrdersPreviewExperiencias121>
          <StoredOrdersPreviewExperiencias121 v-if="previewContent.component === 'experiencias121-storedOrder'" :data="previewContent.data"></StoredOrdersPreviewExperiencias121>
          <AbandonedOrdersPreviewExperiencias121 v-if="previewContent.component === 'experiencias121-abandonedOrder'" :data="previewContent.data"></AbandonedOrdersPreviewExperiencias121>

          <!-- Navidad 12 + 1 -->
          <OrdersPreviewNavidad121 v-if="previewContent.component === 'navidad121-order'" :data="previewContent.data"></OrdersPreviewNavidad121>
          <StoredOrdersPreviewNavidad121 v-if="previewContent.component === 'navidad121-storedOrder'" :data="previewContent.data"></StoredOrdersPreviewNavidad121>
          <AbandonedOrdersPreviewNavidad121 v-if="previewContent.component === 'navidad121-abandonedOrder'" :data="previewContent.data"></AbandonedOrdersPreviewNavidad121>
          <NewMenuNavidad121 v-if="previewContent.component === 'navidad121-menuNavidad'"></NewMenuNavidad121>
          <NewCartaNavidad121 v-if="previewContent.component === 'navidad121-cartaNavidad'"></NewCartaNavidad121>

        </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {  IonPage, IonContent} from '@ionic/vue';
import { } from 'ionicons/icons';
import { defineComponent } from 'vue';

/* Restaurant */
import OrdersPreviewRestaurant from '@/views/modules/restaurant/components/OrdersPreview.vue';
import StoredOrdersPreviewRestaurant from '@/views/modules/restaurant/components/StoredOrdersPreview.vue';
import AbandonedOrdersPreviewRestaurant from '@/views/modules/restaurant/components/AbandonedOrdersPreview.vue';

  /* Bookings */
  import BookingDetailsRestaurant from '@/views/modules/bookings/components/BookingDetails.vue';

/* Shop */
import OrdersPreviewShop from '@/views/modules/shop/components/OrdersPreview.vue';
import StoredOrdersPreviewShop from '@/views/modules/shop/components/StoredOrdersPreview.vue';
import AbandonedOrdersPreviewShop from '@/views/modules/shop/components/AbandonedOrdersPreview.vue';

/* Hotel */
import BookingDetailsHotel from '@/views/modules/hotel/components/BookingDetails.vue';

/* Clients */
import UsersDetailsClients from '@/views/modules/clients/components/UserDetails.vue';

/* Experiencias 12+1 */
import OrdersPreviewExperiencias121 from '@/views/modules/custom/experiencias-121/components/OrdersPreview.vue';
import StoredOrdersPreviewExperiencias121 from '@/views/modules/custom/experiencias-121/components/StoredOrdersPreview.vue';
import AbandonedOrdersPreviewExperiencias121 from '@/views/modules/custom/experiencias-121/components/AbandonedOrdersPreview.vue';

/* Navidad 12+1 */
import OrdersPreviewNavidad121 from '@/views/modules/custom/navidad-121/components/OrdersPreview.vue';
import StoredOrdersPreviewNavidad121 from '@/views/modules/custom/navidad-121/components/StoredOrdersPreview.vue';
import AbandonedOrdersPreviewNavidad121 from '@/views/modules/custom/navidad-121/components/AbandonedOrdersPreview.vue';
import NewMenuNavidad121 from '@/views/modules/custom/navidad-121/components/NewMenuNavidad.vue';
import NewCartaNavidad121 from '@/views/modules/custom/navidad-121/components/NewCartaNavidad.vue';


export default defineComponent({
  name: 'Preview',
  components: {
    IonPage,
    IonContent,
    OrdersPreviewRestaurant,
    StoredOrdersPreviewRestaurant,
    AbandonedOrdersPreviewRestaurant,
    OrdersPreviewShop,
    StoredOrdersPreviewShop,
    AbandonedOrdersPreviewShop,
    OrdersPreviewExperiencias121,
    StoredOrdersPreviewExperiencias121,
    AbandonedOrdersPreviewExperiencias121,
    OrdersPreviewNavidad121,
    StoredOrdersPreviewNavidad121,
    AbandonedOrdersPreviewNavidad121,
    NewMenuNavidad121,
    NewCartaNavidad121,
    UsersDetailsClients,
    BookingDetailsHotel,
    BookingDetailsRestaurant
  },
  props: ['previewContent'],
});
</script>

<style>

</style>