<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div class="background-login">
        <ion-header class="ion-no-border" id="header">
            <ion-item lines="none" class="container header-item">
                <router-link to="/" class="logo" slot="start">
                    <img src="assets/business_white.png" alt="Evolbe Business"/>
                </router-link>

                <ion-buttons slot="end"  style="margin-right: 0">
                    <ion-button href="https://api.whatsapp.com/send?phone=34656509518&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Evolbe%20Business" target="_blank">
                        <ion-icon slot="icon-only" :icon="helpCircleOutline"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
        </ion-header>

        <div style="min-height: calc(100vh - 10.5rem);"></div>

        <section id="main-container">
            <ion-card>
                <ion-card-content>
                    <form @submit.prevent="makeLogin" v-if="activeSection === 1">
                    <h3 style="padding-bottom: 1rem;">Iniciar Sesión</h3>
                    <ion-grid id="login">
                        <ion-row>
                            <ion-col size="12" class="box-input" :class="{ error: v$.login.email.$errors.length }">
                                <label for="email">Email</label>
                                <InputText class="input" id="email" type="email" v-model.trim.lazy="v$.login.email.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.login.email.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.login.password.$errors.length }">
                                <label for="password">Contraseña</label>
                                <Password v-model="value" :feedback="false" toggleMask class="input" id="password" v-model.trim.lazy="v$.login.password.$model"/>
                                <!-- error message -->
                                    <div class="input-errors" v-for="(error, index) of v$.login.password.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-button shape="round" type="submit" color="primary" expand="full" style="margin-bottom: 1rem;" :disabled="v$.login.$invalid">
                        <ion-label>Iniciar Sesión</ion-label>
                    </ion-button>
                    <ion-button shape="round" expand="full" color="dark" fill="outline" @click="activeSection = 2">
                        <ion-label>¿Olvidaste la contraseña?</ion-label>
                    </ion-button>
                </form>

                <form @submit.prevent="recoverPassword" v-if="activeSection === 2">
                    <h3 style="padding-bottom: 1rem;">Recuperar Contraseña</h3>
                    <ion-grid id="forgot">
                        <ion-row>
                            <ion-col size="12" class="box-input" :class="{ error: v$.forgot.email.$errors.length }">
                                <label for="email">Email</label>
                                <InputText class="input" id="email" type="email" v-model.trim.lazy="v$.forgot.email.$model" />
                                <!-- error message -->
                                <div class="input-errors" v-for="(error, index) of v$.forgot.email.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>

                    <ion-button shape="round" type="submit" color="primary" expand="full" style="margin-bottom: 1rem;" :disabled="v$.forgot.$invalid">
                        <ion-label>Recuperar</ion-label>
                    </ion-button>
                    <ion-button shape="round" expand="full" color="dark" fill="outline" @click="activeSection = 1">
                        <ion-label>Volver</ion-label>
                    </ion-button>
                </form>
                </ion-card-content>
            </ion-card>
        </section>

        <ion-footer id="footer" class="ion-no-border">
            <ion-grid class="container px-xl">
                <ion-row id="subfooter-login">
                    <ion-col size="12" size-lg="6">
                    <p class="copyright">&copy; {{ anno }} Evolbe Systems. Todos los derechos reservados.</p>
                    </ion-col>
                    <ion-col size="12" size-lg="6">
                    <ion-buttons class="footer-buttons">
                        <ion-button>
                        <ion-label>Terminos y Condiciones</ion-label>
                        </ion-button>
                        <ion-button>
                        <ion-label>Privacidad</ion-label>
                        </ion-button>
                    </ion-buttons>
                    </ion-col>
                </ion-row>
            </ion-grid>
        </ion-footer>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButton, IonLabel, loadingController, IonIcon, IonButtons, IonItem, IonHeader, IonCardContent, IonCard, IonFooter } from '@ionic/vue';
import { helpCircleOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import InputText from 'primevue/inputtext';
import Password from 'primevue/password';

import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@/utils/i18n-validators'

import moment from 'moment';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonPage,
    InputText,
    Password,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonIcon,
    IonButtons,
    IonItem,
    IonHeader,
    IonCardContent,
    IonCard,
    IonFooter 
  },
  data() {
      return {
          activeSection: 1,
          login: {
            email: '',
            password: '',
          },
          forgot: {
            email: '',
          },
          anno: moment().year() 
      };
    },
    validations() {
        return {
            login: {
                email: { required, email },
                password: { minLength: minLength(6) },
            },
            forgot: {
                email: { required, email },
            },
        }
    },
    methods: {
        ...mapActions(['loginEmail', 'recoverPassword']),

        async makeLogin(){
            const loading = await loadingController
                .create({
                message: 'Iniciando Sesión...',
                duration: 3000,
                });

            await loading.present();

            await this.loginEmail(this.login).then(res => {
                loading.dismiss();

                if(res){
                    this.$toast.add({severity: 'error', summary: 'Error', detail: res, group: 'tr', life: 300000});
                } else {
                    this.$toast.add({severity: 'success', summary: '¡Bienvenido/a de Nuevo!', detail: 'Sesión iniciada correctamente', group: 'tr', life: 3000});
                }
            });
        },

        async recoverPassword(){
            const loading = await loadingController
                .create({
                message: 'Enviando recuperación...',
                duration: 3000,
                });

            await loading.present();

            await this.updatePassword(JSON.parse(JSON.stringify(this.passwordRecover))).then(res => {
                loading.dismiss();

                if(res){
                this.$toast.add({severity: 'error', summary: 'Error', detail: res, group: 'tr', life: 3000});
                } else {
                this.$toast.add({severity: 'success', summary: 'Correo Recuperación Enviado Correctamente', detail: 'Te hemos enviado un email con un enlace para reestablecer tu contraseña', group: 'tr', life: 3000});
                }
            });
        },
    },
    setup () {
        return { v$: useVuelidate(), helpCircleOutline }
    },
});
</script>

<style scoped>
    .background-login{
         background-image: url("../../public/assets/img/background-login.png");
         background-size: cover;
         background-repeat: no-repeat;
         background-position: center;
         min-height: 100vh;
    }

    #main-container{
        max-width: 23rem;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translateX(-50%) translateY(-50%);
    }

    #main-container ion-card{
        box-shadow: none !important;
    }
    

    /* Header */
    #header{
        height: 5rem;
        padding: 0;
    }

    .header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        border-bottom: 1px solid #f9f9f9;
        color: #f9f9f9;
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        height: 30px;
        margin: 0;

    }

    .logo img{
        height: 100%;
    }
    /* Footer */
    #footer{
        color: #f9f9f9;
    }

    #subfooter-login{
        border-top: 1px solid #f9f9f9;;
        padding: 1rem 0;
    }

    .copyright{
        font-size: 0.75rem; 
        margin-top: 8px;
        font-weight: 500;
        color: #f9f9f9;
    }

    .footer-buttons{
        justify-content: flex-start;
    }

    .footer-buttons ion-label{
        font-size: 0.75rem; 
    }

    @media (min-width: 960px) and (max-width: 1440px) {
        .footer-buttons{
        justify-content: flex-end;
        }
    }

    @media (min-width: 1440px) {
        .footer-buttons{
        justify-content: flex-end;
        }
    }

</style>
