<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Editar</h4>
                  <h1>Habitación</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <div class="flex">
                    <ion-button @click="exit" shape="round" style="margin-top: 6px" color="dark" fill="outline">
                        <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                        <ion-label>Volver</ion-label>
                    </ion-button>
                    <ion-button @click="save" :disabled="v$.element.$invalid" color="primary" style="margin-top: 6px; margin-left:0.75rem" shape="round">
                        <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                        <ion-label>Guardar</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col size="12" size-lg="6">
                  <ion-buttons class="options-nav">
                    <ion-toggle v-model="element.active" color="success"></ion-toggle>
                    <Dropdown v-model="language" style="margin: 0 0.5rem;" :options="languages" optionLabel="name" optionValue="code" placeholder="Idioma" />
                    <ion-button @click="confirmDeleteElement($event)" >
                        <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
          </ion-grid>
        </section>

        <section id="content" class="mb-lg">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <ion-grid>
                  <ion-row class="mb-md">
                    <h5 class="edit-title">Detalles</h5>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.name[language].$errors.length }">
                        <label for="name">Nombre</label>
                        <InputText class="input" id="name" placeholder="Nombre" type="text" v-model.trim.lazy="v$.element.name[language].$model" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.name[language].$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.price.$errors.length }">
                        <label for="price">Precio por Noche</label>
                        <InputNumber id="price" class="inputNumber secondary-input" v-model.lazy="v$.element.price.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.price.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.priceExtraPerson.$errors.length }">
                        <label for="priceExtraPerson">Precio por Persona Extra</label>
                        <InputNumber id="priceExtraPerson" class="inputNumber" v-model.lazy="v$.element.priceExtraPerson.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.priceExtraPerson.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.direction.$errors.length }">
                        <label for="direction">Dirección</label>
                        <InputText class="input" id="direction" placeholder="Nombre" type="text" v-model.trim.lazy="v$.element.direction.$model" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.direction.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.map.$errors.length }">
                        <label for="map">Mapa</label>
                        <InputText class="input" id="map" placeholder="Iframe del mapa" type="text" v-model.trim.lazy="v$.element.map.$model" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.map.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.features[language].$errors.length }">
                        <label for="features">Características</label>
                        <Chips class="input" separator="," id="features" v-model.lazy="v$.element.features[language].$model" />
                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.features[language].$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.description[language].$errors.length }">
                        <label for="description">Descripción</label>
                        <Editor v-model.trim.lazy="v$.element.description[language].$model" placeholder="Descripción" id="description" editorStyle="min-height: 160px; height: auto"/>

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.description[language].$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                  </ion-row>

                  <ion-row class="mb-md">
                    <ion-col size="12">
                      <h5 class="edit-title">Imágenes</h5>
                          <FileUpload
                              mode="basic"
                              name="image"
                              :multiple="true"
                              accept="image/*"
                              :maxFileSize="5000000"
                              :customUpload="true"
                              @uploader="handleImageUpload"
                              :auto="true"
                              chooseLabel="Subir Imágenes"
                              class="uploader"
                              ref="uploader"
                          />
                            <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                            <draggable class="imagenes" :list="images" @change="updateImageList">
                              <div
                                class="imagen-container" v-for="image in images" :key="image"
                              >
                                <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                                <ion-buttons class="imagen-remover">
                                    <ion-button @click="handleImageRemove($event, image.objectURL)">
                                    <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                              </div>
                            </draggable>
                      </ion-col>
                  </ion-row>
                </ion-grid>

                <ion-button @click="showPreview" color="dark" fill="outline" expand="block"  class="hide-xl hide-lg" style="margin-top:0.5rem" shape="round">
                    <ion-label>Previsualizar</ion-label>
                </ion-button>
              </ion-col>
              <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md">
                  <DishPreview :data="element"></DishPreview>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonButton, IonButtons, modalController, IonToggle } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import ConfirmPopup from 'primevue/confirmpopup';
import InputNumber from 'primevue/inputnumber';
import Image from 'primevue/image';
import Chips from 'primevue/chips';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import DishPreview from './components/DishPreview.vue';

import { VueDraggableNext } from 'vue-draggable-next'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'EditRoom',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    ConfirmPopup,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,

    InputText,
    FileUpload,
    Image,
    InputNumber,
    Editor,
    IonToggle,
    Dropdown,
    DishPreview,
    Chips,
    draggable: VueDraggableNext,
  },
  data() {
      return {
        element: {
            active: true,
            name: '',
            price: 0,
            priceExtraPerson: 0,
            description: '',
            direction: '',
            map: '',
            features: [],
            images: [],
        },
        images: [],

        dialogImageUrl: '',
        dialogVisible: false,

        language: 'es',
        languages: [
                {name: 'Español', code: 'es'},
                {name: 'English', code: 'en'},
        ],
      };
    },
    validations() {
        return {
            element: {
                name: {
                  [this.language]: {required}
                },
                price: {required},
                priceExtraPerson: {required},
                description: {
                  [this.language]: {}
                },
                direction: {},
                map: {},
                features: {
                  [this.language]: {}
                },
            },
        }
    },
    computed: {
        ...mapState('hotel', {
            room: state => state.room,
        }),

        ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('hotel',['getRoom','uploadImage', 'addRoom', 'updateRoom', 'deleteRoom']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),

      // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/hotel/rooms', img: file});
          this.element.images.push({
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fhotel%2Frooms%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fhotel%2Frooms%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fhotel%2Frooms%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fhotel%2Frooms%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          });
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        const pos = this.element.images.findIndex(x => x[512] === url);
        this.element.images.splice(pos, 1);
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },

      updateImageList(event){
        const temp = this.element.images[event.moved.oldIndex];
        this.element.images.splice(event.moved.oldIndex, 1);
        this.element.images.splice(event.moved.newIndex, 0, temp);
      },

      // Actions
      save(){
        if(this.$route.params.id === 'new'){
          this.element.dateCreated = new Date().getTime();
          this.element.dateUpdated = new Date().getTime();

          this.addRoom(this.element);
          this.exit();
        } else {
          this.element.dateUpdated = new Date().getTime();
          this.updateRoom({data: this.element, id: this.$route.params.id});
          this.exit();
        }
      },
      confirmDeleteElement(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.deleteRoom(this.$route.params.id);
                    this.exit();
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      exit(){
          this.images = [];
          this.$router.go(-1);
      },
      
      async showPreview(){
        const modal = await modalController
            .create({
                component: DishPreview,
                cssClass: 'auto-height',
                componentProps: {
                    element: this.element
                },
            })
        return modal.present();
      }
    },
    beforeMount() {
      if(this.$route.params.id === 'new'){
        // Nuevo
      } else {
        this.getRoom(this.$route.params.id).then(() => {
            if(this.room){
                this.element = this.room;

                // Load Images
                if(this.element && !this.images.length){
                  this.element.images.forEach(img => {
                    this.images.push({objectURL: img['512'], status: 'upload'});
                  });
                }
            }
        });
      }
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline};
  }
});
</script>

<style scoped>

  .edit-title{
    padding-bottom: 1rem;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 212px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .allergen-item img{
    width: 48px;
    height: auto;
  }

</style>