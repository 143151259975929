<template>
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="closeDetails">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Detalles</h5>
            <ion-buttons slot="end" style="margin: 0">
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <div class="mt-xxs mb-xxs">
          <ion-list class="datos" lines="none">
              <ion-item>
                <ion-label><h6><b>#{{ data.objectID }}</b></h6></ion-label>
              </ion-item>

              <ion-item>
                <ion-label text-wrap>
                  <h3 style="text-transform: capitalize;"><b>{{ data.firstName }}, {{ data.lastName }}</b></h3>
                </ion-label>
              </ion-item>

              <ion-item>
                  <ion-label text-wrap>
                      <span>Teléfono:</span>
                      <p>{{ data.phone }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Email:</span>
                      <p>{{ data.email }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Sexo:</span>
                      <p v-if="data.sex === 'm'">Masculino</p>
                      <p v-if="data.sex === 'f'">Femenino</p>
                      <p v-if="data.sex === 'o'">Otro</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha de Nacimiento:</span>
                      <p>{{ fechaDia(data.birthDate) }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Dirección:</span>
                      <p>{{ data.address }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Ciudad:</span>
                      <p>{{ data.city }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Código Postal:</span>
                      <p>{{ data.zipCode }}</p>
                  </ion-label>
              </ion-item>
          </ion-list>
        </div>

        <div class="bt pt-xxs pb-xxs">
          <ion-list class="datos" lines="none">
              <ion-item>
                  <ion-label text-wrap>
                      <span>Dirección:</span>
                      <p>{{ data.address }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Ciudad:</span>
                      <p>{{ data.city }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Código Postal:</span>
                      <p>{{ data.zipCode }}</p>
                  </ion-label>
              </ion-item>
          </ion-list>
        </div>

        <div class="bt pt-xxs pb-xxs">
          <ion-list class="datos" lines="none">
              <ion-item>
                  <ion-label text-wrap>
                      <span>Fecha de Registro:</span>
                      <p>{{ fecha(data.dateCreated) }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Último Inicio de Sesión:</span>
                      <p>{{ fecha(data.lastLogin) }}</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Proveedor de Registro:</span>
                      <p v-if="data.provider === 'google.com'">Google</p>
                      <p v-if="data.provider === 'facebook.com'">Facebook</p>
                      <p v-if="data.provider === 'apple.com'">Apple</p>
                      <p v-if="data.provider === 'email'">Email</p>
                  </ion-label>
              </ion-item>
              <ion-item>
                  <ion-label text-wrap>
                      <span>Notificaciones</span>
                      <p v-if="data.notifications"><span v-if="data.notifications.email">Email,</span> <span v-if="data.notifications.mobile">Push</span></p>
                  </ion-label>
              </ion-item>
          </ion-list>
        </div>
    </div>
</template>

<script>
import { IonIcon, IonButton, IonButtons, IonLabel, IonList, IonItem, IonHeader, IonToolbar} from '@ionic/vue';
import { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline } from 'ionicons/icons';


import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';

export default defineComponent({
  name: 'UserDetails',
  props: ['data'],
  components: {
    IonIcon,
    IonButton,
    IonButtons,
    IonLabel,
    IonList,
    IonItem,
    IonHeader,
    IonToolbar,
  },
    data() {
      return {

      };
    },
    computed: {
        ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
         ...mapActions(['closeDetails']),

        fecha(date){
          return moment(date).format('DD/MM/YYYY - HH:mm');
        },

        fechaHora(date){
            return moment(date).format('HH:mm');
        },

        fechaDia(date){
            return moment(date).format('DD/MM/YYYY');
        },      
    },
    created(){
      moment.locale('es');
    },
    setup() {
        return { trashOutline, createOutline, closeOutline, closeCircleOutline, printOutline, arrowForwardCircleOutline, arrowBackOutline, chevronBackOutline };
    }
});
</script>

<style scoped>

    .header-aside-container{
        max-width: 96rem;
        margin: 0 auto;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .header-details{
      height: 5rem;
      padding: 1rem 0;
    }

    .datos{
      padding: 0;
    }

    .datos ion-item{
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
    }

    .datos p{
        margin-top: 0.25rem;
    }

</style>