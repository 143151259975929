import { getFirestoreClient } from '@/firebaseConfig';

export const moduleBookings = {
    namespaced: true,
    state: {
        // Restaurant
            // Bookings
            bookings: [],
            booking: {},

            daysBlocked: [],
    },
    mutations: { 
        // Restaurant
            // Bookings
            setBookings(state, payload){
                state.bookings = payload
            },
            setBooking(state, payload){
                state.booking = payload
            },

            setDaysBlocked(state, payload){
                state.daysBlocked = payload
            },
    },
    actions: {
        // Restaurant
            // Bookings
            async getBookings({commit}){
                if(!this.state.bookings.bookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    let data;
                    await dbClient.collection('/evolbe-modules/restaurant/bookings').orderBy('dateCreated', 'desc').onSnapshot(res => {
                        data = [];
                        res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                            data.push(e);
                        })

                        commit('setBookings', data);
                    });

                }
            },
            async getBooking({commit}, id){
                if(!this.state.bookings.bookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    const res = await dbClient.collection('/evolbe-modules/restaurant/bookings').doc(id).get();

                    const item = res.data();
                    const data = {
                        objectID: item.id,
                        ...item
                    }
                    
                    commit('setBooking', data);
                } else {
                    const data = this.state.bookings.bookings.find(x => x.objectID === id)
                    commit('setBooking', data);
                }
            },
                async addBooking({commit}, data){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/restaurant/bookings').add(data);
                },
                async updateBooking({commit}, {data, id}){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/restaurant/bookings').doc(id).update(data);
                },
                async deleteBooking({commit}, id){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/restaurant/bookings').doc(id).delete();
                },

            async getDaysBlocked({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/settings').doc('bookings').get();
                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
    
                // Convertir Fechas
                const newData = [];
                data.daysBlocked.forEach(day => {
                    newData.push(new Date(day));
                });
               
                commit('setDaysBlocked', newData.sort((a,b) => a - b));
            },

            async updateDaysBlocked({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);

                const newData = [];
                data.forEach(day => {
                    newData.push(new Date(day));
                });
                
                const newSettings = {
                    daysBlocked: data || [],
                }

                await dbClient.collection('/settings').doc('bookings').update(newSettings);

                // Convertir Fechas
                const newDates = [];
                newSettings.daysBlocked.forEach(day => {
                    newDates.push(new Date(day));
                });
                commit('setDaysBlocked', newDates.sort((a,b) => a - b));
            },
    },
}