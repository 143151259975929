<template>
  <div class="inner-content">
    <div class="item-details">
      <h4 class="text-center">Imprimiendo</h4>
      <ion-spinner name="crescent" color="primary" class="loader"></ion-spinner>
    </div>
  </div>

  <div id="printable">
    <Ticket v-if="type === 'ticket'" :ticket="data"></Ticket>
    <TicketOrder v-if="type === 'ticket-order'" :ticket="data"></TicketOrder>
  </div>
</template>

<script>
import { IonSpinner, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { chevronBackOutline } from 'ionicons/icons';

import printJS from 'print-js'
import Ticket from '@/components/printables/Ticket.vue';
import TicketOrder from '@/components/printables/TicketOrder.vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'OtherPrint',
  props: ['data', 'type', 'copies'],
  components: {
    IonSpinner,
    Ticket,
    TicketOrder
  },
data() {
    return {
        BTList: [],
        selectedDevice: undefined,
        showList: undefined,
    };
},
  computed: {
      ...mapState(['evolbeUser', 'preferences'])
  },
  methods: {
      async print(){
        let i = 0;
        while (i < this.copies) {
          await printJS({
              printable: "printable",
              type: "html",
              scanStyles: false,
            });
            console.log(i);
            i = i + 1;
          }

          this.exit();
          throw new Error('Test:', this.data);
      },

      exit(){
          modalController.dismiss();
      }
  },
  created(){
    setTimeout(() => {
      this.print();
    }, 1000);

  },
    setup() {
        return { chevronBackOutline };
    }
});
</script>

<style scoped>
 .loader{
    margin: 5rem auto;
    display: block;
    width: 64px;
    height: 64px;
 }

 #printable{
    position: absolute;
    z-index: -100;
    right: 10000px;
    top: 0;
 }
</style>