<template>
  <ion-page>
    <ion-content  :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Carta</h4>
                  <h1>Menú del Día</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                    <ion-buttons class="options-nav">
                      <ion-button router-link="/modules/restaurant/todays-menu/new">
                        <ion-icon color="dark" slot="icon-only" :icon="addCircleOutline"></ion-icon>
                      </ion-button>
                      <ion-button @click="exportCSV($event)">
                        <ion-icon color="dark" slot="icon-only" :icon="cloudDownloadOutline"></ion-icon>
                      </ion-button>
                      <ion-button @click="confirmDuplicateAllProducts($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                        <ion-icon color="dark" slot="icon-only" :icon="duplicateOutline"></ion-icon>
                      </ion-button>
                      <ion-button @click="confirmDeleteAllProducts($event)" v-if="selectedElements.length && evolbeUser.business[0].rol !== 'analyst'">
                        <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                      </ion-button>
                      <ConfirmPopup></ConfirmPopup>
                    </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Nombre')" field="name" header="Día" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field table-primary-field">{{ slotProps.data.name.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Precio')" field="total" header="Precio" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.priceFull.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Precio 1/2')" field="total" header="Precio 1/2" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field" v-if="slotProps.data.priceHalf">{{ slotProps.data.priceHalf.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha')" field="date" header="Fecha" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fechaDia(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy" placeholder="dd/mm/yyyy" :showTime="true" />
                  </template>
                </Column>

                <!--- Datos Expandidos -->
                <Column v-if="selectedColumns.includes('Primeros')" field="first" header="Primeros" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.items" class="table-field"><span v-for="dish in slotProps.data.first" :key="dish.name">{{ dish.name }}, </span></span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Plato"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Segundos')" field="second" header="Segundos" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.items" class="table-field"><span v-for="dish in slotProps.data.second" :key="dish.name">{{ dish.name }}, </span></span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Plato"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Postres')" field="first" header="Postres" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.items" class="table-field"><span v-for="dish in slotProps.data.desserts" :key="dish.name">{{ dish.name }}, </span></span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Plato"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Bebidas')" field="second" header="Bebidas" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.items" class="table-field"><span v-for="dish in slotProps.data.drinks" :key="dish.name">{{ dish.name }}, </span></span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Plato"/>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Creación')" field="dateCreated" header="Creación" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Modificación')" field="dateUpdated" header="Modificación" dataType="date" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateUpdated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.objectID" class="table-field">#{{ slotProps.data.objectID }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/restaurant/todays-menu/' + slotProps.data.objectID">
                        <ion-label>Editar</ion-label>
                      </ion-button>
                  </template>
                </Column>

            </DataTable>
          </section>
      </div>

      <Footer></Footer>
    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonButton, IonButtons} from '@ionic/vue';

import { trashOutline, cloudDownloadOutline, addCircleOutline, archiveOutline,optionsOutline, eyeOutline, duplicateOutline, createOutline, printOutline, arrowUpOutline, closeCircleOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ConfirmPopup from 'primevue/confirmpopup';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';

import moment from 'moment';
import Header from '@/components/Header.vue';

export default defineComponent({
  name: 'Products',
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    IonButtons, 
    IonButton,
    ConfirmPopup,
    Calendar,
    InputNumber,
    Header,
    MultiSelect
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: null,
        openFilters: false,
        loading: true,
        lazyParams: null,
        totalRecords: 0,
        selectedColumns: null,
        columns: [
          'ID',
          'Fecha',
          'Fecha Creación',
          'Fecha Modificación',

          'Nombre',
          'Precio',
          'Precio 1/2',

          'Primeros',
          'Segundos',
          'Postres',
          'Bebidas',
        ],
      };
    },
    computed: {
      ...mapState('restaurant', {
        elements: state => state.todaysMenus,
      }),

      ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('restaurant',['getTodaysMenus', 'addTodaysMenu', 'deleteTodaysMenu']),
      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Nombre',
            'Precio',
            'Precio 1/2',
            'Fecha',
            'Fecha Modificación',
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'date': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'dateUpdated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'first': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
              'second': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
              'priceFull': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'priceHalf': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'desserts': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'drinks': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      // Table
      onPage(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      onSort(event) {
        this.lazyParams = event;
        this.loadLazyData();
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      // Single Functions
      duplicateElement(ele){
          const newEle = JSON.parse(JSON.stringify(ele));
          newEle.name = 'Duplicate ' + newEle.name;
          newEle.dateCreated = new Date().getTime();
          newEle.dateUpdated = new Date().getTime();
          delete newEle.objectID;

          this.addTodaysMenu(newEle);
      },

      // Masive Functions
      confirmDuplicateAllProducts(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres duplicar estos elementos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when evolbeUser confirms the action
                this.selectedElements.forEach(order => {
                    this.duplicateElement(order);
                });

                this.selectedElements = [];
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },

      confirmDeleteAllProducts(event){
        this.$confirm.require({
            target: event.currentTarget,
            message: '¿Seguro de que quieres cancelar estos elementos?',
            acceptLabel: 'Si',
            rejectLabel: 'No',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                //callback to execute when evolbeUser confirms the action
                this.selectedElements.forEach(order => {
                  this.deleteTodaysMenu(order.objectID );
                });

                this.selectedElements = [];
            },
            reject: () => {
                //callback to execute when evolbeUser rejects the action
            }
        });
      },


      // Others
      scrollToTop(){
        const scrollContent = document.querySelector('ion-content.bindingScrollContent');
        scrollContent.scrollToTop(1000)
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY - HH:mm');
      },

      fechaDia(date){
        
        return moment(date).format('DD/MM/YYYY');
      },

      async loadLazyData() {
        this.loading = true;

        await this.getTodaysMenus(this.lazyEvent).then(res => {
          this.loading = false;
                        
          if(res){
            this.totalRecords = res.length; 
          }
        })
      },
    },
    created(){
      this.initFilters();
      this.loadLazyData();
      moment.locale('es');
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, eyeOutline, archiveOutline, addCircleOutline, duplicateOutline, optionsOutline, createOutline, printOutline, closeCircleOutline, arrowUpOutline, funnelOutline, funnel, cloudUploadOutline, openOutline, statsChartOutline };
    }
});
</script>

<style scoped>
  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>