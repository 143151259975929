import { getFirestoreClient } from '@/firebaseConfig';

export const moduleBlog = {
    namespaced: true,
    state: {
        // Blog
        articles: [],
        article: {},

        categories: [],
        category: [],
    },
    mutations: { 
        // Blog
        setArticles(state, payload){
            state.articles = payload
        },
        setArticle(state, payload){
            state.article = payload
        },

        setCategories(state, payload){
            state.categories = payload
        },
        setCategory(state, payload){
            state.category = payload
        },
    },
    actions: {
        // Empresas
        async getArticles({commit}){
            if(!this.state.blog.articles.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data;
                await dbClient.collection('/evolbe-modules/blog/articles').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setArticles', data);
                });

            }
        },
        async getArticle({commit}, id){
            if(!this.state.blog.articles.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/blog/articles').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setArticle', data);
            } else {
                const data = this.state.blog.articles.find(x => x.objectID === id)
                commit('setArticle', data);
            }
        },
            async addArticle({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/articles').add(data);
            },
            async updateArticle({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/articles').doc(id).update(data);
            },
            async deleteArticle({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/articles').doc(id).delete();
            },

        // Categorías
        async getCategories({commit}){
            if(!this.state.lgdc.categorias.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data;
                await dbClient.collection('/evolbe-modules/blog/categories').orderBy('order', 'asc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                        console.log
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setCategories', data);
                });

            }
        },
        async getCategory({commit}, id){
            if(!this.state.lgdc.categorias.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/blog/categories').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setCategory', data);
            } else {
                const data = this.state.lgdc.categorias.find(x => x.objectID === id)
                commit('setCategory', data);
            }
        },
            async addCategory({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/categories').add(data);
            },
            async updateCategory({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/categories').doc(id).update(data);
            },
            async deleteCategory({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/blog/categories').doc(id).delete();
            },
    },
}