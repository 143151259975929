import { getFirestoreClient } from '@/firebaseConfig';
import algoliasearch from 'algoliasearch/lite';

export const moduleLgdc = {
    namespaced: true,
    state: {
        // Empresas
        empresas: [],
        empresa: {},

        categorias: [],
        categoria: [],

        // Patrocinios
        patrocinios: [],
        patrocinio: [],
    },
    mutations: { 
        // Empresas
        setEmpresas(state, payload){
            state.empresas = payload
        },
        setEmpresa(state, payload){
            state.empresa = payload
        },

        setCategorias(state, payload){
            state.categorias = payload
        },
        setCategoria(state, payload){
            state.categoria = payload
        },

        setPatrocinios(state, payload){
            state.patrocinios = payload
        },
        setPatrocinio(state, payload){
            state.patrocinio = payload
        },
    },
    actions: {
        // Empresas
        async getEmpresas({commit}){
        if(!this.state.lgdc.empresas.length){
            const searchClient = algoliasearch(
                this.state.evolbeUser.business[0].algoliaConfig.id,
                this.state.evolbeUser.business[0].algoliaConfig.key
            );
    
            let data = [];
    
            const index = searchClient.initIndex("lgdc_empresas");
            await index
                .search('')
                .then(async (res) => {
                    data = res.hits;
    
                    if(res.nbPages > 1){
                        for (let page = 1; page <= res.nbPages; page++) {
                        await index.search('', {page: page})
                            .then((res2) => {
                                data.push(...res2.hits);
                            })
                            .catch(err => {
                                console.log(err);
                            });
                        }
                    }
    
                })
                .catch(err => {
                    console.log(err);
                });
    
                commit('setEmpresas', data);
            }
        },
        async getEmpresa({commit}, id){
            if(!this.state.lgdc.empresas.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/lgdc/empresas').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setEmpresa', data);
            } else {
                const data = this.state.lgdc.empresas.find(x => x.objectID === id)
                commit('setEmpresa', data);
            }
        },
            async addEmpresa({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/empresas').add(data);
            },
            async updateEmpresa({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/empresas').doc(id).update(data);
            },
            async deleteEmpresa({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                console.log(id);
                await dbClient.collection('/evolbe-modules/lgdc/empresas').doc(id).delete();
            },

        // Categorías
        async getCategorias({commit}){
            if(!this.state.lgdc.categorias.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data;
                await dbClient.collection('/evolbe-modules/lgdc/categorias').orderBy('name.es', 'asc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                        console.log
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setCategorias', data);
                });

            }
        },
        async getCategoria({commit}, id){
            if(!this.state.lgdc.categorias.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/lgdc/categorias').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setCategoria', data);
            } else {
                const data = this.state.lgdc.categorias.find(x => x.objectID === id)
                commit('setCategoria', data);
            }
        },
            async addCategoria({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/categorias').add(data);
            },
            async updateCategoria({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/categorias').doc(id).update(data);
            },
            async deleteCategoria({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/categorias').doc(id).delete();
            },

            async updateAllEmpresasCategoria({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                
                const empresas = [];
                const res =await dbClient.collection('/evolbe-modules/lgdc/empresas').where('category.objectID', '==', id).get();
                res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    empresas.push(e);
                });

                empresas.forEach(async element => {
                    const newElement = element;
                    newElement.category = data;
                    await dbClient.collection('/evolbe-modules/lgdc/empresas').doc(element.objectID).update(newElement);
                });
            },

        // Patrocinios
        async getPatrocinios({commit}){
            if(!this.state.lgdc.patrocinios.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data;
                await dbClient.collection('/evolbe-modules/lgdc/patrocinios').orderBy('dateUpdated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                        console.log
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setPatrocinios', data);
                });

            }
        },
        async getPatrocinio({commit}, id){
            if(!this.state.lgdc.patrocinios.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/lgdc/patrocinios').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setPatrocinio', data);
            } else {
                const data = this.state.lgdc.patrocinios.find(x => x.objectID === id)
                commit('setPatrocinio', data);
            }
        },
            async addPatrocinio({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/patrocinios').add(data);
            },
            async updatePatrocinio({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/patrocinios').doc(id).update(data);
            },
            async deletePatrocinio({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/lgdc/patrocinios').doc(id).delete();
            },


        // OLD
        async copyEmpresas(){
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            const res = await dbClient.collection('/lgdcModule/data/empresas').get();
            const data = [];
            res.forEach(item => {
            const e = {
                objectID: item.id,
                ...item.data()
            }
                data.push(e);
            });

            const res2 = await dbClient.collection('/evolbe-modules/lgdc/categorias').get();
            const categories = [];
            res2.forEach(item => {
            const e = {
                objectID: item.id,
                ...item.data()
            }
                categories.push(e);
            });

            data.forEach(async element => {

                const newImages = [];
                if(element.imagenes && element.imagenes.length){
                    let imageName = element.imagenes[0].replace(/^.*[\\/]/, '');
                    imageName = imageName.split('.').slice(0, -1).join('.')
                    newImages.push({
                        256: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fthumbnails%2F' + imageName+ '_256x256.webp?alt=media',
                        512: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fthumbnails%2F' + imageName+ '_512x512.webp?alt=media',
                        1024: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fthumbnails%2F' + imageName+ '_1024x1024.webp?alt=media',
                        2048: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fthumbnails%2F' + imageName+ '_2048x2048.webp?alt=media',
                    });
                }

                let newTarjeta = {};
                if(element.tarjeta){
                    let tarjetaName = element.tarjeta.replace(/^.*[\\/]/, '');
                    tarjetaName = tarjetaName.split('.').slice(0, -1).join('.')
                    newTarjeta = {
                        256: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fbusiness_cards%2Fthumbnails%2F' + tarjetaName + '_256x256.webp?alt=media',
                        512: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fbusiness_cards%2Fthumbnails%2F' + tarjetaName + '_512x512.webp?alt=media',
                        1024: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fbusiness_cards%2Fthumbnails%2F' + tarjetaName + '_1024x1024.webp?alt=media',
                        2048: 'https://firebasestorage.googleapis.com/v0/b/la-guia-de-chiclana.appspot.com/o/evolbe-modules%2Flgdc%2Fempresas%2Fbusiness_cards%2Fthumbnails%2F' + tarjetaName + '_2048x2048.webp?alt=media',
                    };
                }

                let newCategory = {};
                categories.forEach(cat => {
                    if(cat.name.es === element.categoria[0].value){
                        newCategory = cat;
                    }
                });

                const newSocial = {};

                element.redesSociales.forEach(item => {
                    switch (item.icono) {
                        case 'logo-facebook':
                            newSocial.facebook = item.link;
                        break;
                        case 'logo-instagram':
                            newSocial.instagram = item.link;
                        break;
                        case 'logo-youtube':
                            newSocial.youtube = item.link;
                        break;
                        case 'logo-twitter':
                            newSocial.twitter = item.link;
                        break;
                        case 'logo-linkedin':
                            newSocial.linkedin = item.link;
                        break;
                    }
                });

                const empresa = {
                   active: true,
                   category: newCategory,
                   dateCreated: element.dateUpdated,
                   dateUpdated: 1642520417000,
                   description: element.descripcion,
                   email: element.email,
                   keywords: element.etiquetas,
                   images: newImages,
                   address: element.localizacion,
                   map: element.mapa,
                   name: element.nombre,
                   businessCard: newTarjeta,
                   phone: element.telefono,
                   website: element.web,
                   social: newSocial
                };
                console.log(empresa);
                //await dbClient.collection('/evolbe-modules/lgdc/empresas').add(empresa);
            });
        }
        
    },
}