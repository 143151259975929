import { getFirestoreClient } from '@/firebaseConfig';

export const moduleNavidad121 = {
    namespaced: true,
    state: {
        // Navidad
        orders: [],
        storedOrders: [],
        abandonedOrders: [],
        order: {},
    },
    mutations: { 
        // Navidad

        setOrders(state, payload){
            state.orders = payload
        },
        setStoredOrders(state, payload){
            state.storedOrders = payload
        },
        setAbandonedOrders(state, payload){
            state.abandonedOrders = payload
        },
        setOrder(state, payload){
            state.order = payload
        },
    },
    actions: {
        // Navidad


        // PEDIDOS
        // Orders
        async getOrders({commit}){
            if(!this.state.navidad121.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/restaurant/navidad').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setOrders', data);
                });
            }
        },
        async getOrder({commit}, id){
            if(!this.state.navidad121.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/navidad').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.navidad121.orders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                if(id){
                    await dbClient.collection('/evolbe-modules/restaurant/navidad').doc(id).set(data);
                } else {
                    await dbClient.collection('/evolbe-modules/restaurant/navidad').add(data);
                }
            },
            async updateOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/navidad').doc(id).update(data);
            },
            async deleteOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/navidad').doc(id).delete();
            },

        // Stored Orders
        async getStoredOrders({commit}){
            if(!this.state.navidad121.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const res = await dbClient.collection('/evolbe-modules/restaurant/stored_navidad').orderBy('dateCreated', 'desc').get();

                data = [];
                res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                 data.push(e);
                })
                
                commit('setStoredOrders', data);
            }
        },
        async getStoredOrder({commit}, id){
            if(!this.state.navidad121.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/stored_navidad').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.navidad121.abandonedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_navidad').doc(id).set(data);
            },
            async updateStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_navidad').doc(id).update(data);
            },
            async deleteStoredOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_navidad').doc(id).delete();
            },

        // Abandoned Orders
        async getAbandonedOrders({commit}){
            if(!this.state.navidad121.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_navidad').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setAbandonedOrders', data);
                });
            }
        },
        async getAbandonedOrder({commit}, id){
            if(!this.state.navidad121.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/abandoned_navidad').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.navidad121.abandonedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addAbandonedOrder({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_navidad').add(data);
            },
            async updateAbandonedOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_navidad').doc(id).update(data);
            },
            async deleteAbandonedOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_navidad').doc(id).delete();
            },
    },
}

