<template>
  <div class="inner-content">
    <div class="item-details">
      <h4 class="text-center">Imprimiendo</h4>
      <ion-spinner name="crescent" color="primary" class="loader"></ion-spinner>
    </div>

    <div id="printable" ref="printable">
      <Ticket v-if="type === 'ticket'" :ticket="data"></Ticket>
      <TicketOrder v-if="type === 'ticket-order'" :ticket="data"></TicketOrder>
    </div>

  </div>
</template>

<script>
import { modalController, IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';
import { chevronBackOutline } from 'ionicons/icons';

import {BluetoothSerial} from '@awesome-cordova-plugins/bluetooth-serial';
import html2canvas from 'html2canvas';

import Ticket from '@/components/printables/Ticket.vue';
import TicketOrder from '@/components/printables/TicketOrder.vue';

import { mapState } from 'vuex';
import EscPosEncoder from 'esc-pos-encoder-ionic';

export default defineComponent({
  name: 'BluetoothPrint',
  props: ['data', 'type', 'copies'],
  components: {
    Ticket,
    IonSpinner,
    TicketOrder
  },
  computed: {
      ...mapState(['evolbeUser', 'preferences'])
  },
  methods: {
      print(){
        BluetoothSerial.connect(this.preferences.btPrinter.address)
        .subscribe(() =>{
            BluetoothSerial.write(this.convertToPOS())
            .then(() =>{
            BluetoothSerial.disconnect();
            this.exit();
            this.$toast.add({severity: 'success', summary: 'Impresión Correcta', detail: 'Se ha mandado el documento correctamente a la impresora.', group: 'tr', life: 3000});
            },err=>{
                console.log('error', err);
                this.exit();
                this.$toast.add({severity: 'error', summary: 'Error', detail: err, group: 'tr', life: 3000});
            })
        },err=>{
            console.log('error', err);
            this.exit();
            this.$toast.add({severity: 'error', summary: 'Error', detail: err, group: 'tr', life: 3000});
        })
      },

      async convertToPOS(){
          const elemId = 'printable';
          const node = document.getElementById(elemId);
          const nodeInfo = node.getBoundingClientRect();
          const img = new Image();
          img.setAttribute('crossorigin', 'anonymous'); 
          img.onload = () => {
              const imgWidth = Math.ceil(nodeInfo.width/8)*8;
              const height = Math.ceil(nodeInfo.height/8)*8;
              const encoder = new EscPosEncoder();
              BluetoothSerial.connect(this.preferences.btPrinter.address).subscribe(() => {
                let printData = null;

                for (let i = 1; i <= this.copies; i++) {
                  printData = (encoder.initialize().image(img, imgWidth, height).newline().newline().newline().newline().newline().cut().encode()).buffer;
                  BluetoothSerial.write(printData)
                }

                const printSpace = (encoder.initialize().newline().encode()).buffer;
                BluetoothSerial.write(printSpace)

                BluetoothSerial.disconnect();
                this.exit();
              })
          }
          const canvas = await html2canvas(node, { allowTaint: false, useCORS: true, logging: true });
          const imgSrc = canvas.toDataURL("image/png");
          img.src = imgSrc;
      },

      exit(){
          modalController.dismiss();
      }
  },
  created(){
    if(this.preferences && this.preferences.printType === "bluetooth" && this.preferences.btPrinter){
      setTimeout(() => {
        this.convertToPOS();
      }, 1000);
    } else {
      setTimeout(() => {
        this.exit();
        this.$toast.add({severity: 'error', summary: 'No se encuentra la Impresora', detail: 'Por favor, resvise su configuración de impresora y asegrese de tener el Bluetooth activo y enlazado.', group: 'tr', life: 3000});
      }, 1000);
    }
  },
    setup() {
        return { chevronBackOutline };
    }
});
</script>

<style scoped>
 .loader{
    margin: 5rem auto;
    display: block;
    width: 64px;
    height: 64px;
 }

  #printable{
    position: absolute;
    z-index: -100;
    right: 10000px;
    top: 0;
  }
</style>