import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { store } from './store'
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import i18n from './i18n';

import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import '../public/assets/css/custom.scss';
import '../public/assets/css/nebula.scss';

import * as Sentry from "@sentry/vue";
import { Integrations } from '@sentry/tracing';
import packageInfo from '../package.json'

// Sentry Log
/*Sentry.init({
  dsn: 'https://b1624cdfd568456b892e2ed726507a63@o1221914.ingest.sentry.io/6365312',
  release: `evolbe-business-${packageInfo.version}`,
  tracesSampleRate: 1.0,
  integrations:[
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://business.evolbe.systems'],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ]
})*/

const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(router)
  .use(store)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)

router.isReady().then(() => {
  app.mount('#app');
});