import { getFirestoreClient } from '@/firebaseConfig';

import algoliasearch from 'algoliasearch/lite';

export const moduleClients = {
    namespaced: true,
    state: {
        // Usesrs
        users: [],
        user: {},
    },
    mutations: { 
        // Users
        setUsers(state, payload){
            state.users = payload
        },
        setUser(state, payload){
            state.user = payload
        },

    },
    actions: {
        // Users
        async getUsers({commit}){
            if(!this.state.clients.users.length){
                const searchClient = algoliasearch(
                    this.state.evolbeUser.business[0].algoliaConfig.id,
                    this.state.evolbeUser.business[0].algoliaConfig.key
                );
    
                let data = [];
    
                const index = searchClient.initIndex("users");
                await index
                    .search('')
                    .then(async (res) => {
                        data = res.hits;
    
                        if(res.nbPages > 1){
                        for (let page = 1; page <= res.nbPages; page++) {
                            await index.search('', {page: page})
                            .then((res2) => {
                                data.push(...res2.hits);
                            })
                            .catch(err => {
                                console.log(err);
                            });
                        }
                        }
    
                    })
                    .catch(err => {
                        console.log(err);
                    });
    
                commit('setUsers', data);
            }
        },
        async getUser({commit}, id){
            if(!this.state.clients.users.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/users').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setUser', data);
            } else {
                const data = this.state.clients.users.find(x => x.objectID === id)
                commit('setUser', data);
            }
        },
            async addUser({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/users').doc(id).set(data);
            },
            async updateUser({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/users').doc(id).update(data);
            },
            async deleteUser({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/users').doc(id).delete();
            },


        // DATA TRANSFORM
        async convertUsers({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
  
            const data = []
            await dbClient.collection('/usuarios').onSnapshot(async res => {
              console.log(res);
              res.forEach(item => {
                const e = {
                  id: item.id,
                  ...item.data()
                }
  
                data.push(e);
              });
  
              data.forEach(element => {
                const newData = {
                  firstName: element.nombre && element.nombre.trim(),
                  lastName: element.apellidos && element.apellidos.trim(),
                  email: element.email,
                  phone: element.telefono && element.telefono.trim(),
                  sex: element.sexo && element.sexo.charAt(0),
                  birthDate: element.fecha_nacimiento,
                  address: element.direcciones && element.direcciones.length && element.direcciones[0].direccion.trim(),
                  city: element.direcciones && element.direcciones.length && element.direcciones[0].ciudad.trim(),
                  zipCode: element.direcciones && element.direcciones.length && element.direcciones[0].postal,
                  uid: element.id,
                  notifications:{
                    email: element.configuracion && element.configuracion.notificaciones && element.configuracion.notificaciones.email,
                    mobile: element.configuracion && element.configuracion.notificaciones && element.configuracion.notificaciones.email,
                  },
                  privacy: element.configuracion && element.configuracion.privacidad || true,
                  terms: element.configuracion && element.configuracion.condiciones || true,
                  provider: element.provider || 'email',
                  playerID: element.pushId,
                  dateCreated: element.fecha_registro,
                  lastLogin: element.fecha_login,                  
                }
  
                console.log(newData);
                console.log(element.id);
                //dbClient.collection('/users').doc(element.id).set(newData);
              });
  
  
          });
        },
    },
  }