<template>
  <ion-content>
    <ion-header class="ion-no-border" id="header-menu">
        <ion-item lines="none" class="container header-item-menu">
            <router-link to="/" class="logo-menu" slot="start">
                <img src="assets/business_black.png" alt="Evolbe Business"/>
            </router-link>

            <ion-buttons slot="end"  style="margin-right: 0">
              <ion-button @click="closeModal($event)">
                  <ion-icon :icon="closeOutline" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-buttons>
        </ion-item>
    </ion-header>

    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-lg="3" class="mt-sm mb-sm">

            <!-- Restaurant -->
            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('restaurant')">
              <ion-list-header>
                <ion-label>Restaurante</ion-label>
              </ion-list-header>

              <ion-item button @click="closeModal" :disabled="true" router-link="/modules/restaurant/tpv">
                <ion-label class="module-section">TPV</ion-label>
              </ion-item>

              <ion-item button @click="openList[1] = !openList[1]" >
                <ion-label class="module-section">Carta</ion-label>
                <ion-icon :icon="!openList[1] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>

              <div :class="!openList[1] ? 'hidden' : ''" class="submenu">
                  <ion-item button lines="none" @click="closeModal" router-link="/modules/restaurant/todays-menu">
                    <ion-label color="dark">Menú del Día</ion-label>
                  </ion-item>

                  <ion-item button lines="none" @click="closeModal" :disabled="evolbeUser.business[0].rol === 'editor-menu'" router-link="/modules/restaurant/dishes">
                    <ion-label color="dark">Platos</ion-label>
                  </ion-item>

                  <ion-item button lines="none" @click="closeModal" :disabled="evolbeUser.business[0].rol === 'editor-menu'" router-link="/modules/restaurant/categories">
                    <ion-label color="dark">Categorías</ion-label>
                  </ion-item>

                  <ion-item button @click="closeModal" :disabled="evolbeUser.business[0].rol === 'editor-menu'" router-link="/modules/restaurant/promotions">
                    <ion-label color="dark">Promociones</ion-label>
                  </ion-item>
              </div>

              <ion-item button @click="openList[2] = !openList[2]" v-if="evolbeUser.business[0].rol !== 'editor-menu'">
                  <ion-label class="module-section">Pedidos</ion-label>
                  <ion-icon :icon="!openList[2] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>

              <div :class="!openList[2] ? 'hidden' : ''" class="submenu">
                <ion-item button lines="none" @click="closeModal" router-link="/modules/restaurant/orders">
                  <ion-label color="dark">Activos</ion-label>
                </ion-item>
                <ion-item button lines="none" @click="closeModal" router-link="/modules/restaurant/stored_orders">
                  <ion-label color="dark">Almacén</ion-label>
                </ion-item>
                <ion-item button lines="none" @click="closeModal" router-link="/modules/restaurant/abandoned_orders">
                  <ion-label color="dark">Abandonados</ion-label>
                </ion-item>
              </div>
            </ion-list>

            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('restaurant-carta')">
              <ion-list-header>
                <ion-label>Restaurante</ion-label>
              </ion-list-header>

              <ion-item button @click="openList[1] = !openList[1]" >
                <ion-label class="module-section">Carta</ion-label>
                <ion-icon :icon="!openList[1] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>

              <div :class="!openList[1] ? 'hidden' : ''" class="submenu">
                  <ion-item button lines="none" @click="closeModal" :disabled="evolbeUser.business[0].rol === 'editor-menu'" router-link="/modules/restaurant/dishes">
                    <ion-label color="dark">Platos</ion-label>
                  </ion-item>

                  <ion-item button lines="none" @click="closeModal" :disabled="evolbeUser.business[0].rol === 'editor-menu'" router-link="/modules/restaurant/categories">
                    <ion-label color="dark">Categorías</ion-label>
                  </ion-item>
              </div>
            </ion-list>


            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('shop')">
              <ion-list-header>
                <ion-label>Tienda</ion-label>
              </ion-list-header>

                <ion-item button @click="closeModal" :disabled="true" router-link="/modules/shop/tpv">
                  <ion-label class="module-section">TPV</ion-label>
                </ion-item>

                <ion-item button @click="openList[3] = !openList[3]" >
                  <ion-label class="module-section">Catálogo</ion-label>
                  <ion-icon :icon="!openList[3] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
                </ion-item>


                <div :class="!openList[3] ? 'hidden' : ''" class="submenu">
                      <ion-item button @click="closeModal" router-link="/modules/shop/products">
                        <ion-label color="dark">Productos</ion-label>
                      </ion-item>

                      <ion-item button @click="closeModal" router-link="/modules/shop/categories">
                        <ion-label color="dark">Categorías</ion-label>
                      </ion-item>
                </div>


                <ion-item button @click="openList[4] = !openList[4]">
                    <ion-label class="module-section">Pedidos</ion-label>
                    <ion-icon :icon="!openList[4] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
                </ion-item>

                <div :class="!openList[4] ? 'hidden' : ''" class="submenu">
                      <ion-item button @click="closeModal" router-link="/modules/shop/orders">
                        <ion-label color="dark">Activos</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/shop/stored_orders">
                        <ion-label color="dark">Almacén</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/shop/abandoned_orders">
                        <ion-label color="dark">Abandonados</ion-label>
                      </ion-item>
                </div>
            </ion-list>

            <ion-list lines="none" mode="md" class=" module" v-if="evolbeUser.business[0].modules.includes('hotel')">
              <ion-list-header>
                <ion-label>Hotel</ion-label>
              </ion-list-header>

              <ion-menu-toggle auto-hide="false">
                <ion-item button @click="closeModal" router-link="/modules/hotel/rooms">
                  <ion-label class="module-section">Habitaciones</ion-label>
                </ion-item>
              </ion-menu-toggle>

              <ion-item button @click="openList[5] = !openList[5]">
                  <ion-label class="module-section">Reservas</ion-label>
                  <ion-icon :icon="!openList[5] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>

              <div :class="!openList[5] ? 'hidden' : ''" class="submenu">
                    <ion-item button @click="closeModal" router-link="/modules/hotel/bookings">
                      <ion-label color="dark">Listado</ion-label>
                    </ion-item>
                    <ion-item button @click="closeModal" router-link="/modules/hotel/calendar">
                      <ion-label color="dark">Calendario</ion-label>
                    </ion-item>
                    <ion-item button @click="closeModal" router-link="/modules/hotel/abandoned_bookings">
                      <ion-label color="dark">Abandonadas</ion-label>
                    </ion-item>
              </div>
            </ion-list>

            <ion-list lines="none" mode="md" class=" module" v-if="evolbeUser.business[0].modules.includes('bookings')">
              <ion-list-header>
                <ion-label>Restaurante</ion-label>
              </ion-list-header>

              <ion-item button @click="openList[6] = !openList[6]">
                  <ion-label class="module-section">Reservas</ion-label>
                  <ion-icon :icon="!openList[6] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>

              <div :class="!openList[6] ? 'hidden' : ''" class="submenu">
                    <ion-item button @click="closeModal" router-link="/modules/bookings/bookings">
                      <ion-label color="dark">Listado</ion-label>
                    </ion-item>
                    <ion-item button @click="closeModal" router-link="/modules/bookings/calendar">
                      <ion-label color="dark">Calendario</ion-label>
                    </ion-item>
              </div>
            </ion-list>

            <ion-list lines="none" mode="md" class=" module" v-if="evolbeUser.business[0].modules.some(r => ['experiencias-121', 'navidad-121', 'lgdc'].includes(r))">
              <ion-list-header >
                <ion-label>Personalizado</ion-label>
              </ion-list-header>

                <ion-item button @click="openList[3] = !openList[3]" v-if="evolbeUser.business[0].modules.includes('experiencias-121')">
                  <ion-label class="module-section">Experiencias</ion-label>
                  <ion-icon :icon="!openList[3] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
                </ion-item>


                  <div :class="!openList[3] ? 'hidden' : ''" class="submenu">                    
                      <ion-item button router-link="/modules/experiencias-121/orders">
                        <ion-label @click="closeModal" color="dark">Pedidos</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/experiencias-121/stored_orders">
                        <ion-label color="dark">Almacén</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/experiencias-121/abandoned_orders">
                        <ion-label color="dark">Abandonados</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/experiencias-121/cajas">
                        <ion-label color="dark">Cajas</ion-label>
                      </ion-item>
                  </div>


                <ion-item button @click="openList[100] = !openList[100]" v-if="evolbeUser.business[0].modules.includes('navidad-121')">
                  <ion-label class="module-section">Navidad</ion-label>
                  <ion-icon :icon="!openList[100] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
                </ion-item>


                  <div :class="!openList[100] ? 'hidden' : ''" class="submenu">
                      <ion-item button @click="closeModal" router-link="/modules/navidad-121/orders">
                        <ion-label color="dark">Pedidos</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/navidad-121/stored_orders">
                        <ion-label color="dark">Almacén</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/navidad-121/abandoned_orders">
                        <ion-label color="dark">Abandonados</ion-label>
                      </ion-item>
                  </div>

                <ion-item button @click="openList[101] = !openList[101]" v-if="evolbeUser.business[0].modules.includes('lgdc')">
                  <ion-label class="module-section">La Guía</ion-label>
                  <ion-icon :icon="!openList[100] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
                </ion-item>


                  <div :class="!openList[101] ? 'hidden' : ''" class="submenu">
                      <ion-item button @click="closeModal" router-link="/modules/lgdc/empresas">
                        <ion-label color="dark">Empresas</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/lgdc/categorias">
                        <ion-label color="dark">Categorías</ion-label>
                      </ion-item>
                      <ion-item button @click="closeModal" router-link="/modules/lgdc/patrocinios">
                        <ion-label color="dark">Patrocinios</ion-label>
                      </ion-item>
                  </div>
            </ion-list>

            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('coupons', 'notifications')">
              <ion-list-header>
                <ion-label>Marketing</ion-label>
              </ion-list-header>

              <ion-menu-toggle auto-hide="false">
                <ion-item button :disabled="true" @click="closeModal" router-link="/modules/coupons" v-if="evolbeUser.business[0].modules.includes('coupons')">
                  <ion-label class="module-section">Cupones</ion-label>
                </ion-item>
              </ion-menu-toggle>

              <ion-item button :disabled="true" @click="openList[4] = !openList[4]" v-if="evolbeUser.business[0].modules.includes('notifications-push', 'notifications-email')">
                <ion-label class="module-section">Notificaciones</ion-label>
                <ion-icon :icon="!openList[4] ? chevronDownOutline : chevronUpOutline" color="dark" slot="end"></ion-icon>
              </ion-item>


                <div :class="!openList[4] ? 'hidden' : ''" class="submenu">
                  <ion-item button router-link="/modules/notifications/push" @click="closeModal" v-if="evolbeUser.business[0].modules.includes('notifications-push')">
                    <ion-label color="dark">Push</ion-label>
                  </ion-item>
                </div>        
            </ion-list>

            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('blog')">
              <ion-list-header>
                <ion-label>Blog</ion-label>
              </ion-list-header>

              <ion-menu-toggle auto-hide="false">
                <ion-item button @click="closeModal" router-link="/modules/blog/articles">
                  <ion-label class="module-section">Artículos</ion-label>
                </ion-item>
              </ion-menu-toggle>

              <ion-menu-toggle auto-hide="false">
                <ion-item button @click="closeModal" router-link="/modules/blog/categories">
                  <ion-label class="module-section">Categorías</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>


            <ion-list lines="none" mode="md" class="module" v-if="evolbeUser.business[0].modules.includes('users')">
              <ion-list-header>
                <ion-label>Clientes</ion-label>
              </ion-list-header>

              <ion-item button @click="closeModal" router-link="/modules/clients/users">
                <ion-label class="module-section">Usuarios</ion-label>
              </ion-item>
            </ion-list>

            <ion-list lines="none" mode="md" class="module" v-if="false">
              <ion-list-header>
                <ion-label>Estádisticas</ion-label>
              </ion-list-header>

              <ion-item button @click="closeModal" router-link="/statistics/resume">
                <ion-label class="module-section">Resumen</ion-label>
              </ion-item>
              <ion-item button @click="closeModal" :disabled="true || evolbeUser.business[0].rol === 'admin'" router-link="/statistics/accounting">
                <ion-label class="module-section">Contabilidad</ion-label>
              </ion-item>
            </ion-list>
          </ion-col>

          <ion-col size="12" size-lg="3" class="hide-md hide-sm hide-xs"></ion-col>
          <ion-col size="12" size-lg="2" class="hide-md hide-sm hide-xs"></ion-col>

          <ion-col size="12" size-lg="4" class="hide-md hide-sm hide-xs">
            <img src="https://images.unsplash.com/photo-1462556791646-c201b8241a94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1465&q=80" alt="Evolbe Business" class="image-decor"/>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>






        

        
<!--
                <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class=" module">
                  <ion-card :style="'background-color:' + evolbeUser.business[0].colors.primary + ';color:' + evolbeUser.business[0].colors.contrast + '; min-height: auto;'" class="app-card">
                    <ion-card-content style="padding: 2rem !important">
                      <img :src="evolbeUser.business[0].icon" :alt="evolbeUser.business[0].name" class="app-icon"/>
                      <h5>{{ evolbeUser.business[0].name }}</h5>
                      <p>{{ evolbeUser.business[0].type }}</p>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
-->


              

          
      </ion-content>

</template>

<script>
import { IonContent, IonIcon, IonList, IonItem, IonGrid, IonRow, IonCol, IonListHeader, IonLabel, IonMenuToggle, modalController, IonButton, IonButtons, IonHeader } from '@ionic/vue';
import { searchOutline, chevronUpOutline, giftOutline, chevronDownOutline, bagHandleOutline, closeOutline, homeOutline, bedOutline, calendarOutline, shirtOutline, cartOutline, fastFoodOutline, restaurantOutline, storefrontOutline, cubeOutline, businessOutline, newspaperOutline, ticketOutline, notificationsOutline, peopleOutline, settingsOutline, personOutline, ellipsisVerticalOutline} from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'Menu',
  components: {
    IonContent,
    IonIcon,
    IonList,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    IonListHeader,
    IonLabel,
    IonMenuToggle,
    IonButton,
    IonButtons,
    IonHeader
  },
  data() {
    return {
      openList: [],
    }
  },
  computed: {
      ...mapState(['evolbeUser'])
  },
  methods:{
    ...mapActions(['logout']),

    closeModal(){
      modalController.dismiss();
    },

    closeSession(){
      this.logout();
      this.closeModal();
    }
  },
  setup() {
    return { searchOutline, chevronUpOutline, closeOutline, chevronDownOutline, bagHandleOutline, giftOutline, homeOutline, bedOutline, calendarOutline, shirtOutline, cartOutline, fastFoodOutline, restaurantOutline, storefrontOutline, cubeOutline, businessOutline, newspaperOutline, ticketOutline, notificationsOutline, peopleOutline, settingsOutline, personOutline, ellipsisVerticalOutline };
  }
});
</script>

<style>
    .modules{
      padding: 2rem;
      padding-top: 0;
    }

    .module{
      padding-bottom: 1.5rem !important;
    }

    .module .module-section{
      font-size: 1.5rem;
      font-weight: bold;
    }

    .module ion-list-header ion-label{
      font-size: 0.75rem;
      font-weight: 500;
    }

    .submenu{
      height: auto;
      opacity: 1;
      visibility: visible;
      transition: all .5s ease-in-out;
    }

    .submenu ion-label{
      font-weight: 300;
      transition: 0.2s;
    }

    .submenu ion-label:hover{
      color: var(--ion-color-primary-shade);
    }

    .hidden{
      height: 0px;
      opacity: 0;
      visibility: hidden;
      transition: all 0s ease-in-out;
    }

    /* Header */
    #header-menu{
        height: 5rem;
        padding: 0;
    }

    .header-item-menu{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        border-bottom: 1px solid var(--ion-color-medium);
    }

    .logo-menu{
        display: block;
        cursor: pointer;
        width: auto;
        height: 30px;
        margin: 0;

    }

    .logo-menu img{
        height: 100%;
    }

    /* Imagen */
    .image-decor{
      width: 100%;
      padding: 2rem;
      border-radius: 4rem;
      height: 75vh;
      object-fit: cover;
      object-position: center;
    }
    
</style>