import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
// Module: Hotel
import Rooms from '../views/modules/hotel/Rooms.vue'
import EditRoom from '../views/modules/hotel/EditRoom.vue'
import Bookings from '../views/modules/hotel/Bookings.vue'
import Calendar from '../views/modules/hotel/Calendar.vue'
// Module: Blog
import Articles from '../views/modules/blog/Articles.vue'
import EditArticle from '../views/modules/blog/EditArticle.vue'
// Module: Restaurant


// Module: Restaurant (Old)
import Dishes from '../views/modules/restaurant/Dishes.vue'
import EditDish from '../views/modules/restaurant/EditDish.vue'
  import DishesCategories from '../views/modules/restaurant/DishesCategories.vue'
  import EditDishCategory from '../views/modules/restaurant/EditDishCategory.vue'
import TodaysMenu from '../views/modules/restaurant/TodaysMenu.vue'
import RestaurantOldOrders from '../views/modules/restaurant/Orders.vue'

// Custom
  // Module: LGDC
  import Empresas from '../views/modules/custom/lgdc/Empresas.vue'
  import EditEmpresa from '../views/modules/custom/lgdc/EditEmpresa.vue'
    import LgdcCategories from '../views/modules/custom/lgdc/Categorias.vue'
    import LgdcEditCategory from '../views/modules/custom/lgdc/EditCategoria.vue'

  // Module: Experiencias 12+1
  import ExperienciasOrders from '../views/modules/custom/experiencias-121/Orders.vue'
    import Experiencias from '../views/modules/custom/experiencias-121/Experiencias.vue'
    import EditExperiencia from '../views/modules/custom/experiencias-121/EditExperiencia.vue'

// Module: Coupons
import Coupons from '../views/modules/coupons/Coupons.vue'
import EditCoupon from '../views/modules/coupons/EditCoupon.vue'

// Module: Notifications
import Pushs from '../views/modules/notifications/Pushs.vue'
import EditPush from '../views/modules/notifications/EditPush.vue'

import { store } from '../store'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },

  // Module: Hotel
  {
    path: '/modules/hotel/rooms',
    name: 'Rooms',
    component: Rooms,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/hotel/rooms/:id',
    name: 'EditRoom',
    component: EditRoom,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/hotel/bookings',
    name: 'Bookings',
    component: Bookings,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/hotel/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/hotel/abandoned_bookings',
    name: 'AbandonedBookings',
    component: () => import('@/views/modules/hotel/AbandonedBookings.vue'),
    meta: {requiresAuth: true}
  },


  // Module: Bookings
  {
    path: '/modules/bookings/bookings',
    name: 'RestaurantBookings',
    component: () => import('@/views/modules/bookings/Bookings.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/bookings/calendar',
    name: 'RestaurantCalendar',
    component: () => import('@/views/modules/bookings/Calendar.vue'),
    meta: {requiresAuth: true}
  },

  // Module: Blog
  {
    path: '/modules/blog/articles',
    name: 'Articles',
    component: Articles,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/blog/articles/:id',
    name: 'EditArticle',
    component: EditArticle,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/blog/categories',
    name: 'BlogCategories',
    component: () => import('@/views/modules/blog/Categories.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/blog/categories/:id',
    name: 'BlogCategory',
    component: () => import('@/views/modules/blog/EditCategory.vue'),
    meta: {requiresAuth: true}
  },

  // Module: Shop
  {
    path: '/modules/shop/products',
    name: 'Products',
    component: () => import('@/views/modules/shop/Products.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/shop/products/:id',
    name: 'EditProduct',
    component: () => import('@/views/modules/shop/EditProduct.vue'),
    meta: {requiresAuth: true}
  },
    {
      path: '/modules/shop/categories',
      name: 'ShopCategories',
      component: () => import('@/views/modules/shop/Categories.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/shop/categories/:id',
      name: 'ShopEditCategory',
      component: () => import('@/views/modules/shop/EditCategory.vue'),
      meta: {requiresAuth: true}
    },  
  {
    path: '/modules/shop/orders',
    name: 'ShopOrders',
    component: () => import('@/views/modules/shop/Orders.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/shop/stored_orders',
    name: 'ShopStoredOrders',
    component: () => import('@/views/modules/shop/StoredOrders.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/shop/abandoned_orders',
    name: 'ShopAbandonedOrders',
    component: () => import('@/views/modules/shop/AbandonedOrders.vue'),
    meta: {requiresAuth: true}
  },

  // Module Restaurant
  {
    path: '/modules/restaurant/tpv',
    name: 'RestaurantTPV',
    component: () => import('@/views/modules/restaurant/Tpv.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/restaurant/dishes',
    name: 'Dishes',
    component: Dishes,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/restaurant/dishes/:id',
    name: 'EditDish',
    component: EditDish,
    meta: {requiresAuth: true}
  },
    {
      path: '/modules/restaurant/categories',
      name: 'DishesCategories',
      component: DishesCategories,
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/restaurant/categories/:id',
      name: 'EditDishCategory',
      component: EditDishCategory,
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/restaurant/promotions',
      name: 'Promotions',
      component: () => import('@/views/modules/restaurant/Promotions.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/restaurant/promotions/:id',
      name: 'EditPromotion',
      component: () => import('@/views/modules/restaurant/EditPromotion.vue'),
      meta: {requiresAuth: true}
    },
  {
    path: '/modules/restaurant/todays-menu',
    name: 'TodaysMenu',
    component: TodaysMenu,
    meta: {requiresAuth: true}
  },
    {
      path: '/modules/restaurant/todays-menu/:id',
      name: 'EditTodaysMenu',
      component: () => import('@/views/modules/restaurant/EditTodaysMenu.vue'),
      meta: {requiresAuth: true}
    },
  {
    path: '/modules/restaurant/orders',
    name: 'RestaurantOldOrders',
    component: RestaurantOldOrders,
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/restaurant/stored_orders',
    name: 'RestaurantStoredOrders',
    component: () => import('@/views/modules/restaurant/StoredOrders.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/modules/restaurant/abandoned_orders',
    name: 'Restaurant-AbandonedOrders',
    component: () => import('@/views/modules/restaurant/AbandonedOrders.vue'),
    meta: {requiresAuth: true}
  },

  /* Statistics */
  {
    path: '/statistics/resume',
    name: 'StatisticsResume',
    component: () => import('@/views/statistics/Resume.vue'),
    meta: {requiresAuth: true}
  },

  // Coupons
  {
    path: '/modules/coupons',
    name: 'Coupons',
    component: Coupons,
    meta: {requiresAuth: true}
  },
    {
      path: '/modules/coupons/:id',
      name: 'EditCoupon',
      component: EditCoupon,
      meta: {requiresAuth: true}
    },
  // Notifications
  {
    path: '/modules/notifications/pushs',
    name: 'Pushs',
    component: Pushs,
    meta: {requiresAuth: true}
  },
    {
      path: '/modules/notifications/pushs/:id',
      name: 'EditPush',
      component: EditPush,
      meta: {requiresAuth: true}
    },

  // Users
  {
    path: '/modules/clients/users',
    name: 'Users',
    component: () => import('@/views/modules/clients/Users.vue'),
    meta: {requiresAuth: true}
  },


  // Custom
    // Module: LGDC
    {
      path: '/modules/lgdc/empresas',
      name: 'Empresas',
      component: Empresas,
      meta: {requiresAuth: true}
    },
      {
        path: '/modules/lgdc/categorias',
        name: 'LgdcCategorias',
        component: LgdcCategories,
        meta: {requiresAuth: true}
      },
      {
        path: '/modules/lgdc/categorias/:id',
        name: 'LgdcEditCategoria',
        component: LgdcEditCategory,
        meta: {requiresAuth: true}
      },
    {
      path: '/modules/lgdc/empresas/:id',
      name: 'EditEmpresa',
      component: EditEmpresa,
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/lgdc/patrocinios',
      name: 'LGDCPatrocinios',
      component: () => import('@/views/modules/custom/lgdc/Patrocinios.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/lgdc/patrocinios/:id',
      name: 'EditLGDCPatrocinios',
      component: () => import('@/views/modules/custom/lgdc/EditPatrocinio.vue'),
      meta: {requiresAuth: true}
    },

    // Experiencias 12+1
    {
      path: '/modules/experiencias-121/orders',
      name: 'ExperienciasOrders',
      component: ExperienciasOrders,
      meta: {requiresAuth: true}
    },
      {
        path: '/modules/experiencias-121/tpv',
        name: 'ExperienciasTPV',
        component: () => import('@/views/modules/custom/experiencias-121/Tpv.vue'),
        meta: {requiresAuth: true}
      },
    {
      path: '/modules/experiencias-121/stored_orders',
      name: 'ExperienciasStoredOrders',
      component: () => import('@/views/modules/custom/experiencias-121/StoredOrders.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/experiencias-121/abandoned_orders',
      name: 'ExperienciasAbandonedOrders',
      component: () => import('@/views/modules/custom/experiencias-121/AbandonedOrders.vue'),
      meta: {requiresAuth: true}
    },
    
    {
      path: '/modules/experiencias-121/cajas',
      name: 'CajasExperiencias',
      component: Experiencias,
      meta: {requiresAuth: true}
    },
      {
        path: '/modules/experiencias-121/cajas/:id',
        name: 'EditCajaExperiencia',
        component: EditExperiencia,
        meta: {requiresAuth: true}
      },

    // Navidad 12+1
    {
      path: '/modules/navidad-121/orders',
      name: 'NavidadOrders',
      component: () => import('@/views/modules/custom/navidad-121/Orders.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/navidad-121/stored_orders',
      name: 'NavidadStoredOrders',
      component: () => import('@/views/modules/custom/navidad-121/StoredOrders.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/navidad-121/abandoned_orders',
      name: 'NavidadAbandonedOrders',
      component: () => import('@/views/modules/custom/navidad-121/AbandonedOrders.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/modules/navidad-121/tpv',
      name: 'NavidadTPV',
      component: () => import('@/views/modules/custom/navidad-121/Tpv.vue'),
      meta: {requiresAuth: true}
    },

    // Business
    {
      path: '/business/settings',
      name: 'BusinessSettings',
      component: () => import('@/views/business/Settings.vue'),
      meta: {requiresAuth: true}
    },
    {
      path: '/business/profile',
      name: 'BusinessProfile',
      component: () => import('@/views/business/Profile.vue'),
      meta: {requiresAuth: true}
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('loadLocalStorage').then(() => {
    if (to.meta.requiresAuth) {
      if (store.getters.isLogin) {
        next();
      } else {
        next('/login');
      }
    } else {
      if(to.path === '/login'){
        if (store.getters.isLogin) {
          next('/dashboard');
        } else {
          next();
        }
      }
    }
  }, error => {
      console.log(error);
  })         

});

export default router
