<template>
    <div id="printTicket" :class="preferences && preferences.paperSize === '80mm' ? 'ochenta' : 'cincuenta-seis'">
        <div id="img-out">
            <img style="display: block; width: 290px; margin:0 auto;" :src="evolbeUser.business[0].invoicing.logo" :alt="evolbeUser.business[0].name"/>
        </div>
        <p style="text-align: center; padding: 0; margin-top: 32px;">{{ evolbeUser.business[0].invoicing.name}} </p>
        <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.address}} </p>
        <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.cif}} </p>
        <p style="text-align: center; padding: 0; margin:0;">{{ evolbeUser.business[0].invoicing.phone}} </p>

        <hr>

        <p>PEDIDO: <b v-if="ticket.number">#{{ ticket.number }}</b><b v-if="!ticket.number">#{{ ticket.objectID }}</b></p>
        <p style="padding: 0">FECHA: <b>{{ fecha(ticket.datePurchased) }}</b></p>

        <hr>

        <p>CLIENTE: <b>{{ ticket.customer.firstName }}, {{ ticket.customer.lastName }}</b></p>
        <p v-if="ticket.delivery">DIRECCIÓN: <b>{{ ticket.customer.address }}</b></p>
        <p v-if="!ticket.delivery">DIRECCIÓN: <b>Recogida en el local</b></p>
        <p v-if="ticket.deliveryDate">DÍA ENTREGA: <b>{{ fechaEntrega(ticket.deliveryDate) }}</b></p>
        <p>HORA ENTREGA: <b>{{ ticket.deliveryHour }}</b></p>
        <p style="padding: 0">TELÉFONO: <b>{{ ticket.customer.phone }}</b></p>

        <hr>

        <table>
        <tr>
            <td width="10%">UD.</td>
            <td width="65%">ARTICULO</td>
            <td width="25%" align="right">PRECIO</td>
        </tr>

        <tr v-for="dish of ticket.items" :key="dish.id">
            <td colspan="3"> 
            <table width="100%">
                <tr>
                <td width="10%"><span>{{ dish.quantity }}</span></td>
                <td width="65%"><b>{{ dish.name }}</b></td>
                <td width="25%" align="right">{{ dish.price.toFixed(2) }}€</td>
                </tr>
        
                <tr v-if="dish.modifiers && dish.modifiers.length">
                <td></td>
                <td>
                    <span v-for="modifier in dish.modifiers" :key="modifier.key">
                        <p v-for="option in modifier.options.filter(x => x.selected === true)" :key="option"><i>{{ option.name }}</i></p>
                    </span>
                </td>
                </tr>

                <tr v-for="group in dish.groups" :key="group.id">
                <td colspan="3">
                    <table width="100%" v-for="article in group.articles.filter(x => x.selected)" :key="article.id">
                        <tr>
                        <td width="10%"><span>1</span></td>
                        <td width="65%">{{ article.name }}</td>
                        <td width="25%" align="right">PROMO</td>
                        </tr>
                
                        <tr>
                        <td></td>
                        <td>
                            <span v-for="modifier in article.modifiers" :key="modifier.key">
                                <p v-for="option in modifier.options.filter(x => x.selected === true)" :key="option">{{ option.name }} </p>
                            </span>
                        </td>
                        </tr>
                    </table>
                </td>
                </tr>
            </table>
            </td>
        </tr>
        </table>

        <hr>

        <table>
        <tr>
            <td width="90%">SUBTOTAL:</td>
            <td width="10%" align="right">{{ ticket.subtotal.toFixed(2) }}€</td>
        </tr>
        <tr v-if="ticket.coupon">
            <td width="90%">DESCUENTO CUPON ({{ ticket.coupon.discount }}%):</td>
            <td width="10%" align="right">{{ ticket.discount.toFixed(2) }}€</td>
        </tr>
        <tr v-if="ticket.surcharge">
            <td width="90%">RECARGO:</td>
            <td width="10%" align="right">{{ (3).toFixed(2) }}€</td>
        </tr>
        <tr>
            <td width="90%">ENVíO:</td>
            <td width="10%" align="right">{{ ticket.shipping.toFixed(2) }}€</td>
        </tr>
        <tr>
            <td width="90%"><b>TOTAL:</b></td>
            <td width="10%" align="right"><b>{{ ticket.total.toFixed(2) }}€</b></td>
        </tr>
        </table>

        <h5 style="text-align: center; margin: 16px;">IVA 10% INCLUIDO</h5>

        <hr>

        <p>FORMA DE PAGO: <b><span v-if="ticket.payment.method === 'stripe'">Pago Online</span><span v-if="ticket.payment.method === 'cash'">Efectivo</span><span v-if="ticket.payment.method === 'datahpone'">Tarjeta</span></b></p>
        <p v-if="ticket.coupon && ticket.coupon.code">CUPÓN: <b>{{ ticket.coupon.code }}</b></p>
        <p v-if="ticket.deliveryName">DESTINATARIO: <b>{{ ticket.deliveryName }}</b></p>
        <p>OBSERVACIONES: <br><b>{{ ticket.observations }}</b></p>
    </div>

</template>

<script>
import moment from 'moment';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'Ticket',
  components: {

  },
  props: ['ticket'],
  computed: {
    ...mapState(['evolbeUser', 'preferences'])
  },
  methods: {
    fecha(date){
        return moment(date).format('DD/MM/YYYY - HH:mm');
    },

    fechaEntrega(date){
        let newDate;
        if(date.seconds){
        newDate = new Date(date.seconds * 1000).getTime();
        } else if(date._seconds){
        newDate = new Date(date._seconds * 1000).getTime();
        }

        return moment(newDate).format('dddd, DD/MM/YYYY');
    },
  },
});
</script>

<style>
    #printTicket{
        margin: 24px auto;
        font-weight: 500 !important;
        text-transform: uppercase;
        color: #000 !important;
        font-size: 1.5rem;
    }

    .ochenta{
        width: 570px; /* 80mm */
    }

    .cincuenta-seis{
        width: 350px; /* 56mm */
    }

    #printTicket table{
        width: 100%;
    }

    #printTicket table td{
        font-weight: 500 !important;
        font-size: 1.5rem;
    }

    #printTicket hr{
        color: #000;
        height: 0px;
        border-width: 1px !important;
        margin: 12px 0;
    }

    #printTicket p{
        font-weight: 500 !important;
        font-size: 1.5rem;
    }

    #printTicket b > span{
        font-weight: bold !important;
    }   

    #printTicket span{
        font-weight: 500 !important;
        font-size: 1.5rem;
    }    
</style>