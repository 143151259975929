<template>
  <ion-page>
    <ion-content class="top" :full-screens="true">
      <ion-header class="ion-no-border" >
        <ion-toolbar color="transparent" class="header-aside-container half-box">
          <ion-buttons slot="start" style="margin: 0">
            <ion-button @click="closeDetails" style="padding: 0">
              <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
            </ion-button>
          </ion-buttons>
          <p style="margin-left: 0.5rem"><b>Nuevo Pedido</b></p>
        </ion-toolbar>
      </ion-header>

      <div class="item-details box">
          <h2 style="text-transform: capitalize; padding-bottom: 1rem" class="bb"><b>Menú de Navidad</b></h2>

          <section id="menuNavidad">

              <swiper id="slider-steps" :autoHeight="true" @swiper="setStepSlider">

                  <swiper-slide class="slide-step" :key="menu">

                    <ion-list lines="none" class="element-list">
                      <ion-item-group v-for="(element, i) in menuNavidad" :key="element">
                        <ion-list-header class="subtitulo">
                          <ion-label text-wrap class="menu-title-navidad">{{ element.name }}</ion-label>
                        </ion-list-header>

                        <ion-item v-for="(option, e) in element.options" :key="e" class="element-option">
                          <ion-label text-wrap>{{ option.name }}</ion-label>
                          <ion-buttons slot="end" class="quantitys">
                            <ion-button @click="lessQtyMenu(i,e)" >
                                <ion-icon :icon="removeOutline" color="dark"></ion-icon>
                            </ion-button>
                            
                            <ion-label>{{ menus[i].filter(x => x.name === option.name).length }}</ion-label>
                    
                            <ion-button @click="plusQtyMenu(i,e)">
                                <ion-icon :icon="addOutline" color="dark"></ion-icon>
                            </ion-button>
                          </ion-buttons>
                        </ion-item>

                        <hr class="separator">
                      </ion-item-group>
                    </ion-list>

                    <label for="price">Menús Totales</label>
                    <InputNumber id="price" class="inputNumber" v-model="totalMenus" showButtons :min="1" locale="es-ES" />


                    <ion-button style="margin-top: 2rem" @click="generateMenus" expand="full" shape="round" color="dark">
                      <ion-label>Siguiente</ion-label>
                    </ion-button>
                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title-navidad" style="padding-top: 2rem">Añadir Platos de la Carta Navidad</h5>

                    <ion-list lines="none" class="element-list">
                        <ion-item class="dish-item" lines="none" v-for="(element, i) in cartaNavidad" :key="element">
                            <ion-label text-wrap class="dish-data">
                                <h5 class="dish-name">{{ element.name }}</h5>
                                <p class="dish-price">{{ element.price.toFixed(2) }}€</p>
                            </ion-label>
                                                
                            <ion-buttons slot="end" class="quantitys">
                              <ion-button @click="lessQty(i)" v-if="order.items.find(x => x.name === element.name)">
                                  <ion-icon :icon="removeOutline" color="dark"></ion-icon>
                              </ion-button>
                              
                              <ion-label v-if="order.items.find(x => x.name === element.name)">{{ element.quantity }}</ion-label>
                      
                              <ion-button @click="plusQty(i)">
                                  <ion-icon :icon="addOutline" color="dark"></ion-icon>
                              </ion-button>
                            </ion-buttons>
                        </ion-item>

                    </ion-list>

                    <ion-button style="margin-top: 2rem" @click="nextStep" expand="full" shape="round" color="dark" :disabled="!order.items.length">
                      <ion-label>Siguiente</ion-label>
                    </ion-button>
                    <ion-button style="margin-top: 1rem" expand="full" fill="outline" shape="round" color="dark" @click="backStep">
                      <ion-label>Volver</ion-label>
                    </ion-button>
                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title-navidad" style="padding: 1rem 0">Cliente</h5>
                    <form>
                      <ion-grid>
                          <ion-row>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.firstName.$errors.length }">
                              <InputText class="input" id="firstName" placeholder="Nombre*" type="text" v-model.trim.lazy="v$.customer.firstName.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.firstName.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.lastName.$errors.length }">
                              <InputText class="input" id="lastName" placeholder="Apellidos*" type="text" v-model.trim.lazy="v$.customer.lastName.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.lastName.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.birthDate.$errors.length }">
                              <Calendar autocomplete="off" id="birthDate" class="input" placeholder="Fecha de Nacimiento" :manualInput="false" v-model.lazy="v$.customer.birthDate.$model" :maxDate="maxDate()" dateFormat="dd-mm-yy" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.birthDate.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.sex.$errors.length }">
                              <Dropdown v-model.trim.lazy="v$.customer.sex.$model" class="input" :options="sexs" optionLabel="name" optionValue="code" placeholder="Sexo" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.sex.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.phone.$errors.length }">
                              <InputText class="input" id="phone" placeholder="Teléfono*" type="tel" minLength="9" maxLength="9" v-model.trim.lazy="v$.customer.phone.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.phone.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                            <ion-col size="12" class="box-input" :class="{ error: v$.customer.email.$errors.length }">
                              <InputText class="input" id="email" placeholder="Email*" type="email" v-model.trim.lazy="v$.customer.email.$model" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.customer.email.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>

                            <h5 class="weight-bold" style="padding: 1rem 0; width: 100%">Dirección de Entrega</h5>
                            <ion-col size="12" class="box-input" :class="{ error: v$.order.delivery.$errors.length }">
                              <Dropdown v-model.lazy="v$.order.delivery.$model" class="input" :options="deliveryOptions" optionLabel="name" optionValue="code" placeholder="Entrega" />

                              <div class="input-errors" v-for="(error, index) of v$.order.delivery.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>

                            <ion-row v-if="order.delivery" style="width: 100%">
                              <ion-col size="12" class="box-input" :class="{ error: v$.customer.address.$errors.length }">
                                    <InputText class="input" id="address" placeholder="Dirección Completa*" type="text" v-model.trim.lazy="v$.customer.address.$model" />
                                    <!-- error message -->
                                    <div class="input-errors" v-for="(error, index) of v$.customer.address.$errors" :key="index">
                                    <h6 class="error-msg">{{ error.$message }}</h6>
                                    </div>
                                </ion-col>
                                <ion-col size="12" class="box-input" :class="{ error: v$.customer.city.$errors.length }">
                                    <InputText class="input" id="city" placeholder="Ciudad*" type="text" v-model.trim.lazy="v$.customer.city.$model" />
                                    <!-- error message -->
                                    <div class="input-errors" v-for="(error, index) of v$.customer.city.$errors" :key="index">
                                    <h6 class="error-msg">{{ error.$message }}</h6>
                                    </div>
                                </ion-col>
                                <ion-col size="12" class="box-input" :class="{ error: v$.customer.zipCode.$errors.length }">
                                    <InputText class="input" id="zipCode" placeholder="Código Postal*" type="number" minLength="5" maxLength="5" v-model.trim.lazy="v$.customer.zipCode.$model" />
                                    <!-- error message -->
                                    <div class="input-errors" v-for="(error, index) of v$.customer.zipCode.$errors" :key="index">
                                    <h6 class="error-msg">{{ error.$message }}</h6>
                                    </div>
                              </ion-col>
                            </ion-row>

                            <ion-col size="12" class="box-input" :class="{ error: v$.order.deliveryDate.$errors.length }">
                              <Dropdown v-model.lazy="v$.order.deliveryDate.$model" class="input" :options="deliveryDates" optionLabel="name" optionValue="code" placeholder="Día de Entrega*" />
                              <!-- error message -->
                              <div class="input-errors" v-for="(error, index) of v$.order.deliveryDate.$errors" :key="index">
                                <h6 class="error-msg">{{ error.$message }}</h6>
                              </div>
                            </ion-col>
                          </ion-row>

                          <h6 class="text-center">Los campos marcados con (*) son obligatorios.</h6>
                      </ion-grid>

                      <ion-button style="margin-top: 2rem" @click="nextStep" expand="full" shape="round" color="dark" :disabled="v$.customer.$invalid || v$.order.$invalid">
                        <ion-label>Siguiente</ion-label>
                      </ion-button>
                      <ion-button style="margin-top: 1rem" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                        <ion-label>Atrás</ion-label>
                      </ion-button>
                    </form>
                  </swiper-slide>

                  <swiper-slide class="slide-step">
                    <h5 class="section-title-navidad" style="padding-top: 2rem">Finalizar</h5>

                    <ion-list lines="none" class="platos">
                        <div v-for="(dish, i) in order.items" :key="dish.id" >
                            <ion-item class="dish">
                                <ion-label class="name weight-bold">{{ dish.name }}</ion-label>
                                
                                <ion-buttons slot="end" class="quantitys">
                                    <ion-button @click="lessQtyCart(i)">
                                        <ion-icon :icon="removeOutline"></ion-icon>
                                    </ion-button>
                                    
                                    <ion-label>{{ dish.quantity }}</ion-label>
                            
                                    <ion-button @click="plusQtyCart(i)">
                                        <ion-icon :icon="addOutline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>

                                <p class="text-dark price" slot="end" v-if="dish.price">{{ (dish.price * dish.quantity).toFixed(2) }} €</p>
                                <p class="text-dark price" slot="end" v-if="!dish.price">GRATIS</p>
                            </ion-item>

                            <ion-list class="subplatos" v-if="dish.items" lines="none">
                                <ion-item v-for="item in dish.items" :key="item.id" class="subitem">
                                    <ion-label class="name">{{ item.name }}</ion-label>
                                
                                    <ion-buttons slot="end" class="quantitys">                                       
                                        <ion-label>{{ dish.quantity }}</ion-label>
                                    </ion-buttons>

                                    <p class="text-dark price" slot="end">NAVD.</p>
                                </ion-item>
                            </ion-list>
                        </div>                     
                    </ion-list>

                    <ion-list class="totals" lines="none">
                        <ion-item>
                            <ion-label class="weight-bold">Subtotal</ion-label>
                            <ion-text class="price weight-bold" slot="end">{{ order.subtotal.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item>
                            <ion-label class="weight-bold">Envío</ion-label>
                            <ion-text class="price weight-bold" slot="end">{{ order.shipping.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item v-if="order.discount">
                            <ion-label class="weight-bold">Descuento Cupón ({{ order.coupon.code }})</ion-label>
                            <ion-text class="price weight-bold" slot="end">-{{ order.discount.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item class="total">
                            <ion-label class="weight-bold">Total</ion-label>
                            <ion-text class="price weight-bold" color="dark" slot="end">{{ order.total.toFixed(2) }}€</ion-text>
                        </ion-item>
                        <ion-item>
                            <p class="text-right" style="width: 100%">Incluye ({{ order.tax.toFixed(2) }}€) de I.V.A.</p>
                        </ion-item>
                    </ion-list>

                    <div style="margin-top: 2rem">
                      <Textarea v-model="order.observations" placeholder="Observaciones" :autoResize="true" rows="3"/>
                    </div>

                    <div size="12">
                      <Dropdown v-model.lazy="order.payment.method" class="input" :options="paymentMethods" optionLabel="name" optionValue="code" placeholder="Método de Pago*" />
                    </div>

                    <ion-button style="margin-top: 2rem" @click="payment" expand="full" shape="round" color="dark" :disabled="order.total <= 0">
                      <ion-label>Confirmar Pedido</ion-label>
                    </ion-button>
                    <ion-button style="margin-top: 1rem" @click="backStep" expand="full" fill="outline" shape="round" color="dark">
                      <ion-label>Atrás</ion-label>
                    </ion-button>
                  </swiper-slide>
              </swiper>
          </section>   
      </div>
  </ion-content>
</ion-page>
</template>

<script>
import { IonPage, IonContent, IonicSwiper, IonLabel, IonButtons, IonText, IonButton, IonListHeader, IonList, IonItem, IonIcon, IonItemGroup, IonGrid, IonRow, IonCol, loadingController, IonHeader, IonToolbar } from '@ionic/vue';
import { defineComponent } from 'vue';
import { chevronBackOutline, addOutline, removeOutline } from 'ionicons/icons';

import Textarea from 'primevue/textarea';

import SwiperCore, { Navigation, Pagination, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/swiper-bundle.min.css';
import '@ionic/vue/css/ionic-swiper.css';

SwiperCore.use([IonicSwiper, Navigation, Pagination, Controller]);

import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import moment from 'moment';

import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'NewMenuNavidad',
  components: {
    IonPage, 
    IonContent,
    Swiper,
    SwiperSlide,
    Calendar,
    InputText,
    Dropdown,
    IonLabel,
    IonButton,
    IonListHeader,
    IonList,
    IonItem,
    IonItemGroup,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonText,
    Textarea,
    IonIcon,
    IonHeader,
    IonToolbar,
    InputNumber
  },
  data() {
    return {
        ProductSlider: { value: null },
        stepSlider: { value: null },

        deliveryDates: [
          {name: 'Nochevieja (31 dic)', code: 1640908800000},
          {name: 'Día de Reyes (6 dic)', code: 1670284800000},
        ],

        deliveryOptions: [
          {name: 'Entrega a domicilio', code: true},
          {name: 'Recogida en el restaurante', code: false},
        ],

        paymentMethods: [
          {name: 'Efectivo', code: 'cash'},
          {name: 'Tarjeta', code: 'dataphone'},
        ],

        menuNavidad: [
            {
                name: 'PRIMER ENTRANTE',
                options: [
                    { name: 'Tabla de embutidos ibéricos seleccionados y queso manchego', },
                    { name: 'Tabla de quesos Asturianos y sus maridajes', },
                    { name: 'Sopa de marisco al estilo tradicional', },
                    { name: 'Crema de nécoras y tartar de buey de mar', },
                    { name: 'Pastel de centollo con sus tostas', },
                    { name: 'Salpicón de pulpo y langostinos con vinagreta de pimientos asados', },
                ]
            },
            {
                name: 'SEGUNDO ENTRANTE',
                options: [
                    { name: 'Tabla de embutidos ibéricos seleccionados y queso manchego', },
                    { name: 'Tabla de quesos Asturianos y sus maridajes', },
                    { name: 'Sopa de marisco al estilo tradicional', },
                    { name: 'Crema de nécoras y tartar de buey de mar', },
                    { name: 'Pastel de centollo con sus tostas', },
                    { name: 'Salpicón de pulpo y langostinos con vinagreta de pimientos asados', },
                ]
            },
            {
                name: 'PRIMEROS',
                options: [
                    { name: 'Lomos de pixin en su jugo con zamburiñas', },
                    { name: 'Rodaballo al albariño con guiso de calamares de potera y arroz basmati', },
                    { name: 'Bombón de merluza del pincho relleno de marisco en salsa de oricios', },
                ]
            },
            {
                name: 'SEGUNDOS',
                options: [
                    { name: 'Lechazo a baja temperatura con patatas al ajo y romero', },
                    { name: 'Carrilleras de bellota confitadas al oporto con cremoso de patata gratinado', },
                    { name: 'Cochinillo a baja temperatura con papas arrugadas y sus mojos', },
                ]
            },
            {
                name: 'POSTRE',
                options: [
                    { name: 'Milhojas de turrón', },
                ]
            },
        ],

        cartaNavidad: [
            {
                name: 'Tabla de embutidos ibéricos seleccionados y queso manchego',
                price: 17,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Tabla de quesos Asturianos y sus maridajes',
                price: 16,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Zamburiñas a la plancha con su refrito',
                price: 18,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Langostinos a la plancha con sus salsas',
                price: 18,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Pastel de centollo con sus tostas',
                price: 16,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Crema de nécoras y tartar de buey de mar',
                price: 14,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Sopa de marisco al estilo tradicional',
                price: 14,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Salpicón de pulpo y langostinos con vinagreta de pimientos asados',
                price: 20,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Lomos de pixin en su jugo con zamburiñas',
                price: 20,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Rodaballo al albariño con guiso de calamares de potera y arroz basmati',
                price: 20,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Bombón de merluza del pincho relleno de marisco con salsa de oricios',
                price: 20,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Pulpo amariscado',
                price: 25,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            /*{
                name: 'Paletilla a baja temperatura con patatas al ajo y romero',
                price: 28,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },*/
            {
                name: 'Carrilleras de bellota confitadas al oporto con cremoso de patata gratinado',
                price: 18,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Cochinillo a baja temperatura con papas arrugás y sus mojos',
                price: 20,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Milhojas de turrón',
                price: 5.50,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: 'Botella de Vino Rueda (Siete Siete)',
                price: 6,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
            {
                name: '12 Uvas de la Suerte',
                price: 1.50,
                images: [],
                id: 'carta-navidad',
                description: '',
                dateCreated: 1636979991000,
                dateUpdated: 1636979991000,
                active: true,
            },
        ],
        
        sexs: [
          {name: 'Masculino', code: 'm'},
          {name: 'Femenino', code: 'f'},
          {name: 'Otro', code: 'o'},
        ],

        totalMenus: 1,
        menus: [[],[],[],[],[]],
            
        order: {
          items: [],
          payment: {
            method: ''
          },
          delivery: true,
          total: 0,
          subtotal: 0,
          tax: 0,
          shipping: 0,
          discount: 0
        },
        customer: {
          firstName: '',
          lastName: '',
          birthDate: null,
          sex: null,
          phone: '',
          email: '',
          privacy: false,
          terms: false,
          commercial: false,
        }
    }
  },
  validations() {
    return {
      order: {
        deliveryDate: { required },
        delivery: {required}
      },

      customer: {
        firstName: { required },
        lastName: { required },
        birthDate: { },
        sex: {  },
        phone: { required, minLength: minLength(9), maxLength: maxLength(9) },
        email: { required, email },
        privacy: { required },
        terms: { required },
        commercial: { },
        address: {  },
        city: {  },
        zipCode: { minLength: minLength(5), maxLength: maxLength(5) },
      }
    }
  },
  methods: {
    ...mapActions('navidad121',['addOrder']),
    ...mapActions(['closeDetails']),

    setStepSlider(swiper){
        this.stepSlider.value = swiper;
        this.stepSlider.value.allowSlideNext = false;
        this.stepSlider.value.allowSlidePrev = false;
    },

    nextStep(){
        this.stepSlider.value.allowSlideNext = true;
        this.stepSlider.value.slideNext(250, true);
        this.stepSlider.value.allowSlideNext = false;

        this.recalculate();

        setTimeout(() => {
          this.stepSlider.value.update();
        }, 500);

        this.scrollToTop();
    },

    backStep(){
          this.stepSlider.value.allowSlidePrev = true;
          this.stepSlider.value.slidePrev(250, true);
          this.stepSlider.value.allowSlidePrev = false;
          this.stepSlider.value.update();
          this.scrollToTop();
    },

    scrollToTop(){
      const scrollContent = document.querySelector('ion-content.top');
      scrollContent.scrollToTop(500)
    },

    goToSlide(index){
        this.ProductSlider.value.slideTo(index, 1000, true)
    },

    setProductSlider(swiper){
        this.ProductSlider.value = swiper;
    },

    async addMenu(){
      this.order.items.push({
        name: 'Menú de Navidad',
        price: 45,
        quantity: 1,
        images: [],
        id: 'menu-navidad',
        description: 'Menú de Navidad',
        dateCreated: 1636979991000,
        dateUpdated: 1636979991000,
        active: true,
        items: [],
      });

      const loading = await loadingController
        .create({
          message: 'Añadiendo...',
          duration: 1000,
        });

      await loading.present();


      setTimeout(() => {
        this.menuNavidad.forEach((element, i) => {
            this.order.items[this.order.items.length - 1].items[i] = element.options[0];
        });

        this.nextStep();

        loading.dismiss();
      }, 500);
    },

    async deleteMenu(m){
      const loading = await loadingController
        .create({
          message: 'Eliminando...',
          duration: 1000,
        });

      await loading.present();


      setTimeout(() => {
        this.order.items.splice(m,1);
        this.backStep();

        loading.dismiss();
      }, 500);
    },

    plusQtyMenu(i,e){
      const dish = this.menuNavidad[i].options[e];
      this.menus[i].push(dish);
    },

    lessQtyMenu(i,e){
      const existe = this.menus[i].findIndex(x => x.name === this.menuNavidad[i].options[e].name);
      this.menus[i].splice(existe,1);
      console.log(this.menus);
    },

    generateMenus(){
      for (let i = 0; i < this.totalMenus; i++) {
          const options = [];
          this.menus.forEach((tipo, t) => {
            options.push(tipo[0]);

            tipo.splice(t, 1)
          });

          this.order.items.push({
            name: 'Menú de Navidad',
            price: 45,
            quantity: 1,
            images: [],
            id: 'menu-navidad',
            description: 'Menú de Navidad',
            dateCreated: 1636979991000,
            dateUpdated: 1636979991000,
            active: true,
            items: options,
          });
      }
      this.nextStep();
    },

    plusQtyCart(i){
        this.order.items[i].quantity = this.order.items[i].quantity + 1;
        this.recalculate();
    },

    lessQtyCart(i){
        if(this.order.items[i].quantity > 1){
            this.order.items[i].quantity = this.order.items[i].quantity - 1;
        } else {
            this.order.items.splice(i,1);
        }
        this.recalculate();
    },

    closeUser(){
      this.logout();
      this.customer = {
          firstName: '',
          lastName: '',
          birthDate: null,
          sex: null,
          phone: '',
          email: '',
          privacy: false,
          terms: false,
          commercial: false,
        }
    },

    // Otros
    plusQty(i){
        const existe = this.order.items.find(x => x.name === this.cartaNavidad[i].name);

        if(existe){
            existe.quantity = existe.quantity + 1;
        } else {
            const dish = this.cartaNavidad[i];
            dish.quantity = 1;
            this.order.items.push(dish);
        }
        this.recalculate();
    },

    lessQty(i){
        const existe = this.order.items.findIndex(x => x.name === this.cartaNavidad[i].name);

        if(this.order.items[existe] && this.order.items[existe].quantity > 1){
            this.order.items[existe].quantity = this.order.items[existe].quantity - 1
        } else {
            this.order.items.splice(existe,1);
        }
        this.recalculate();
    },

    maxDate(){
      const mDate = moment().subtract(18, 'years');
      return new Date(mDate.toDate().getTime());
    },

    // Totales
    async recalculate(){
        let total = 0;
        let subtotal = 0;
        let tax = 0;
        this.order.discount = 0;
        this.order.shipping = 0;

        // Productos
        this.order.items.forEach(element => {
            subtotal = subtotal + (element.price * element.quantity);
        });

        // Cupones
        if(this.order.coupon){
            // El cupon es de descuento por cantiddad
            if(this.order.coupon.type === 'discount€'){
                this.order.discount = this.order.coupon.discount;
            }
            // El cupon es de descuento por porcentaje
            if(this.order.coupon.type === 'discount%'){
                this.order.discount = (subtotal * this.order.coupon.discount) / 100;
            }
        }

        if(this.order.delivery && this.customer && this.customer.city){
          if(this.customer.city.toLowerCase() === 'gijon' ||  this.customer.city.toLowerCase() === 'gijón'){
            this.order.shipping = 3;
          } else {
            this.order.shipping = 12;
          }
        }

        if(this.order.deliveryDate === 1640908800000){
          this.order.items.forEach(item => {
            if(item.id === 'menu-navidad'){
              const uvas = item.items.findIndex(x => x.name === 'Uvas de la Suerte');
              if(uvas === -1){
                item.items.push({
                    name: 'Uvas de la Suerte',
                });
              }
            }
          });
        } else {
          this.order.items.forEach(item => {
            if(item.id === 'menu-navidad'){
              const uvas = item.items.findIndex(x => x.name === 'Uvas de la Suerte');
              if(uvas !== -1){
                item.items.splice(uvas, 1);
                console.log('eliminado');
              }
            }
          });
        }

        // Total
        total = (subtotal + this.order.shipping) - this.order.discount;
        tax = subtotal * 0.10;

        this.order.subtotal = subtotal;
        this.order.total = total;
        this.order.tax = tax;
    },

    async payment(){
      const loading = await loadingController
        .create({
          message: 'Generando Pedido...',
          duration: 10000,
        });

      await loading.present();

      this.order.dateCreated = new Date().getTime();
      this.order.status = 1;
      this.order.payment.status = 'pending';
      this.order.customer = this.customer;
      this.order.type = "menu-navidad";


      await this.addOrder({'data': this.order}).then(x => {
        loading.dismiss();
        this.closeDetails();
        this.$router.push('/modules/navidad-121/orders');
      });
    },
  },
  mounted() {
    this.stepSlider.value.allowSlidePrev = true;
    this.stepSlider.value.slideTo(0, 0, true);
    this.stepSlider.value.allowSlidePrev = false;

    this.recalculate();
  },
  setup() {
    return { v$: useVuelidate(), chevronBackOutline, addOutline, removeOutline };
  }
});
</script>

<style scoped>
  .header-aside-container{
      max-width: 96rem;
      margin: 0 auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }

  #menuNavidad{
    margin: 0 auto;
  }

  .slider-steps{
      justify-content: inherit;
      text-align: inherit;
      align-items: inherit;
      display: block;
      width: 100%;
      position: relative;
      top: 0;
  }

  .slide-step{
      justify-content: inherit;
      text-align: inherit;
      align-items: inherit;
      display: block;
      width: 100%;
      position: relative;
      top: 0;
  }

  .element-list{
    padding: 1rem 0 !important;
  }

  .element-list ion-list-header{
    padding: 0;
  }

  .element-list ion-item{
      --padding-start: 0px;
      --inner-padding-start: 0px;
      --padding-end: 0px;
      --inner-padding-end: 0px;
      --padding-top: 0px;
      --inner-padding-top: 0px;
      --padding-bottom: 0px;
      --inner-padding-bottom: 0px;
  }

  .element-list .subtitulo ion-label{
      font-weight: bold;
      font-size: 1.25rem;
      text-transform: uppercase;
  }

  .main-swiper {
    width: 100%;
    height: 347px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
  }

  .main-swiper a{
    width: 100%;
    height: auto;
  }

  .main-swiper img{
    width: 100%;
    height: 347px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }

  .horizontal-swiper {
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
    margin-bottom: 1rem;
  }

  .horizontal-swiper a{
    width: 100%;
    height: auto;
  }

  .horizontal-swiper img{
    width: 100%;
    height: 100px;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem;
  }
  
  .menu-navidad{
    padding: 1rem;
    font-style: italic;
  }
  
  .separator{
    border-bottom: 1px solid var(--ion-color-medium); 
  }

  /* Totales */
  .dish, .totals > ion-item, .subitem{
        --padding-start: 0px;
        --inner-padding-start: 0px;
        --padding-end: 0px;
        --inner-padding-end: 0px;
        --padding-top: 0px;
        --inner-padding-top: 0px;
        --padding-bottom: 0px;
        --inner-padding-bottom: 0px;
        height: 36px;
    }

    .dish > ion-lablel, .totals > ion-label, .subitem > ion-label{
        margin: 0;
    }
    
    .price{
        font-weight: normal;
        margin: 0;
        width: 80px;
        text-align: right;
    }

    .quantitys{
        width: 96px;
        margin-left: 0;
    }

    .quantitys ion-label{
        margin: 0 auto;
    }

    .quantitys > ion-label{
        margin: 0 auto;
    }

    .subplatos{
        padding: 0;
    }

    /* NAVIDAD CUSTOM */
    .portada{
        background-size: 100%;
        background-position: top;
        background-repeat: no-repeat;
        padding-bottom: 0;
        min-height: 25vh;
    }

    @media (min-width: 1140px) {
        .portada{
            min-height: 75vh;
        }
    }

    .footer-navidad{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        transform: translateY(25%) scale(1.25);
        width: 100%;
    }


    @media (min-width: 1140px) {
        .footer-navidad{
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            transform: translateY(0%) scale(1.5);
            width: 100%;
            height: 40rem;
            object-fit: cover;
            object-position: center;
        }
    }

    
</style>