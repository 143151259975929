<template>
  <ion-app>
    <Toast position="top-right" group="tr" />
    <ion-router-outlet id="main" :animation="navAnimation" />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, createAnimation } from '@ionic/vue';
import { defineComponent } from 'vue';

import Toast from 'primevue/toast';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Toast,
  },
    methods: {
    navAnimation(ElementRef, opts){
      const enterAnimation = createAnimation()
        .addElement(opts.enteringEl)
        .fromTo('opacity', '0', '1')
        .delay(500)
        .duration(250);

      const exitAnimation = createAnimation()
        .addElement(opts.leavingEl)
        .fromTo('opacity', '1', '0')
        .duration(250);

      const rootAnimation = createAnimation();

      rootAnimation.addAnimation([exitAnimation,enterAnimation]);

      return rootAnimation;
    },
  },
});
</script>

<style>
  #side-menu{
    --side-width: 100%;
    --border: 0;
    margin-top: 4rem;
  }
</style>