<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Editar</h4>
                  <h1>Plato</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <div class="flex">
                    <ion-button @click="exit" shape="round" style="margin-top: 6px" color="dark" fill="outline">
                        <ion-icon slot="start" :icon="arrowBackOutline"></ion-icon>
                        <ion-label>Volver</ion-label>
                    </ion-button>
                    <ion-button @click="save" :disabled="v$.element.$invalid" color="primary" style="margin-top: 6px; margin-left:0.75rem" shape="round">
                        <ion-icon slot="start" :icon="saveOutline"></ion-icon>
                        <ion-label>Guardar</ion-label>
                    </ion-button>
                  </div>
                </ion-col>
                <ion-col size="12" size-lg="6">
                  <ion-buttons class="options-nav">
                    <ion-toggle v-model="element.active" color="success"></ion-toggle>
                    <ion-button @click="confirmDeleteElement($event)" >
                        <ion-icon color="danger" slot="icon-only" :icon="trashOutline"></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                  </ion-buttons>
                </ion-col>
              </ion-row>
          </ion-grid>
        </section>

        <section id="content" class="mb-lg">
          <ion-grid>
            <ion-row>
              <ion-col size="12" size-lg="8">
                <ion-grid>
                  <ion-row class="mb-md">
                    <h5 class="edit-title">Detalles</h5>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.name.$errors.length }">
                        <label for="name">Nombre</label>
                        <InputText class="input" id="name" placeholder="Nombre" type="text" v-model.trim.lazy="v$.element.name.$model" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.name.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.category.$errors.length }">
                        <label for="category">Categoría</label>
                        <Dropdown v-model.lazy="v$.element.category.$model" placeholder="Seleccionar" id="category" class="inputSelect secondary-input" :options="dishesCategories" optionLabel="name" :filter="true" :showClear="true">
                            <template #value="slotProps">
                                <div v-if="slotProps.value">
                                    <div>{{slotProps.value.name}}</div>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div>
                                    <div>{{slotProps.option.name}}</div>
                                </div>
                            </template>
                        </Dropdown>

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.category.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" size-lg="6" class="box-input" :class="{ error: v$.element.price.$errors.length }">
                        <label for="name">Precio</label>
                        <InputNumber id="price" class="inputNumber" v-model.lazy="v$.element.price.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.price.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                    <ion-col size="12" class="box-input" :class="{ error: v$.element.description.$errors.length }">
                        <label for="description">Descripción</label>
                        <Editor v-model.trim.lazy="v$.element.description.$model" placeholder="Descripción" id="description" editorStyle="min-height: 160px; height: auto"/>

                        <!-- error message -->
                        <div class="input-errors" v-for="(error, index) of v$.element.description.$errors" :key="index">
                          <h6 class="error-msg">{{ error.$message }}</h6>
                        </div>
                    </ion-col>
                  </ion-row>

                  <ion-row class="mb-md">
                    <ion-col size="12">
                      <h5 class="edit-title">Imágenes</h5>
                          <FileUpload
                              mode="basic"
                              name="image"
                              :multiple="true"
                              accept="image/*"
                              :maxFileSize="5000000"
                              :customUpload="true"
                              @uploader="handleImageUpload"
                              :auto="true"
                              chooseLabel="Subir Imágenes"
                              class="uploader"
                              ref="uploader"
                          />
                            <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                            <draggable class="imagenes" :list="images" @change="updateImageList">
                              <div
                                class="imagen-container" v-for="image in images" :key="image"
                              >
                                <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                                <ion-buttons class="imagen-remover">
                                    <ion-button @click="handleImageRemove($event, image.objectURL)">
                                    <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                              </div>
                            </draggable>
                      </ion-col>
                  </ion-row>

                  <ion-row class="mb-md">
                    <h5 class="edit-title">Variaciones</h5>
                    <ion-col size="12">
                        <ion-item lines="none">
                            <ion-label><b>Variaciones del Plato</b></ion-label>
                            <ion-buttons slot="end">
                                <ion-button @click="addVariation" >
                                    <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                </ion-button>
                            </ion-buttons>
                        </ion-item>

                        <DataTable :value="element.variations" v-model:expandedRows="expandedRows" @cell-edit-complete="onCellEditVariationComplete" dataKey="key" editMode="cell" class="variations p-datatable-sm edit-datatable" responsiveLayout="scroll">
                          <template #empty>
                              No hay variaciones de este producto
                          </template>
                          
                          <Column :expander="true" style="width: 64px;" />
                          <Column field="name" header="Variación">
                            <template #editor="slotProps">
                                <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                            </template>
                          </Column>
                          <Column alignFrozen="right">
                              <template #body="slotProps"> 
                                <ion-buttons style="justify-content: flex-end">
                                    <ion-button @click="addOption($event, slotProps.data.key)" >
                                        <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                                    </ion-button>
                                    <ion-button @click="removeVariation($event, slotProps.data.key)" >
                                        <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                    </ion-button>
                                </ion-buttons>
                              </template>
                          </Column>
                          <template #expansion="slotProps">
                              <div class="options-subtable">
                                  <DataTable :value="slotProps.data.options" @cell-edit-complete="onCellEditOptionComplete($event, slotProps)" editMode="cell" dataKey="key" class="editable-cells-table" responsiveLayout="scroll">
                                      <Column field="text" header="Opción">
                                        <template #editor="slotProps">
                                            <InputText v-model="slotProps.data[slotProps.column.props.field]" />
                                        </template>
                                      </Column>
                                      <Column field="value" header="Incremento">
                                        <template #editor="slotProps">
                                            <InputNumber id="increase" class="inputNumber" min="0" v-model="slotProps.data[slotProps.column.props.field]" showButtons mode="currency" currency="EUR" />
                                        </template>
                                      </Column>
                                      <Column alignFrozen="right" :frozen="balanceFrozen">
                                        <template #body="slotProps"> 
                                            <ion-buttons style="justify-content: flex-end">
                                                <ion-button  @click="removeOption($event, slotProps.data.key)" >
                                                    <ion-icon slot="icon-only" :icon="removeCircleOutline"></ion-icon>
                                                </ion-button>
                                            </ion-buttons>
                                        </template>
                                      </Column>
                                  </DataTable>
                              </div>
                          </template>
                      </DataTable>
                    </ion-col>
                  </ion-row>

                  <ion-row class="mb-md">
                    <h5 class="edit-title">Alérgenos</h5>
                    <ion-col size="12">
                      <PickList v-model="allergens" dataKey="name" class="allergens" v-model:selection="element.allergens">
                        <template #sourceHeader>
                            Disponibles
                        </template>
                        <template #targetHeader>
                            Seleccionados
                        </template>
                        <template #item="slotProps">
                            <ion-item lines="none" class="allergen-item">
                              <img :src="'assets/img/modules/restaurant/allergens/dark/' + slotProps.item.image" slot="start" :alt="slotProps.item.name"/>
                              <ion-label>{{slotProps.item.name}}</ion-label>
                            </ion-item>
                        </template>
                      </PickList>
                    </ion-col>
                  </ion-row>
                </ion-grid>

                <ion-button @click="showPreview" color="dark" fill="outline" expand="block"  class="hide-xl hide-lg" style="margin-top:0.5rem" shape="round">
                    <ion-label>Previsualizar</ion-label>
                </ion-button>
              </ion-col>
              <ion-col size="12" size-lg="4" class="hide-xs hide-sm hide-md">
                  <DishPreview :data="element"></DishPreview>
              </ion-col>
            </ion-row>
          </ion-grid>
        </section>
      </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonButton, IonButtons, IonItem, modalController, IonToggle } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Editor from 'primevue/editor';
import FileUpload from 'primevue/fileupload';
import ConfirmPopup from 'primevue/confirmpopup';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Image from 'primevue/image';
import PickList from 'primevue/picklist';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import DishPreview from './components/DishPreview.vue';

import { VueDraggableNext } from 'vue-draggable-next'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'EditDish',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    Footer,
    ConfirmPopup,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,
    IonItem,

    InputText,
    FileUpload,
    DataTable,
    Image,
    Column,
    InputNumber,
    Editor,
    Dropdown,
    PickList,
    IonToggle,
    DishPreview,
    draggable: VueDraggableNext,
  },
  data() {
      return {
        element: {
            active: true,
            name: '',
            category: undefined,
            price: 0,
            description: '',
            images: [],
            variations: [],
            allergens: []
        },
        language: 'es',
        images: [],

        dialogImageUrl: '',
        dialogVisible: false,
        languages: [
                {name: 'Español', code: 'es'},
        ],
        lastVariationKey: 0,
        expandedRows: [],

        allergens: [
          [
            {
              image: 'altramuces.svg',
              name: 'Altramuces'
            },
            {
              image: 'apio.svg',
              name: 'Apio'
            },
            {
              image: 'cacahuetes.svg',
              name: 'Cacahuetes'
            },
            {
              image: 'dioxido-de-azufre-y-sulfitos.svg',
              name: 'Dióxido de Azufre y Sulfitos'
            },
            {
              image: 'frutos-de-cascara.svg',
              name: 'Frutos de Cáscara'
            },
            {
              image: 'gluten.svg',
              name: 'Gluten'
            },
            {
              image: 'granos-de-sesamo.svg',
              name: 'Granos de Sésamo'
            },
            {
              image: 'huevos.svg',
              name: 'Huevos'
            },
            {
              image: 'lacteos.svg',
              name: 'Lácteos'
            },
            {
              image: 'moluscos.svg',
              name: 'Moluscos'
            },
            {
              image: 'mostaza.svg',
              name: 'Mostaza'
            },
            {
              image: 'pescado.svg',
              name: 'Pescado'
            },
            {
              image: 'soja.svg',
              name: 'Soja'
            }
          ],
          []
        ]
      };
    },
    validations() {
        return {
            element: {
                name: {required},
                category: {required},
                price: {required},
                description: {},
                variations: {},
                allergens: {}
            },
        }
    },
    computed: {
        ...mapState('restaurant', {
            dish: state => state.dish,
            dishesCategories: state => state.dishesCategories,
        }),

        ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('restaurant',['getDish','uploadImage', 'addDish', 'updateDish', 'deleteDish', 'getDishesCategories']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),

      // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/restaurant/dishes', img: file});
          this.element.images.push({
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Fdishes%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Fdishes%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Fdishes%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Frestaurant%2Fdishes%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          });
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        const pos = this.element.images.findIndex(x => x[512] === url);
        this.element.images.splice(pos, 1);
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },

      updateImageList(event){
        const temp = this.element.images[event.moved.oldIndex];
        this.element.images.splice(event.moved.oldIndex, 1);
        this.element.images.splice(event.moved.newIndex, 0, temp);
      },

      // Variations
      addVariation(){
        if(!this.element.variations){
          this.element.variations = [];
        }
        this.element.variations.push({
            "key": (this.lastVariationKey + 1),
            "name":"Nueva Variación",
            "lastOptionKey": 0,
            "options": [
                {
                    "key": (this.lastVariationKey + 1) + "-0",
                    "text": "Nueva Opción",
                    "value": 0,
                },
            ]
        });

        this.lastVariationKey = this.lastVariationKey + 1;
      },
      removeVariation(event, key){
        const pos = this.element.variations.findIndex(x => x.key === key);
        this.element.variations.splice(pos, 1);

        this.lastVariationKey = this.lastVariationKey - 1;
      },
      addOption(event, key){
        const pos = this.element.variations.findIndex(x => x.key === key);
        if(!this.element.variations[pos].options){
          this.element.variations[pos].options = [];
        }

        this.element.variations[pos].options.push({
            "key": key + "-" + (this.element.variations[pos].lastOptionKey + 1),
            "text": "Nueva Opción",
            "increase": 0,
            "stock": 0,
            "refCode": ''
        });

        this.element.variations[pos].lastOptionKey = this.element.variations[pos].lastOptionKey + 1;
      },
      removeOption(event, key){
        const variationKey = key.split('-')[0];
        const posVariation = this.element.variations.findIndex(x => x.key === parseInt(variationKey));
        const posOption = this.element.variations[posVariation].options.findIndex(x => x.key === key);
        this.element.variations[posVariation].options.splice(posOption, 1);
      },

      onCellEditVariationComplete(event){
        this.element.variations[event.index][event.field] = event.newValue;
      },

      onCellEditOptionComplete(event, slotProps){
        this.element.variations[slotProps.index].options[event.index][event.field] = event.newValue;
      },


      // Actions
      save(){
        if(this.$route.params.id === 'new'){
          this.element.dateCreated = new Date().getTime();
          this.element.dateUpdated = new Date().getTime();
          this.element.allergens = this.allergens[1];

          this.addDish(this.element);
          this.exit();
        } else {
          this.element.dateUpdated = new Date().getTime();
          this.element.allergens = this.allergens[1];
          this.updateDish({data: this.element, id: this.$route.params.id});
          this.exit();
        }
      },
      confirmDeleteElement(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.deleteDish(this.$route.params.id);
                    this.exit();
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      exit(){
          this.images = [];
          this.$router.go(-1);
      },
      
      async showPreview(){
        const modal = await modalController
            .create({
                component: DishPreview,
                cssClass: 'auto-height',
                componentProps: {
                    element: this.element
                },
            })
        return modal.present();
      }
    },
    beforeMount() {
      if(this.$route.params.id === 'new'){
        this.lastVariationKey = 0;
      } else {
        this.getDish(this.$route.params.id).then(() => {
            if(this.dish){
                this.element = this.dish;

                // Load Images
                if(this.element && !this.images.length){
                  this.element.images.forEach(img => {
                    this.images.push({objectURL: img['512'], status: 'upload'});
                  });
                }

                if(this.element.variations){
                  this.lastVariationKey = this.element.variations.length;
                } else{
                  this.lastVariationKey = 0;
                }
          }
        });
      }

      this.getDishesCategories();
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline};
  }
});
</script>

<style scoped>

  .edit-title{
    padding-bottom: 1rem;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 212px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .allergen-item img{
    width: 48px;
    height: auto;
  }

</style>