import { getFirestoreClient } from '@/firebaseConfig';

import moment from 'moment';

import algoliasearch from 'algoliasearch/lite';
import { alertController } from '@ionic/vue';
import notificationSound from '../../public/assets/sounds/new-order.mp3';
import router from '@/router'

export const moduleShop = {
    namespaced: true,
    state: {
        // Shop
        products: [],
        product: {},
        productsCategories: [],
        productCategory: {},
        
        orders: [],
        storedOrders: [],
        abandonedOrders: [],
        order: {},

        openingHours: {},
        shipping: {},
    },
    mutations: { 
        // Shop
        setProducts(state, payload){
            state.products = payload
        },
        setProduct(state, payload){
            state.product =  payload
        },
        setProductsCategories(state, payload){
            state.productsCategories = payload
        },
        setProductCategory(state, payload){
            state.productCategory = payload
        },

        setOrders(state, payload){
            state.orders = payload
        },
        setStoredOrders(state, payload){
            state.storedOrders = payload
        },
        setAbandonedOrders(state, payload){
            state.abandonedOrders = payload
        },
        setOrder(state, payload){
            state.order = payload
        },

        setOpeningHours(state, payload){
            state.openingHours = payload
        },
        setShipping(state, payload){
            state.shipping = payload
        },
    },
    actions: {
        // Shop

        // PRODUCTS
        // Products
        async getProducts({commit}){
          if(!this.state.shop.products.length){
            const searchClient = algoliasearch(
              this.state.evolbeUser.business[0].algoliaConfig.id,
              this.state.evolbeUser.business[0].algoliaConfig.key
            );
  
            let data = [];
  
            const index = searchClient.initIndex("shop_products");
            await index
                .search('')
                .then(async (res) => {
                    data = res.hits;
  
                    if(res.nbPages > 1){
                      for (let page = 1; page <= res.nbPages; page++) {
                        await index.search('', {page: page})
                          .then((res2) => {
                              data.push(...res2.hits);
                          })
                          .catch(err => {
                              console.log(err);
                          });
                      }
                    }
  
                })
                .catch(err => {
                    console.log(err);
                });
  
              commit('setProducts', data);
          }
        },

        async getProduct({commit}, id){
            if(!this.state.shop.products.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/shop/products').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setProduct', data);
            } else {
                const data = this.state.shop.products.find(x => x.objectID === id)
                commit('setProduct', data);
            }
        },
            async addProduct({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/products').add(data);
            },
            async updateProduct({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/products').doc(id).update(data);
            },
            async deleteProduct({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/products').doc(id).delete();
            },


        // Dishes Categories
        async getProductsCategories({commit}){
            if(!this.state.shop.productsCategories.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const res = await dbClient.collection('/evolbe-modules/shop/categories').orderBy('order', 'asc').get();

                data = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                data.push(e);
                })
                commit('setProductsCategories', data);
            }
        },
        async getProductCategory({commit}, id){
            if(!this.state.shop.productsCategories.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/shop/categories').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setProductCategory', data);
            } else {
                const data = this.state.shop.productsCategories.find(x => x.objectID === id)
                commit('setProductCategory', data);
            }
        },
            async addProductCategory({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/categories').add(data);
            },
            async updateProductCategory({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/categories').doc(id).update(data);
            },
            async deleteProductCategory({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/categories').doc(id).delete();
            },

            async updateAllProductsCategories({commit}, {data, id}){
              const dbClient = getFirestoreClient(this.state.evolbeUser);
              
              const datos = [];
              const res =await dbClient.collection('/evolbe-modules/shop/products').where('category.objectID', '==', id).get();
              res.forEach(item => {
                  const e = {
                      objectID: item.id,
                      ...item.data()
                  }
                  datos.push(e);
              });
  
              datos.forEach(async element => {
                  const newElement = element;
                  newElement.category = data;
                  await dbClient.collection('/evolbe-modules/shop/products').doc(element.objectID).update(newElement);
              });
          },

        // PEDIDOS
        // Orders
        async getOrders({commit}){
            if(!this.state.shop.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/shop/orders').orderBy('dateCreated', 'desc').onSnapshot(async res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setOrders', data);
                });
            }
        },
        async getOrder({commit}, id){
            if(!this.state.shop.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/shop/orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.shop.orders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/orders').doc(id).set(data);
            },
            async updateOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/orders').doc(id).update(data);
            },
            async deleteOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/orders').doc(id).delete();
            },

        // Stored orders
        async getStoredOrders({commit}){
            if(!this.state.shop.storedOrders.length){
                const searchClient = algoliasearch(
                  this.state.evolbeUser.business[0].algoliaConfig.id,
                  this.state.evolbeUser.business[0].algoliaConfig.key
                );
      
                let data = [];
      
                const index = searchClient.initIndex("stored_orders");
                await index
                    .search('')
                    .then(async (res) => {
                        data = res.hits;
      
                        if(res.nbPages > 1){
                          for (let page = 1; page <= res.nbPages; page++) {
                            await index.search('', {page: page})
                              .then((res2) => {
                                  data.push(...res2.hits);
                              })
                              .catch(err => {
                                  console.log(err);
                              });
                          }
                        }
      
                    })
                    .catch(err => {
                        console.log(err);
                    });
      
                  commit('setStoredOrders', data);
              }
        },
        async getStoredOrder({commit}, id){
            if(!this.state.shop.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/shop/stored_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.shop.storedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/stored_orders').doc(id).set(data);
            },
            async updateStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/stored_orders').doc(id).update(data);
            },
            async deleteStoredOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/stored_orders').doc(id).delete();
            },

        // Abandoned orders
        async getAbandonedOrders({commit}){
            if(!this.state.shop.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/shop/abandoned_orders').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setAbandonedOrders', data);
                });
            }
        },
        async getAbandonedOrder({commit}, id){
            if(!this.state.shop.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/shop/abandoned_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.shop.abandonedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addAbandonedOrder({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/abandoned_orders').add(data);
            },
            async updateAbandonedOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/abandoned_orders').doc(id).update(data);
            },
            async deleteAbandonedOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/shop/abandoned_orders').doc(id).delete();
            },


     







        // DATA TRANSFORM
          async incrementos({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            let data = [];
            let data2 = [];

            const res = await dbClient.collection('/evolbe-modules/shop/products').get();
              data = [];
              res.forEach(item => {
                const e = {
                  id: item.id,
                  ...item.data()
                }

                data.push(e);
              })

              const res2 = await dbClient.collection('/shop-module/catalogo/productos').get();
                data2 = [];
                res2.forEach(item => {
                  const e2 = {
                    id: item.id,
                    ...item.data()
                  }
    
                  const ele = data.find(x => x.refCode === e2.sku);
                  if(ele){
                      delete ele._highlightResult;
                      const vars = [];
      
                      if(ele.variations){
                        if(e2.variations){
                          e2.variations.forEach((variation, i) => {
                            const varOptions = [];
                            variation.options.forEach((option, e) => {
                              varOptions.push({
                                price: parseFloat(option.price.toFixed(2)),
                                key: i + '-' + e,
                                option: option.name,
                                refCode: option.sku,
                                stock: option.stock
                              });
                            });
          
                            vars.push({
                              key: i,
                              lastOptionKey: varOptions.length - 1,
                              variation: variation.name,
                              options: varOptions
                            });
                          });
                        }
                      }


                      const e = {
                        variations: vars,
                        objectID: ele.id
                      }
      
                      data2.push(e);
                    }
                })

                data2.forEach(e => {
                  console.log(e.variations);
                  //dbClient.collection('/evolbe-modules/shop/products').doc(e.objectID).update(e);
                });
        },
        
          // Convert React to Vue
          async convertProducts({commit}){
            if(!this.state.products.length){
              const dbClient = getFirestoreClient(this.state.evolbeUser);

              await dbClient.collection('/shop-module/catalogo/categorias').onSnapshot(async resp => {
                const categories = [];

                resp.forEach(item => {
                  const e = {
                    id: item.id,
                    ...item.data()
                  }
                  categories.push(e);
                });

                await dbClient.collection('/evolbe-modules/shop/categories').orderBy('order', 'asc').onSnapshot(async res2 => {
                  const newCategories = [];
                  res2.forEach(item => {
                    const e = {
                      id: item.id,
                      ...item.data()
                    }
                    newCategories.push(e);
                  });

                  await dbClient.collection('/shop-module/catalogo/productos').onSnapshot(res => {
                    const data = [];

                    res.forEach(ele => {
                      const e = {
                        id: ele.id,
                        ...ele.data()
                      }
                      data.push(e);
                    });

                    for (let index = 0; index < 10000; index++) {                   
                      const item = data[index];
                      const vars = [];
                      const espf = [];
                      let cat = undefined;
                      let subcat = undefined;
      
                      if(item.variations){
                        item.variations.forEach((variation, i) => {
                          const varOptions = [];
                          variation.options.forEach((option, e) => {
                            varOptions.push({
                              increase: 0,
                              key: i + '-' + e,
                              option: option.name,
                              refCode: option.sku,
                              stock: option.stock
                            });
                          });
        
                          vars.push({
                            key: i + 1,
                            lastOptionKey: i,
                            variation: variation.name,
                            options: varOptions
                          });
                        });
                      }
      
                      if(item.especifications){
                        item.especifications.forEach(specification => {                      
                          espf.push({
                            specification: specification.key,
                            value: specification.value
                          });
                        });
                      }
      
                      if(item.categories.length){
                        const catOld = categories.find(x => x.id === item.categories[0]);
                        if(catOld){
                          cat = newCategories.find(x => x.name === catOld.name);

                          if(catOld.subcategories){
                            const subcatOld = catOld.subcategories.find(x => x.id === item.categories[1]);
                            if(subcatOld){
                              subcat = newCategories.find(x => x.name === subcatOld.name);
                            }
                          }
                        }  
                      }
      
                      const e = {
                        category: cat,
                        subcategory: subcat,
                        active: item.active,
                        dateCreated: item.date,
                        dateUpdated: new Date().getTime(),
                        description: item.description || '',
                        offer: {},
                        globalRating: 0,
                        images: item.images,
                        keywords: [],
                        name: item.name,
                        price: item.price,
                        cost: item.cost || null,
                        refCode: item.sku,
                        specifications: espf,
                        stock: 100,
                        variations: vars,
                        qr: item.qr,
                        barcode: item.barcode
                      }
      
                      console.log(e);
                      //dbClient.collection('/evolbe-modules/shop/products').add(e);
                    }
                  })
                });
              });
            }
          },
          // Delete Duplicates
          async deleteDuplicateProducts({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            const res = await dbClient.collection('/evolbe-modules/shop/products').get();
              const data = [];

              res.forEach(ele => {
                const e = {
                  id: ele.id,
                  ...ele.data()
                }
                data.push(e);
              });
              /*
              const set = new Set(data);

              const duplicates = data.filter(item => {
                  if (set.has(item)) {
                      console.log(item);
                  } else {
                      return item;
                  }
              });*/

              data.forEach(async element => {
                const num2 = element.images[0];
                const num1 = element.images[1];
                element.images[0] = num1;
                element.images[1] = num2;
                //await dbClient.collection('/evolbe-modules/shop/products').doc(element.id).update(element);
                console.log(element.id);
              });
          },

          // Inport JSON
          async importProductsJSON({commit}){
            const response = await fetch('http://localhost:8100/assets/json/csvjson.json');
            const data = await response.json();
            const dbClient = getFirestoreClient(this.state.evolbeUser);
            await dbClient.collection('/evolbe-modules/shop/categories').orderBy('order', 'asc').onSnapshot(async res2 => {
              const newCategories = [];
              res2.forEach(item => {
                const e = {
                  id: item.id,
                  ...item.data()
                }
                newCategories.push(e);
              });

              data.forEach(item => {
                const vars = [];
                const espf = [];
                let cat = undefined;
                let subcat = undefined;

                const oldCat = item.FAMILIA;

                switch (oldCat) {

                  case 'Accesorios portátiles':
                    cat = newCategories.find(x => x.id === 'zj3na43aQIyTrRVBbQ0a');
                    subcat = newCategories.find(x => x.id === 'kDP2QsOgtXwDGhL4YspR');
                  break;

                  case 'Accesorios telefonía':
                    cat = newCategories.find(x => x.id === 'xuMBzN0ikWZdkOLXSIMh');
                    subcat = newCategories.find(x => x.id === 'Cs06Q2qZbDxVWzSKd8KW');
                  break;

                  case 'Adaptadores bahía':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'MSu3iYmk6qF7dTFjdz1K');
                  break;

                  case 'Adaptadores y Convertidores':
                    cat = newCategories.find(x => x.id === '3yPhJjO9IcD0Xob6ozcV');
                  break;

                  case 'Almacenamiento NAS':
                    cat = newCategories.find(x => x.id === 'N7quPNsD0OYhMVKi37cn');
                    subcat = newCategories.find(x => x.id === 'Q8Taclk2C8g4nppQKhbN');
                  break;

                  case 'Almacenamiento portátil':
                    cat = newCategories.find(x => x.id === 'N7quPNsD0OYhMVKi37cn');
                    subcat = newCategories.find(x => x.id === 'IWlSllPVmH5PTHiuduA2');
                  break;

                  case 'Altavoces':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'aINJnfQXNW4QJ6QqwVAC');
                  break;

                  case 'Auriculares':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'UlMtSt48zobaa3yaanwD');
                  break;

                  case 'Cables y Conectores':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'IeYRhLqdLrMcoJMDJqoJ');
                  break;

                  case 'Cajas y fuentes':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'CShhdyqjD9x7EDoih21K');
                  break;

                  case 'Consumibles':
                    cat = newCategories.find(x => x.id === 'QX4ozJYuynvFn5qQr6Tp');
                    subcat = newCategories.find(x => x.id === 'kuf0Wtkh9CQKLLqGOdhY');
                  break;

                  case 'Bicicletas y accesorios':
                    cat = newCategories.find(x => x.id === '2mow9sBAa06jhdoZUweM');
                    subcat = newCategories.find(x => x.id === 'dcC8Cs9wMBapaHYKyMwf');
                  break;

                  case 'Discos Duros':
                    cat = newCategories.find(x => x.id === 'N7quPNsD0OYhMVKi37cn');
                    subcat = newCategories.find(x => x.id === 'IWlSllPVmH5PTHiuduA2');
                  break;

                  case 'Discos duros internos':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'Q63tLPe5T1S2kLaxfImD');
                  break;

                  case 'E-books':
                    cat = newCategories.find(x => x.id === 'xuMBzN0ikWZdkOLXSIMh');
                    subcat = newCategories.find(x => x.id === '3gtcgvHrLbtb04dTfBgn');
                  break;

                  case 'Electricidad':
                    cat = newCategories.find(x => x.id === 'N7quPNsD0OYhMVKi37cn');
                    subcat = newCategories.find(x => x.id === 'GEoFgseOhJnNSRDk1q4z');
                  break;

                  case 'Escáneres':
                    cat = newCategories.find(x => x.id === 'QX4ozJYuynvFn5qQr6Tp');
                    subcat = newCategories.find(x => x.id === 'dgwpvo848BSLzVHGkHHO');
                  break;

                  case 'Gaming':
                    cat = newCategories.find(x => x.id === 'aN5naAjbxobWrw0Zh44b');
                    subcat = newCategories.find(x => x.id === 'J1c6pE9dOZDKuWERCmhR');
                  break;

                  case 'Iluminación':
                    cat = newCategories.find(x => x.id === 'aN5naAjbxobWrw0Zh44b');
                    subcat = newCategories.find(x => x.id === 'C8DyAoyQChINZvl9DGeS');
                  break;

                  case 'Memoria RAM':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === '0fjODOFBEYGIvLVgoigu');
                  break;

                  case 'Micrófonos':
                    cat = newCategories.find(x => x.id === 'rbA5qF7dRgvhXcvmA5Ta');
                    subcat = newCategories.find(x => x.id === 'RPpXjytBJMpmJVAkrsjc');
                  break;

                  case 'Mobiliario para el hogar':
                    cat = newCategories.find(x => x.id === 'aN5naAjbxobWrw0Zh44b');
                    subcat = newCategories.find(x => x.id === 'kCYbHRtplBVIFkBQ9uX8');
                  break;

                  case 'Patinetes y scooters':
                    cat = newCategories.find(x => x.id === '2mow9sBAa06jhdoZUweM');
                    subcat = newCategories.find(x => x.id === 'OOeVr36gQWvSL05W5fh5');
                  break;

                  case 'PCs Sobremesa':
                    cat = newCategories.find(x => x.id === 'KzXTdHaIHeKRhRBzJKU1');
                  break;

                  case 'Periféricos':
                    cat = newCategories.find(x => x.id === 'N7quPNsD0OYhMVKi37cn');
                  break;

                  case 'Placas base':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'C84zeOigTHUNYwFzRVyz');
                  break;

                  case 'Portátiles':
                    cat = newCategories.find(x => x.id === 'zj3na43aQIyTrRVBbQ0a');
                  break;

                  case 'Procesadores':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 't76iVuLljj56qiN1105k');
                  break;

                  case 'Proyección':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'uz6EGK32X2U7lqW19pmg');
                  break;

                  case 'Refrigeración':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === '2ufNIpA982xwqqvHoqSB');
                  break;

                  case 'Relojes Inteligentes':
                    cat = newCategories.find(x => x.id === 'xuMBzN0ikWZdkOLXSIMh');
                    subcat = newCategories.find(x => x.id === 'E3yqW02Kprg9Xakx8Maq');
                  break;

                  case 'Repetidores y extensores':
                    cat = newCategories.find(x => x.id === '3yPhJjO9IcD0Xob6ozcV');
                  break;

                  case 'Routers y Modems':
                    cat = newCategories.find(x => x.id === '3yPhJjO9IcD0Xob6ozcV');
                  break;

                  case 'Sistemas Operativos':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'jIjmsPvEOGPrsIssmIuJ');
                  break;

                  case 'Switches y Transceptores':
                    cat = newCategories.find(x => x.id === '3yPhJjO9IcD0Xob6ozcV');
                  break;

                  case 'Sonido y Home Cinema':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'ufOgF2HUcur4BmrXEs8E');
                  break;

                  case 'Tablets':
                    cat = newCategories.find(x => x.id === 'xuMBzN0ikWZdkOLXSIMh');
                    subcat = newCategories.find(x => x.id === 'aVn4XxXzw0nWFQoUrc69');
                  break;

                  case 'Tarjetas':
                    cat = newCategories.find(x => x.id === 'MCueHUQ8fpZwp7kw5FLG');
                    subcat = newCategories.find(x => x.id === 'wkIkhPvOpRSfrPeBYO0r');
                  break;

                  case 'Telefonía':
                    cat = newCategories.find(x => x.id === 'xuMBzN0ikWZdkOLXSIMh');
                    subcat = newCategories.find(x => x.id === 'y95EsHO5NyRajMIIf7ts');
                  break;

                  case 'TV':
                    cat = newCategories.find(x => x.id === 'Vvej8dfhVsgHW0E7xVhV');
                    subcat = newCategories.find(x => x.id === 'NsfZDYL2zifvgnv5Pym6');
                  break;

                  case 'Vídeo':
                    cat = newCategories.find(x => x.id === 'rbA5qF7dRgvhXcvmA5Ta');
                    subcat = newCategories.find(x => x.id === 'dxQQJ9xJNRTxGKq9uiyh');
                  break;

                  case 'Videojuegos, Consolas y Accesorios':
                    cat = newCategories.find(x => x.id === '5x6tQyBCgQS7bPlMC02m');
                  break;

                  case 'Wifi':
                    cat = newCategories.find(x => x.id === '3yPhJjO9IcD0Xob6ozcV');
                  break;
                }

                const e = {
                  category: cat,
                  subcategory: subcat,
                  active: true,
                  dateCreated: new Date().getTime(),
                  dateUpdated: new Date().getTime(),
                  description: item.DESCEXTRTF || '',
                  offer: {},
                  globalRating: 0,
                  images: [{
                    "256": item.ThumbPic,
                    "512": item.LowPic,
                    "1024": item.HighPic,
                  }],
                  keywords: [item.SUBCATEGORIADEPRODUCTO],
                  name: item.DENOMINA,
                  price: parseFloat((((item.COMPRA + item.PRECIOCANO) * 1.30) * 1.21).toFixed(2)),
                  cost: item.COMPRA,
                  canon: item.PRECIOCANO,
                  refCode: item.ARTICULO,
                  specifications: [
                    {
                      specification: 'Marca',
                      value: item.MARCA
                    },
                    {
                      specification: 'Peso',
                      value: item.PESO + ' kg'
                    },
                    {
                      specification: 'P/N',
                      value: item.CODIGO_FABRICANTE
                    },
                  ],
                  stock: item.STOCK,
                  variations: [],
                  qr: undefined,
                  barcode: item.EAN,
                }
    
                console.log(e);
                //dbClient.collection('/evolbe-modules/shop/products').add(e);
              });
            });
          },

          // Actualizar BD
          async actualizarBD({commit}){          
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            const data = [];
            const res = await dbClient.collection('/evolbe-modules/shop/products').get();
            res.forEach(ele => {
              const e = {
                id: ele.id,
                ...ele.data()
              }
              data.push(e);
            });

            console.log(data);


            for (let index = 0; index < 29000; index++) {
              const item = data[index];

              if(item.price === 0){
                console.log(item);
                await dbClient.collection('/evolbe-modules/shop/products').doc(item.id).delete();
              }

            }
          },

          async copyOrders({commit}){
            if(!this.state.orders.length){
              const dbClient = getFirestoreClient(this.state.evolbeUser);
    
              await dbClient.collection('/shop-module/orders/purchases').onSnapshot(res => {
                res.forEach(item => {
                  const e = {
                    id: item.id,
                    ...item.data()
                  }
                  
                  dbClient.collection('/evolbe-modules/shop/orders').add(e);
                })
    
              })
            }
          },



      csvJSON({ commit }, csv){
        const decoder = new TextDecoder('iso-8859-1');
        const text = decoder.decode(csv);

        const lines=text.split("\n");
      
        const result = [];
      
        const headers=lines[0].split(";");
      
        for(let i=1;i<lines.length;i++){
      
          const obj = {};
          const currentline=lines[i].split(";");
      
          for(let j=0;j<headers.length;j++){
            
            if(currentline[j]){
              if(!isNaN(currentline[j].replaceAll('"','')) && !isNaN(parseFloat(currentline[j].replaceAll('"','')))){
                obj[headers[j].replaceAll('"','')] = currentline[j].replaceAll('"','') * 1;
              } else {
                obj[headers[j].replaceAll('"','')] = currentline[j].replaceAll('"','');
              }
            }
          }
      
          result.push(obj);
      
        }
        
        //return result; //JavaScript object
        return result; //JSON
      },


      async updateProdcuts({ commit, dispatch }){
        const dbClient = getFirestoreClient(this.state.evolbeUser);

        const database = [];
        const res = await dbClient.collection("/evolbe-modules/shop/products").get();
    
        res.forEach((item) => {
          const e = {
            objectID: item.id,
            ...item.data(),
          };
          database.push(e);
        });

        console.log('database:',database);
    
        try {
              const myHeaders = new Headers();
              myHeaders.append('Content-Type','text/csv; charset=UTF-8');
              const response = await fetch('https://ociostock.gesio.be/dyndata/exportaciones/csvzip/catalog_1_50_54_2_40836fd3ce5ea622a4d34a8aa6c8cda3_csv_plain.csv', myHeaders);
              const csv = await response.arrayBuffer();

              const elements = await dispatch('csvJSON', csv);
              console.log(elements.length);

              for (let i = 0; i < elements.length; i++) {
                setTimeout(async () => {
                  const data = elements[i];
                  
                  if(data.referencia !== undefined){      
                    // Images
                    const images = [];
                    if(data.csv_imagenes){
                      const allImages = data.csv_imagenes.split("|");
                      allImages.forEach((img) => {
                        images.push({
                          "256": img,
                          "512": img,
                          "1024": img,
                          "2048": img,
                        });
                      });
                    }
                        
                    const newData = {
                      active: true,
                      barcode: data.ean,
                      qrCode: "",
                      refCode: data.referencia,
    
                      name: data.nombre,
                      category: {},
                      subcategory: {},
                      description: data.descripcion,
                      keywords: [data.categoria_principal],
    
                      canon: 0,
                      price: data.precio_bruto,
                      cost: data.precio_distribuidores,
                      globalRating: 4,
                      stock: data.stock_disponible,
    
                      specifications: [
                        {
                          text: "Marca",
                          value: data.marca,
                        },
                      ],
    
                      variations: [],
                      images,
    
                      dateCreated: new Date(data.fecha_alta).getTime(),
                      dateUpdated: new Date(data.fecha_ultima_modificacion).getTime(),
                    };
    
                    console.log(newData);
    
                    const existe = database.find((x) => x.refCode === newData.refCode);
    
                    if (existe) {
                      await dbClient.collection("/evolbe-modules/shop/products").doc(existe.objectID).update(newData);
                    } else {
                      await dbClient.collection("/evolbe-modules/shop/products").add(newData);
                    }

                    console.log(newData);
                  }
                }, i * 500);
              }
        } catch (err) {
          console.log("Error" + err);
        }
      },

      async recategorize(){
        const dbClient = getFirestoreClient(this.state.evolbeUser);

        const res = await dbClient.collection('/evolbe-modules/shop/products').get(); // .where('keywords', 'array-contains-any', ['VIDEOJUEGO|ZELDA'])
          const data = [];

            res.forEach(ele => {
              const e = {
                objectID: ele.id,
                ...ele.data()
              }
              data.push(e);
            });

            console.log('productos',data);

        const res2 = await dbClient.collection('/evolbe-modules/shop/categories').get();
            const categories = [];
  
              res2.forEach(ele => {
                const e = {
                  objectID: ele.id,
                  ...ele.data()
                }
                categories.push(e);
              });

              console.log('categorias',categories);

            data.forEach(async (item, i) => {
              if(item.name && item.name.includes('Figuras POP')){
                setTimeout(async () => {
                  const newItem = JSON.parse(JSON.stringify(item));
                  newItem.offer = {};
  
                  const categoria = categories.find(x => x.objectID === 'EZ2VkZD5b9ikDNYFFIRc');
                  const subcategoria = categories.find(x => x.objectID === '112WAjNfmoz5ZxfblvjI');

                  newItem.category = categoria;
                  newItem.subcategory = {};
                  
                    if(Object.keys(newItem.category).length === 0){
                      newItem.category = categoria;
                    }
  
                    /*if(Object.keys(newItem.subcategory).length === 0){
                      newItem.subcategory = subcategoria;
                    }*/

                    await dbClient.collection('/evolbe-modules/shop/products').doc(item.objectID).update(newItem);
                    console.log(newItem);

                }, i * 1);
              }
            });
      },

      // Eliminar los que no tienen fotos
      async purgarProductos(){
        const dbClient = getFirestoreClient(this.state.evolbeUser);

        const res = await dbClient.collection('/evolbe-modules/shop/products').get(); // .where('keywords', 'array-contains-any', ['VIDEOJUEGO|ZELDA'])
          const data = [];

            res.forEach(ele => {
              const e = {
                objectID: ele.id,
                ...ele.data()
              }
              data.push(e);
            });

            let c = 0;
            data.forEach(async (item, i) => {
              if(Object.keys(item.category).length === 0){
                c++;
                console.log(c);
                console.log(item);
                await dbClient.collection('/evolbe-modules/shop/products').doc(item.objectID).delete();
              }
            });
      },

      async makeCS(){
        const dbClient = getFirestoreClient(this.state.evolbeUser);

        const res = await dbClient.collection('/evolbe-modules/shop/products').get(); // .where('keywords', 'array-contains-any', ['VIDEOJUEGO|ZELDA'])
          const data = [];

            res.forEach(ele => {
              const e = {
                objectID: ele.id,
                ...ele.data()
              }
              data.push(e);
            });

            data.forEach(async item => {
              console.log(item)
              await dbClient.collection("/evolbe-modules/shop/products_cs2").doc(item.objectID).set(item);
            });
      },
      

      async updateShop(){
        const dbClient = getFirestoreClient(this.state.evolbeUser);

        const res = await dbClient.collection('/evolbe-modules/shop/products_cs2').get(); // .where('keywords', 'array-contains-any', ['VIDEOJUEGO|ZELDA'])
          const data = [];

            res.forEach(ele => {
              const e = {
                objectID: ele.id,
                ...ele.data()
              }
              data.push(e);
            });

            console.log('productos',data);

            data.forEach(async (item, i) => {
              setTimeout(async () => {
                    // Images
                    const newImages = [];
                    if(item.images){
                      item.images.forEach(img => {
                        newImages.push({
                          256: img,
                          512: img,
                          1024: img,
                          2048: img,
                        })
                      });
                    }

                    /*if(item.variations && item.variations.length){
                      item.variations.forEach(variation => {
                        variation.name = variation.variation;
                        delete variation.variation;

                        variation.options.forEach(option => {
                          option.text = option.option;
                          delete option.option;
                        });
                      });
                    }*/

                    const newItem = {
                      active: item.active,
                      barcode: item.barcode,
                      category: item.category,
                      subcategory: item.subcategory,
                      globalRating: 4,
                      ratings: [],
                      cost: item.cost,
                      price: item.price,
                      name: item.name,
                      description: item.description,
                      images: item.images,
                      refCode: item.refCode,
                      stock: item.stock,
                      keywords: item.keywords,
                      qr: item.qr,
                      specifications: [],
                      variations: item.variations,
                      dateCreated: item.dateCreated,
                      offer: {},
                      dateUpdated: new Date().getTime(),
                    };
                    

                    await dbClient.collection("/evolbe-modules/shop/products").doc(item.objectID).set(newItem);
                    console.log(newItem);            
              }, i * 1);
            });
      },
    },
}
