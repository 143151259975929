import { getFirestoreClient } from '@/firebaseConfig';

import moment from 'moment';

import algoliasearch from 'algoliasearch/lite';
import { alertController } from '@ionic/vue';
import notificationSound from '../../public/assets/sounds/new-order.mp3';
import router from '@/router'
import { options } from 'preact';

export const moduleRestaurant = {
    namespaced: true,
    state: {
        // Restaurant
        todaysMenus: [],
        todaysMenu: {},
        dishes: [],
        dish: {},
        dishesCategories: [],
        dishCategory: {},
        promotions: [],
        promotion: {},

        orders: [],
        storedOrders: [],
        abandonedOrders: [],
        order: {},

        openingHours: {},
        shipping: {},
    },
    mutations: { 
        // Restaurant
        setTodaysMenus(state, payload){
            state.todaysMenus = payload
        },
        setTodaysMenu(state, payload){
            state.todaysMenu = payload
        },
        setDishes(state, payload){
            state.dishes = payload
        },
        setDish(state, payload){
            state.dish =  payload
        },
        setDishesCategories(state, payload){
            state.dishesCategories = payload
        },
        setDishCategory(state, payload){
            state.dishCategory = payload
        },
        setPromotions(state, payload){
            state.promotions = payload
        },
        setPromotion(state, payload){
            state.promotion = payload
        },

        setOrders(state, payload){
            state.orders = payload
        },
        setStoredOrders(state, payload){
            state.storedOrders = payload
        },
        setAbandonedOrders(state, payload){
            state.abandonedOrders = payload
        },
        setOrder(state, payload){
            state.order = payload
        },

        setOpeningHours(state, payload){
            state.openingHours = payload
        },
        setShipping(state, payload){
            state.shipping = payload
        },
    },
    actions: {
        // Restaurant


        // CARTA
        // Todays Menus
        async getTodaysMenus({commit}){
            if(!this.state.restaurant.todaysMenus.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/restaurant/todays-menu').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setTodaysMenus', data);
                });
            }
        },
        async getTodaysMenu({commit}, id){
            if(!this.state.restaurant.todaysMenus.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/todays-menu').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setTodaysMenu', data);
            } else {
                console.log(this.state.restaurant.todaysMenus);
                const data = this.state.restaurant.todaysMenus.find(x => x.objectID === id)
                commit('setTodaysMenu', data);
            }
        },
            async addTodaysMenu({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/todays-menu').add(data);
            },
            async updateTodaysMenu({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/todays-menu').doc(id).update(data);
            },
            async deleteTodaysMenu({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/todays-menu').doc(id).delete();
            },

        // Dishes
        async getDishes({commit}){
            if(!this.state.restaurant.dishes.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/dishes').orderBy('category.name', 'desc').onSnapshot(res => {
                    const data = [];
                    res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                        data.push(e);
                        })
        
                        commit('setDishes', data);
                });
            }
        },
        async getDish({commit}, id){
            if(!this.state.restaurant.dishes.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setDish', data);
            } else {
                const data = this.state.restaurant.dishes.find(x => x.objectID === id)
                commit('setDish', data);
            }
        },
            async addDish({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/dishes').add(data);

            },
            async updateDish({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(id).update(data);
            },
            async deleteDish({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(id).delete();
            },


        // Dishes Categories
        async getDishesCategories({commit}){
            if(!this.state.restaurant.dishesCategories.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/categories').orderBy('order', 'asc').onSnapshot(res => {
                    const data = [];
                    res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                        data.push(e);
                        })
                        commit('setDishesCategories', data);
                });
            }
        },
        async getDishCategory({commit}, id){
            if(!this.state.restaurant.dishesCategories.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/categories').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setDishCategory', data);
            } else {
                const data = this.state.restaurant.dishesCategories.find(x => x.objectID === id)
                commit('setDishCategory', data);
            }
        },
            async addDishCategory({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/categories').add(data);
            },
            async updateDishCategory({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/categories').doc(id).update(data);
            },
            async deleteDishCategory({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/categories').doc(id).delete();
            },


        // Promotions
        async getPromotions({commit}){
            if(!this.state.restaurant.promotions.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const res = await dbClient.collection('/evolbe-modules/restaurant/promotions').orderBy('order', 'asc').get();

                data = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                data.push(e);
                });

                commit('setPromotions', data);
            }
        },
        async getPromotion({commit}, id){
            if(!this.state.restaurant.promotions.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/promotions').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setPromotion', data);
            } else {
                const data = this.state.restaurant.promotions.find(x => x.objectID === id)
                commit('setPromotion', data);
            }
        },
            async addPromotion({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/promotions').add(data);
            },
            async updatePromotion({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/promotions').doc(id).update(data);
            },
            async deletePromotion({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/promotions').doc(id).delete();
            },


        // PEDIDOS
        // Orders
        async getOrders({commit}){
            if(!this.state.restaurant.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const newOrderNotification = new Audio(notificationSound);
                await dbClient.collection('/evolbe-modules/restaurant/orders').where('status', '>', 0).where('status', '<', 4).orderBy('status', 'asc').onSnapshot(async res => {
                    data = [];

                    let num = 0;
                    res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            number: 'A' + (res.size - num),
                            ...item.data()
                        }
                        
                        data.push(e);
                        num++;
                    })

                    if(data.length > this.state.restaurant.orders.length){
                        
                        // Notificación Sonora
                        await newOrderNotification.play();

                        // Notificación visual
                        const alert = await alertController
                        .create({
                            cssClass: 'new-order-alert',
                            header: '¡Nuevo Pedido!',
                            message: 'Nuevo pedido a las ' + moment(data[0].dateCreated).format('HH:mm'),
                            mode: 'ios',
                            buttons: [
                            {
                                text: 'Aceptar',
                                id: 'confirm-button',
                                cssClass: 'new-order-confirm-button',
                                handler: () => {
                                console.log('Confirm Okay')
                                },
                            },

                            {
                                text: 'Imprimir',
                                id: 'print-button',
                                cssClass: 'new-order-print-button',
                                handler: () => {
                                    router.push('/modules/restaurant/orders?print=' + data[0].objectID);
                                },
                            },
                            ]
                        });
                        alert.present();
                   }

                   data.forEach(async e => {
                    if(e.customer && e.customer.address && e.customer.address.street){
                        e.customer.address = e.customer.address.street + ', ' + e.customer.address.number + ', ' + e.customer.address.specific + ', ' + e.customer.address.zipCode + ', ' + e.customer.address.city;
                    } else if (e.delivery && !e.customer.address){
                        const ures = await dbClient.collection('/users').doc(e.customer.uid).get();
                        
                        if(ures.exists){
                            const user = ures.data();
                            e.customer.address = user.address.street + ', ' + user.address.number + ', ' + user.address.specific + ', ' + user.address.zipCode + ', ' + user.address.city;    
                            await dbClient.collection('/evolbe-modules/restaurant/orders').doc(e.objectID).update(e);
                        }
                    }
                   });

                    commit('setOrders', data);
                });
            }
        },
        async getOrder({commit}, id){
            if(!this.state.restaurant.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.restaurant.orders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/orders').doc(id).set(data);
            },
            async updateOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/orders').doc(id).update(data);
            },
            async deleteOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/orders').doc(id).delete();
            },

        // Stored orders
        async getStoredOrders({commit}){
            if(!this.state.restaurant.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const res = await dbClient.collection('/evolbe-modules/restaurant/orders').where('status', '>=', 4).get();

                data = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                data.push(e);
                });

                const newData2 = data.sort((a,b) => b.datePurchased - a.datePurchased);
                
/*
                let artesano = 0;
                let brioche = 0;
                let singluten = 0;
                newData2.forEach(element => {
                    element.items.forEach(item => {
                        if(item.modifiers && item.modifiers.length){
                            item.modifiers.forEach(modifier => {
                                modifier.options.forEach(option => {
                                    if(option.name === 'Pan Brioche' && option.selected === true){
                                        brioche = brioche + 1;
                                    } else if(option.name === 'Pan Artesano' && option.selected === true){
                                        artesano = artesano + 1;
                                    }
                                    else if(option.name === 'Pan Sin Gluten' && option.selected === true){
                                        singluten = singluten + 1;
                                    }
                                });
                            });
                        } else if (item.variations && item.variations.length){
                            item.variations.forEach(modifier => {
                                modifier.options.forEach(option => {
                                    if(option.text === 'Pan Brioche' && option.selected === true){
                                        brioche = brioche + 1;
                                    } else if(option.text === 'Pan Artesano' && option.selected === true){
                                        artesano = artesano + 1;
                                    }
                                    else if(option.name === 'Pan Sin Gluten' && option.selected === true){
                                        singluten = singluten + 1;
                                    }
                                });
                            });
                        }
                    });
                    console.log('e:',element);
                });

                console.log('artesanal', artesano);
                console.log('brioche', brioche);
                console.log('singluten', brioche);*/
      
                commit('setStoredOrders', newData2);
              }
        },
        async getStoredOrder({commit}, id){
            if(!this.state.restaurant.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.restaurant.storedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(id).set(data);
            },
            async updateStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(id).update(data);
            },
            async deleteStoredOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(id).delete();
            },

        // Abandoned orders
        async getAbandonedOrders({commit}){
            if(!this.state.restaurant.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_orders').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setAbandonedOrders', data);
                });
            }
        },
        async getAbandonedOrder({commit}, id){
            if(!this.state.restaurant.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/restaurant/abandoned_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.restaurant.abandonedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addAbandonedOrder({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_orders').add(data);
            },
            async updateAbandonedOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_orders').doc(id).update(data);
            },
            async deleteAbandonedOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/restaurant/abandoned_orders').doc(id).delete();
            },


     

        // DATA TRANSFORM
        // Update Stored Orders
        async updateStoredOrders({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
            let data = [];

            const res = await dbClient.collection('/evolbe-modules/restaurant/stored_orders').orderBy('dateCreated', 'desc').get();

            data = [];
            res.forEach(item => {
            const e = {
                objectID: item.id,
                ...item.data()
            }
            data.push(e);
            })

            console.log('total:', data.length)
            
            setTimeout(async () => {
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];

                    const id = JSON.parse(JSON.stringify(element.objectID));
                    delete element.objectID;
                    element.dateUpdated = 1640204102000;
                    await dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(id).update(element);
                    console.log('actualizado ',index,' de ',data.length);
                }
            }, 10000);
        },

        // Dishes
        async convertDishes({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            const categorias = [];
            await dbClient.collection('/evolbe-modules/restaurant/categories').orderBy('order', 'asc').onSnapshot(async resp => {
              resp.forEach(elemento => {
                const c = {
                  objectID: elemento.id,
                  ...elemento.data()
                }
  
                categorias.push(c);
              });

              await dbClient.collection('/restaurant-module/carta/platos').orderBy('categoria.nombre', 'desc').onSnapshot(res => {
                res.forEach(async item => {
                  const e = {
                    ...item.data()
                  }

                  const cat = categorias.find(x => x.name === e.categoria.nombre);
  
                  let vari = undefined;
                  if(e.variaciones){
                    vari = [
                      {
                        key: 0,
                        name: 'Punto de la Carne',
                        options: []
                      }
                    ]
  
                    e.variaciones.forEach((element, i) => {
                      vari[0].options.push(
                        {
                          key: '0-' + i,
                          text: element.nombre,
                          price: 0,
                          value: i,
                        }
                      )
                    });
                  }
  
                  const data = {
                    active: e.activo,
                    category: cat,
                    description: e.descripcion,
                    dateCreated: e.fecha_creacion || new Date().getTime(),
                    images: ['https://firebasestorage.googleapis.com/v0/b/docemasunorestaurante-com.appspot.com/o/carta%2Fplatos%2F' + e.imagenes[0] + '.jpg?alt=media'],
                    name: e.nombre,
                    price: e.precio,
                    dateUpdated: new Date().getTime(),
                    variations: vari
                  }
                  
                  console.log(data);
                  //await dbClient.collection('/evolbe-modules/restaurant/dishes').add(data);
  
                })
              })
            });
        },

        async updateAllDishesCategories({commit}, {data, id}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
            
            const empresas = [];
            const res =await dbClient.collection('/evolbe-modules/restaurant/dishes').where('category.objectID', '==', id).get();
            res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                empresas.push(e);
            });

            empresas.forEach(async element => {
                const newElement = element;
                newElement.category = data;
                await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(element.objectID).update(newElement);
            });
        },

        // promotions
        async convertPromotions({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);

            const data = []
            await dbClient.collection('/evolbe-modules/restaurant/dishes').where('category.id', '==', '3bPINLJ1FRZDnlgipAyC').onSnapshot(async res => {
              console.log(res);
              res.forEach(item => {
                const e = {
                  objectID: item.id,
                  ...item.data()
                }

                data.push(e);
              })

              await dbClient.collection('/evolbe-modules/restaurant/dishes').where('category.id', '==', 'BJP0MvLUXSsyQVHyjHjV').onSnapshot(async res2 => {
                res2.forEach(item => {
                  const e = {
                    objectID: item.id,
                    ...item.data()
                  }
  
                  data.push(e);
                })
              
                await dbClient.collection('/evolbe-modules/restaurant/dishes').where('category.id', '==', 'FrCuJYIYRQYxi0GkUJPN').onSnapshot(res3 => {
                  res3.forEach(item => {
                    const e = {
                      objectID: item.id,
                      ...item.data()
                    }
    
                    data.push(e);
                  })

                  const newData = {
                  steps: [
                    {
                      name: 'Elige una Hamburguesa, Sandwich o Fajita',
                      multipleSelections: 0,
                      dishes: data,
                    },
                    {
                      name: 'Elige otra Hamburguesa, Sandwich o Fajita',
                      multipleSelections: 0,
                      dishes: data,
                    },
                    {
                      name: 'Escoge tu Regalo',
                      multipleSelections: 0,
                      dishes: [
                        {

                        }
                      ],
                    },
                    
                  ]
                }
                console.log(newData);
                //dbClient.collection('/evolbe-modules/restaurant/promotions').doc('jfbLlMRSRBb11T1IWADK').update(newData);
              });
            });
          });
        },



        // orders
        async convertOrders({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
    
            const data = []
            await dbClient.collection('/restaurant-module/pedidos/pedidos_almacenados').onSnapshot(async res => {
              console.log(res);
              res.forEach(item => {
                const e = {
                  objectID: item.id,
                  ...item.data()
                }
    
                data.push(e);
              });


    
              data.forEach(element => {
                if(element.pedido){
                  const newItems = [];
    
                  element.pedido.forEach(item => {
                    let subitems;

                    if(item.subplatos){
                        subitems = [];

                        item.subplatos.forEach(vari => {
                            subitems.push({
                                name: vari.nombre,
                                quantity: 1,
                            })
                        });
                    }

                    if(item.variaciones){
                        subitems = [];

                        item.variaciones.forEach(vari => {
                            subitems.push({
                                name: vari.nombre,
                                quantity: 1,
                            })
                        });
                    }

                    newItems.push({
                      quantity: item.cantidad,
                      name: item.nombre,
                      price: item.precio,
                      items: subitems,
                      images: [
                        {
                          "256": item.imagen,
                          "512": item.imagen,
                          "1024": item.imagen,
                          "2048": item.imagen,
                        }
                      ]
                    });
                  });

                  let newAddress;
                  if(element.direccion_entrega && element.direccion_entrega.direccion.toLowerCase() !== 'recogida'){
                      newAddress = element.direccion_entrega.direccion;

                      if(element.direccion_entrega.numero){
                        newAddress = newAddress + ', ' + element.direccion_entrega.especifico;
                      }

                      if(element.direccion_entrega.numero){
                        newAddress = newAddress + ', ' + element.direccion_entrega.numero
                      }
                  }
      
                  const newData = {
                    customer: {
                      firstName: element.cliente && element.cliente.nombre.trim(),
                      lastName: element.cliente && element.cliente.apellidos.trim(),
                      phone: element.cliente && element.cliente.telefono.trim(),
                      address: newAddress,
                      city: element.direccion_entrega && element.direccion_entrega.ciudad,
                      zipCode: element.direccion_entrega && element.direccion_entrega.postal,
                      uid: element.cliente && element.cliente.uid,
                    },
                    items: newItems,
                    payment: {
                      method: element.metodo_pago && element.metodo_pago.metodo && element.metodo_pago.metodo === 'Tarjeta' && 'stripe' || 'cash',
                      status: 'succeeded',
                    },
                    platform: null,
                    observations: element.observaciones,
                    coupon: element.coupon,
                    status: element.estado,
                    dateCreated: element.fecha_creacion,
                    delivery: element.direccion_entrega && element.direccion_entrega.direccion.toLowerCase() === 'recogida' && false || true,
                    deliveryHour: element.hora_entrega && moment(parseInt(element.hora_entrega)).format('HH:mm'),
                    subtotal: element.resumen && element.resumen.subtotal,
                    shipping: element.resumen && element.resumen.envio,
                    surcharge: 0,
                    tax: element.resumen && element.resumen.iva,
                    total: element.resumen && element.resumen.total
                  }
      
                  console.log(newData);
                  //dbClient.collection('/evolbe-modules/restaurant/stored_orders').doc(element.objectID).set(newData);
                }
              });
          });
        },

        async editPromotions({commit}, id){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
            const res = await dbClient.collection('/evolbe-modules/restaurant/promotions').doc('AIqwfvxe6HiIHOzoL3xH').get();

            const item = res.data();
            const data = {
                objectID: item.id,
                ...item
            }

            const newData = JSON.parse(JSON.stringify(data));
            newData.groups = newData.steps;

            newData.groups.forEach((grupo, i) => {
                newData.groups[i].articles = newData.groups[i].dishes;
                delete newData.groups[i].dishes;
            });

            delete newData.steps;

            console.log(newData);

            //await dbClient.collection('/evolbe-modules/restaurant/promotions').doc('AIqwfvxe6HiIHOzoL3xH').set(newData);
        },


        async updateVariations({commit}){
            if(!this.state.restaurant.dishes.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                const res = await dbClient.collection('/evolbe-modules/restaurant/dishes').where('objectID', '==' ,'8dw8cHbmtNddCbWoT6H8').get();

                data = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                data.push(e);
                })

                
                /*data.forEach(async element => {
                    if(element.variations && element.variations.length){
                        element.variations.push({
                            key: element.variations.length,
                            name: 'Escoge tu pan',
                            type: 'radio',
                            options: [
                                {
                                    key: element.variations.length + '-0',
                                    text: 'Pan Artesano'
                                },
                                {
                                    key: element.variations.length + '-1',
                                    text: 'Pan Sin Gluten',
                                    increase: 1
                                },
                            ]
                        });
                        await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(element.objectID).update(element);
                    }
                });*/

                data.forEach(async element => {
                    if(element.variations && element.variations.length){
                        element.variations.push({
                            key: element.variations.length,
                            name: 'Ingredientes',
                            type: 'checkbox',
                            options: [
                                {
                                    key: element.variations.length + '-0',
                                    text: 'Sin Lechuga'
                                },
                                {
                                    key: element.variations.length + '-1',
                                    text: 'Sin Tomate'
                                },
                                {
                                    key: element.variations.length + '-2',
                                    text: 'Sin Cebolla'
                                },
                                {
                                    key: element.variations.length + '-3',
                                    text: 'Sin Queso'
                                },
                                {
                                    key: element.variations.length + '-4',
                                    text: 'Sin Salsa'
                                },
                                {
                                    key: element.variations.length + '-5',
                                    text: 'Sin Picante'
                                },
                            ]
                        });
                        await dbClient.collection('/evolbe-modules/restaurant/dishes').doc(element.objectID).update(element);
                    }
                });

                /*
                data.forEach(element => {
                    element.steps.forEach(step => {
                        step.dishes.forEach(async dish => {
                            if(dish.variations && dish.variations.length){
                                dish.variations.forEach(variation => {
                                    variation.type = 'radio';
                                });
                            }
        
                            await dbClient.collection('/evolbe-modules/restaurant/promotions').doc(element.objectID).update(element);
                        });
                    });
                });
                */
            }
        },
    },
  }