<template>
  <ion-page>
    <ion-content :fullscreen="true">

      <Header></Header>

      <div class="container">
        <section id="options" class="list-nav mt-lg mb-md">
            <ion-grid>
              <ion-row>
                <ion-col size="12" size-lg="6">
                  <h4>Carta</h4>
                  <h1>Platos</h1>
                </ion-col>
              </ion-row>
              <ion-row style="margin-top: 1rem">
                <ion-col size="12" size-lg="6">
                  <span class="p-input-icon-left" style="width: 100%;" c>
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Buscar..." class="search" />
                  </span>
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <MultiSelect class="input table-columns-selector" :modelValue="selectedColumns" :options="columns" @update:modelValue="onToggle" placeholder="Seleccionar Columnas" />
                </ion-col>
                <ion-col size="12" size-lg="3">
                  <ion-buttons class="options-nav">
                    <ion-button @click="confirmDeleteAllProducts($event)" v-if="selectedElements.length">
                      <ion-icon :icon="trashOutline" color="danger" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ConfirmPopup></ConfirmPopup>
                    <ion-button @click="duplicate()" v-if="selectedElements.length">
                      <ion-icon :icon="duplicateOutline" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ion-button @click="exportCSV($event)">
                      <ion-icon :icon="cloudDownloadOutline" slot="icon-only" ></ion-icon>
                    </ion-button>
                    <ion-button @click="$router.push({ path: `/modules/restaurant/dishes/new` })">
                      <ion-icon slot="icon-only" :icon="addCircleOutline"></ion-icon>
                    </ion-button>
                  </ion-buttons>
                </ion-col>
              </ion-row>
            </ion-grid>
          </section>

        <section id="lists" class="mb-lg fullwidth-mobile">
            <DataTable 
                :value="elements"
                :totalRecords="totalRecords"
                ref="dt"
                :lazy="false"
                v-model:selection="selectedElements"
                dataKey="objectID"
                removableSort
                responsiveLayout="scroll"
                :paginator="true"
                :rows="25"
                v-model:filters="filters"
                :loading="loading"
                :reorderableColumns="true"
                :globalFilterFields="['name', 'first', 'second', 'desserts', 'drinks', 'uid']"
                filterDisplay="menu"
                :rowsPerPageOptions="[25,50,100]"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando desde {first} a {last} de {totalRecords} elementos"
              >
                <template #empty>
                    No hay resultados
                </template>
                <template #loading>
                    Cargando datos...
                </template>
                <Column selectionMode="multiple"></Column>

                <!-- Secuecia Normal -->
                <Column v-if="selectedColumns.includes('Imagen')" field="images" header="Imagen" style="flex-grow:1; flex-basis:64px; min-width: 64px;">
                  <template #body="slotProps">
                      <img v-if="slotProps.data.images.length" :src="slotProps.data.images[0]['256']" alt="preview" class="preview" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Nombre')" field="name" header="Nombre" filterMatchMode="contains" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field table-primary-field">{{ slotProps.data.name.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Categoría')" filterField="category.name" :showFilterMatchModes="false" header="Categoría" :sortable="true">
                  <template #body="slotProps">
                      <span v-if="slotProps.data.category" class="table-field">{{ slotProps.data.category.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <MultiSelect v-model="filterModel.value" :options="dishesCategories" optionLabel="name" placeholder="Seleccionar" class="p-column-filter">
                          <template #option="slotProps">
                            <span class="table-field">{{slotProps.option.name}}</span>
                          </template>
                      </MultiSelect>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Descripción')" field="description" header="Descripción">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.description.toLowerCase() }}</span>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Precio')" field="price" header="Precio" dataType="numeric" :sortable="true">
                  <template #body="slotProps">
                    <span class="table-field">{{ slotProps.data.price.toFixed(2) }}€</span>
                  </template>
                  <template #filter="{filterModel}">
                      <InputNumber v-model="filterModel.value" mode="currency" currency="EUR" locale="es-ES" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Activo')" field="active" header="Activo" :sortable="true">
                  <template #body="slotProps">
                      <ion-chip v-if="slotProps.data.active" @click="changeStatus(slotProps.data)" color="success"><ion-label >Activo</ion-label></ion-chip>
                      <ion-chip v-if="!slotProps.data.active"  @click="changeStatus(slotProps.data)" color="danger"><ion-label >Inactivo</ion-label></ion-chip>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="activeOptions" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <!-- Datos Expandidos -->
                <Column v-if="selectedColumns.includes('Fecha Creación')" field="dateCreated" header="Creación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateCreated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Fecha Modificación')" field="dateUpdated" header="Modificación" dataType="date" :sortable="true">
                  <template #body="slotProps">
                      <span class="table-field">{{ fecha(slotProps.data.dateUpdated) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                      <Calendar autocomplete="off" v-model="filterModel.value" dateFormat="dd/mm/yyyy - hh:mm" placeholder="dd/mm/yyyy - hh:mm" :showTime="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Variaciones')" field="variations" header="Variaciones" filterMatchMode="contains">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.variations">
                      <span class="table-field" v-for="variation in slotProps.data.variations" :key="'key' + variation.key">{{ variation.name }}</span>
                    </div>
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('Alérgenos')" field="allergens" header="Alérgenos" filterMatchMode="contains">
                  <template #body="slotProps">
                    <div v-if="slotProps.data.allergens">
                      <span class="table-field" v-for="allergen in slotProps.data.allergens" :key="'key' + allergen.name">{{ allergen.name }}</span>
                    </div>
                  </template>
                  <template #filter="{filterModel}">
                      <Dropdown v-model="filterModel.value" :options="allergensOptions" placeholder="Cualquiera" optionLabel="label" optionValue="value" class="p-column-filter" :showClear="true" />
                  </template>
                </Column>

                <Column v-if="selectedColumns.includes('ID')" field="objectID" header="ID" filterMatchMode="contains">
                  <template #body="slotProps">
                      <span class="table-field">{{ slotProps.data.objectID.toLowerCase() }}</span>
                  </template>
                </Column>

                <!-- Acciones -->
                <Column field="actions" style="width: 122px;">
                  <template #body="slotProps">
                      <ion-button shape="round" fill="outline" color="dark" :router-link="'/modules/restaurant/dishes/' + slotProps.data.objectID">
                        <ion-label>Editar</ion-label>
                      </ion-button>
                  </template>
                </Column>

            </DataTable>
          </section>
      </div>

      <Footer></Footer>

    </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonIcon, IonLabel, IonChip } from '@ionic/vue';
import { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline, duplicateOutline } from 'ionicons/icons';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import ConfirmPopup from 'primevue/confirmpopup';

import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';

import { FilterMatchMode, FilterOperator } from 'primevue/api';

import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';


export default defineComponent({
  name: 'Dishes',
  components: {
    IonContent,
    IonPage,
    Header,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonIcon,
    Footer,
    DataTable,
    Column,
    InputText,
    MultiSelect,
    IonLabel,
    InputNumber,
    Calendar,
    Dropdown,
    IonChip,
    ConfirmPopup
  },
    data() {
      return {
        search: '',
        selectedElements: [],
        filters: {},
        totalRecords: 0,

        activeOptions: [
          {
            label: 'Activo',
            value: true,
          },
          {
            label: 'Inactivo',
            value: false,
          },
        ],

        allergensOptions: [
          {
            image: 'altramuces.svg',
            name: 'Altramuces'
          },
          {
            image: 'apio.svg',
            name: 'Apio'
          },
          {
            image: 'cacahuetes.svg',
            name: 'Cacahuetes'
          },
          {
            image: 'dioxido-de-azufre-y-sulfitos.svg',
            name: 'Dióxido de Azufre y Sulfitos'
          },
          {
            image: 'frutos-de-cascara.svg',
            name: 'Frutos de Cáscara'
          },
          {
            image: 'gluten.svg',
            name: 'Gluten'
          },
          {
            image: 'granos-de-sesamo.svg',
            name: 'Granos de Sésamo'
          },
          {
            image: 'huevos.svg',
            name: 'Huevos'
          },
          {
            image: 'lacteos.svg',
            name: 'Lácteos'
          },
          {
            image: 'moluscos.svg',
            name: 'Moluscos'
          },
          {
            image: 'mostaza.svg',
            name: 'Mostaza'
          },
          {
            image: 'pescado.svg',
            name: 'Pescado'
          },
          {
            image: 'soja.svg',
            name: 'Soja'
          }
        ],

        selectedColumns: null,
        columns: [
          'Imagen',
          'Nombre',
          'Categoría',
          'Descripción',
          'Precio',
          'Activo',

          'Fecha Creación',
          'Fecha Modificación',
          'Variaciones',
          'Alérgenos',
          'ID'
        ],
      };
    },
    computed: {
      ...mapState('restaurant', {
        elements: state => state.dishes,
        dishesCategories: state => state.dishesCategories,
      }),

      ...mapState(['evolbeUser', 'preferences'])
    },
    methods: {
      ...mapActions('restaurant',['getDishes', 'addDish', 'deleteDish', 'getDishesCategories', 'updateVariations', 'updateDish']),

      // Initialization
      initFilters() {
          this.selectedColumns = [
            'Imagen',
            'Nombre',
            'Categoría',
            'Precio',
            'Activo',
          ];

          this.filters = {
              'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
              'name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
              'category.name': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'price': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'dateCreated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'dateUpdated': {operator: FilterOperator.AND, constraints: [{value: null, matchMode: FilterMatchMode.DATE_IS}]},
              'active': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
              'allergens': {operator: FilterOperator.OR, constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]},
          }

          this.lazyParams = {
              first: 0,
              rows: 0,
              sortField: null,
              sortOrder: null,
              filters: this.filters
          }
      },

      exportCSV() {
          this.$refs.dt.exportCSV();
      },

      onToggle(value) {
        this.selectedColumns = this.columns.filter(col => value.includes(col));
      },

      duplicate(){
        this.selectedElements.forEach(selected => {
          const element = JSON.parse(JSON.stringify(selected));
          element.dateCreated = new Date().getTime();
          element.dateUpdated = new Date().getTime();
          element.name = element.name + ' (Duplicado)';
          element.active = false;
          delete element.objectID;

          this.addDish(element);
        });

      },

      confirmDeleteAllProducts(event){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar estos elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                    this.selectedElements.forEach(product => {
                      this.deleteDish(product.objectID);
                    });

                    this.selectedElements = [];
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
      },

      handleEdit(id){
        this.$router.push({ path: `/modules/restaurant/dishes/${id}` })
      },

      changeStatus(element){
        element.active = !element.active;
        this.updateDish({data: element, id: element.objectID})
      },

      confirmDeleteElement(event, id){
        this.$confirm.require({
                target: event.currentTarget,
                message: '¿Seguro de que quieres eliminar ese elemento?',
                acceptLabel: 'Si',
                rejectLabel: 'No',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when evolbeUser confirms the action
                     this.deleteDish(id);
                },
                reject: () => {
                    //callback to execute when evolbeUser rejects the action
                }
            });
         
      },

      fecha(date){
        return moment(date).format('DD/MM/YYYY - HH:mm');
      },
    },
    created(){
      this.getDishes();
      this.initFilters();
      this.getDishesCategories();
      //this.updateVariations();
    },
    setup() {
      return { trashOutline, cloudDownloadOutline, addCircleOutline, qrCodeOutline, createOutline, duplicateOutline };
    }
});
</script>

<style scoped>
  .preview{
    width: 64px;
    height: 64px;
    border-radius: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .table-field{
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
  }

  .table-primary-field{
    font-weight: bold;
  }

</style>