<template>
  <ion-footer id="footer" class="ion-no-border">
    <ion-grid class="container">
      <ion-row class="footer-item">
        <ion-col size="12" size-lg="3" class="footer-box">
          <h6 style="padding-bottom: 0.5rem">Powered by</h6>
          <img src="/assets/logo_black.png" alt="Evolbe Systems" class="logo-footer"/>
          <p style="margin-top: 1rem">Versión: 2.1.4</p>
        </ion-col>
        <ion-col size="12" size-lg="3" class="footer-box">
          <h5>Empresa</h5>
          <ul class="footer-menu">
            <li><router-link to="/business/settings" v-if="evolbeUser.business[0].rol === 'admin'">Configuración</router-link></li>
            <!--<li><a>Cuentas</a></li>-->
            <li><router-link to="/business/profile">Mi Perfil</router-link></li>
          </ul>
        </ion-col>
        <ion-col size="12" size-lg="3" class="footer-box">
          <h5>Soporte</h5>
          <ul class="footer-menu">
            <!--<li><a>Evolbe Academy</a></li>
            <li><a>Centro de Ayuda</a></li>-->
            <li><a href="mailto:soporte@evolbe.systems">Reportar un Error</a></li>
          </ul>
        </ion-col>
        <ion-col size="12" size-lg="3" class="footer-box">
          <!-- Idioma -->
          <!-- Integraciones -->
        </ion-col>
      </ion-row>

      <ion-row id="subfooter">
        <ion-col size="12" size-lg="6">
          <p class="copyright">&copy; {{ anno }} Evolbe Systems. Todos los derechos reservados.</p>
        </ion-col>
        <ion-col size="12" size-lg="6">
          <ion-buttons class="footer-buttons">
            <ion-button>
              <ion-label>Terminos y Condiciones</ion-label>
            </ion-button>
            <ion-button>
              <ion-label>Privacidad</ion-label>
            </ion-button>
          </ion-buttons>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-footer>
</template>

<script>
import { IonFooter, IonGrid, IonRow, IonCol, IonButtons, IonButton, IonLabel } from '@ionic/vue';
import { defineComponent } from 'vue';
import moment from 'moment'
import { mapState } from 'vuex';

export default defineComponent({
  name: 'Footer',
  components: {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonButtons,
    IonButton,
    IonLabel,
  },
  data() {
      return {
        anno: moment().year() 
      };
    },
  computed: {
      ...mapState(['evolbeUser'])
  },
});
</script>

<style>
  .footer-item{
    border-top: 1px solid var(--ion-color-medium);
    padding-bottom: 2rem;
  }

  .footer-box{
    margin-top: 2rem;
    padding-right: 4rem;
  }

  .footer-menu{
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    list-style: none;
  }

  .footer-menu li a{
    color: var(--ion-color-dark);
    transition: 0.2s;
  }

  .footer-menu li{
    margin-bottom: 0.75rem;
  }

  .footer-menu li a:hover{
    color: var(--ion-color-primary-shade);
  }

  .logo-footer{
    width: 8rem;
  }

  #subfooter{
    padding-bottom: 1rem;
  }

  .copyright{
    font-size: 0.75rem; 
    margin-top: 8px;
  }

  .footer-buttons{
    justify-content: flex-start;
  }

  .footer-buttons ion-label{
    font-size: 0.75rem; 
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    .footer-buttons{
      justify-content: flex-end;
    }
  }

  @media (min-width: 1440px) {
    .footer-buttons{
      justify-content: flex-end;
    }
  }

</style>