<template>
  <ion-page>
    <ion-content :fullscreen="true">

        <Header></Header>

        <div class="container">
          <ion-grid style="min-height: calc(100vh - 10rem)" class="mt-lg mb-sm" v-if="evolbeUser">
            
            <!-- Restaurant -->
            <ion-row v-if="evolbeUser.business[0].modules.includes('restaurant')">
              <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <h4>Bienvenid<n v-if="evolbeUser.profile.sex === 'm'">o</n><n v-if="evolbeUser.profile.sex === 'f'">a</n></h4>
                <h1>{{ evolbeUser.profile.firstName }}</h1>
              </ion-col>
               <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile" v-if="evolbeUser.business[0].rol !== 'editor-menu'">
                <ion-card>
                  <ion-card-content>
                    <b>Pedidos Activos</b>
                    <h2 class="text-secondary">{{ restaurantOrders.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile" v-if="evolbeUser.business[0].modules.includes('experiencias-121')">
                <ion-card>
                  <ion-card-content>
                    <b>Pedidos de Experiencias</b>
                    <h2 class="text-secondary">{{ experienciasOrders.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
              <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile" v-if="evolbeUser.business[0].modules.includes('navidad-121')">
                <ion-card>
                  <ion-card-content>
                    <b>Pedidos de Navidad</b>
                    <h2 class="text-secondary">{{ navidadOrders.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>

            <!-- Restaurant -->
            <ion-row v-if="evolbeUser.business[0].modules.includes('shop')">
              <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <h4>Bienvenid<n v-if="evolbeUser.profile.sex === 'm'">o</n><n v-if="evolbeUser.profile.sex === 'f'">a</n></h4>
                <h1>{{ evolbeUser.profile.firstName }}</h1>
              </ion-col>
               <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <ion-card>
                  <ion-card-content>
                    <b>Pedidos Activos</b>
                    <h2 class="text-secondary">{{ shopOrders.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>


            <!-- Hotel -->
            <ion-row v-if="evolbeUser.business[0].modules.includes('hotel')">
              <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <h4>Bienvenid<n v-if="evolbeUser.profile.sex === 'm'">o</n><n v-if="evolbeUser.profile.sex === 'f'">a</n></h4>
                <h1>{{ evolbeUser.profile.firstName }}</h1>
              </ion-col>
               <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <ion-card v-if="hotelBookings">
                  <ion-card-content>
                    <b>Reservas</b>
                    <h2 class="text-secondary">{{ hotelBookings.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>

            <!-- Bookings -->
            <ion-row v-if="evolbeUser.business[0].modules.includes('bookings')">
              <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <h4>Bienvenid<n v-if="evolbeUser.profile.sex === 'm'">o</n><n v-if="evolbeUser.profile.sex === 'f'">a</n></h4>
                <h1>{{ evolbeUser.profile.firstName }}</h1>
              </ion-col>
               <ion-col size="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                <ion-card v-if="hotelBookings">
                  <ion-card-content>
                    <b>Reservas</b>
                    <h2 class="text-secondary">{{ restaurantBookings.length }}</h2>
                  </ion-card-content>
                </ion-card>
              </ion-col>
            </ion-row>

            <!-- Custom -->
              <!-- LGDC -->
              <ion-row v-if="evolbeUser.business[0].modules.includes('lgdc')">
                <ion-col size="12" size-sm="6" size-md="4" size-lg="3" size-xl="3" class="tile">
                  <h4>Bienvenid<n v-if="evolbeUser.profile.sex === 'm'">o</n><n v-if="evolbeUser.profile.sex === 'f'">a</n></h4>
                  <h1>{{ evolbeUser.profile.firstName }}</h1>
                </ion-col>
                <ion-col size="6" size-md="4" size-lg="3" size-xl="3"  class="tile">
                  <ion-card>
                    <ion-card-content>
                      <b>Total de Empresas</b>
                      <h2 v-if="empresasLgdc" class="text-secondary">{{ empresasLgdc.length }}</h2>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col size="12" style="margin-top: 4rem">
                  <p class="text-center">Tus estádisticas aparecerán aquí</p>
                  <h5 class="text-center">Próximamente</h5>
                </ion-col>
              </ion-row>
          </ion-grid>
        </div>

      <Footer></Footer>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonCard, IonCardContent, IonRow, IonCol } from '@ionic/vue';
import { defineComponent } from 'vue';
import Footer from '@/components/Footer.vue';

import { mapActions, mapState } from 'vuex';
import Header from '@/components/Header.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    IonContent,
    IonPage,
    Footer,
    Header,
    IonGrid,
    IonCard,
    IonCardContent,
    IonRow,
    IonCol
  },
  computed: {
      ...mapState('restaurant', {
        restaurantOrders: state => state.orders,
      }),

      ...mapState('shop', {
        shopOrders: state => state.orders,
      }),

      ...mapState('hotel', {
        hotelBookings: state => state.bookings,
      }),

      ...mapState('bookings', {
        restaurantBookings: state => state.bookings,
      }),
      
      /* Custom */
      ...mapState('experiencias121', {
        experienciasOrders: state => state.orders,
      }),

      ...mapState('navidad121', {
        navidadOrders: state => state.orders,
      }),

      ...mapState('lgdc', {
        empresasLgdc: state => state.empresas,
      }),

      ...mapState(['evolbeUser'])
  },
  methods: {
      ...mapActions({
        getRestaurantOrders:'restaurant/getOrders',
        getShopOrders:'shop/getOrders',
        getHotelBookings:'hotel/getBookings',
        getRestaurantBookings:'bookings/getBookings',
    
        // Custom
        getExperienciasOrders:'experiencias121/getOrders',
        getNavidadOrders:'navidad121/getOrders',
        getEmpresasLgdc:'lgdc/getEmpresas',
      }),
  },
  mounted(){
    if(this.evolbeUser.business[0].modules.includes('restaurant')){
      this.getRestaurantOrders();
    }

    if(this.evolbeUser.business[0].modules.includes('shop')){
      this.getShopOrders();
    }

    if(this.evolbeUser.business[0].modules.includes('hotel')){
      this.getHotelBookings();
    }

    if(this.evolbeUser.business[0].modules.includes('bookings')){
      this.getRestaurantBookings();
    }

    // Custom
    if(this.evolbeUser.business[0].modules.includes('experiencias-121')){
      this.getExperienciasOrders();
    }

    if(this.evolbeUser.business[0].modules.includes('navidad-121')){
      this.getNavidadOrders();
    }

    if(this.evolbeUser.business[0].modules.includes('lgdc')){
      this.getEmpresasLgdc();
    }
  }
});
</script>

<style scoped>
  .tile{
    padding: 0.75rem;
  }

  .tile ion-card{
    display: flex;
    align-items: flex-start;
    min-height: 100%;
  }

</style>