import { getFirestoreClient } from '@/firebaseConfig';

export const moduleHotel = {
    namespaced: true,
    state: {
        // Hotel
            // Rooms
            rooms: [],
            room: {},

            // Bookings
            bookings: [],
            booking: {},

            abandonedBookings: [],
            abandonedBooking: {},

    },
    mutations: { 
        // Hotel
            // Rooms
            setRooms(state, payload){
                state.rooms = payload
            },
            setRoom(state, payload){
                state.room = payload
            },

            // Bookings
            setBookings(state, payload){
                state.bookings = payload
            },
            setBooking(state, payload){
                state.booking = payload
            },

            setAbandonedBookings(state, payload){
                state.abandonedBookings = payload
            },
            setAbandonedBooking(state, payload){
                state.abandonedBooking = payload
            },
    },
    actions: {
        // Hotel
            // Rooms
            async getRooms({commit}){
                if(!this.state.hotel.rooms.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    let data;
                    await dbClient.collection('/evolbe-modules/hotel/rooms').orderBy('dateCreated', 'desc').onSnapshot(res => {
                        data = [];
                        res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                            data.push(e);
                        })

                        commit('setRooms', data);
                    });

                }
            },
            async getRoom({commit}, id){
                if(!this.state.hotel.rooms.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    const res = await dbClient.collection('/evolbe-modules/hotel/rooms').doc(id).get();

                    const item = res.data();
                    const data = {
                        objectID: item.id,
                        ...item
                    }
                    
                    commit('setRoom', data);
                } else {
                    const data = this.state.hotel.rooms.find(x => x.objectID === id)
                    commit('setRoom', data);
                }
            },
                async addRoom({commit}, data){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/rooms').add(data);
                },
                async updateRoom({commit}, {data, id}){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/rooms').doc(id).update(data);
                },
                async deleteRoom({commit}, id){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/rooms').doc(id).delete();
                },

            // Bookings
            async getBookings({commit}){
                if(!this.state.hotel.bookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    let data;
                    await dbClient.collection('/evolbe-modules/hotel/bookings').orderBy('dateCreated', 'desc').onSnapshot(res => {
                        data = [];
                        res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                            data.push(e);
                        })

                        commit('setBookings', data);
                    });

                }
            },
            async getBooking({commit}, id){
                if(!this.state.hotel.bookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    const res = await dbClient.collection('/evolbe-modules/hotel/bookings').doc(id).get();

                    const item = res.data();
                    const data = {
                        objectID: item.id,
                        ...item
                    }
                    
                    commit('setBooking', data);
                } else {
                    const data = this.state.hotel.bookings.find(x => x.objectID === id)
                    commit('setBooking', data);
                }
            },
                async addBooking({commit}, data){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/bookings').add(data);
                },
                async updateBooking({commit}, {data, id}){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/bookings').doc(id).update(data);
                },
                async deleteBooking({commit}, id){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/bookings').doc(id).delete();
                },

            // Abandoned Bookings
            async getAbandonedBookings({commit}){
                if(!this.state.hotel.abandonedBookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    let data;
                    await dbClient.collection('/evolbe-modules/hotel/abandoned_bookings').orderBy('dateCreated', 'desc').onSnapshot(res => {
                        data = [];
                        res.forEach(item => {
                        const e = {
                            objectID: item.id,
                            ...item.data()
                        }
                            data.push(e);
                        })

                        commit('setAbandonedBookings', data);
                    });

                }
            },
            async getAbandonedBooking({commit}, id){
                if(!this.state.hotel.abandonedBookings.length){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    const res = await dbClient.collection('/evolbe-modules/hotel/abandoned_bookings').doc(id).get();

                    const item = res.data();
                    const data = {
                        objectID: item.id,
                        ...item
                    }
                    
                    commit('setAbandonedBooking', data);
                } else {
                    const data = this.state.hotel.abandonedBookings.find(x => x.objectID === id)
                    commit('setAbandonedBooking', data);
                }
            },
                async addAbandonedBooking({commit}, data){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/abandoned_bookings').add(data);
                },
                async updateAbandonedBooking({commit}, {data, id}){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/abandoned_bookings').doc(id).update(data);
                },
                async deleteAbandonedBooking({commit}, id){
                    const dbClient = getFirestoreClient(this.state.evolbeUser);
                    await dbClient.collection('/evolbe-modules/hotel/abandoned_bookings').doc(id).delete();
                },
    },
}