import { auth, db, getFirestoreClient } from '@/firebaseConfig';
import { createStore } from 'vuex'
import router from '../router/index';
import { getStorageClient } from '../firebaseConfig';

import { modalController } from '@ionic/vue';
import Preview from '../components/Preview.vue'

// Modules
import { moduleRestaurant } from './moduleRestaurant';
import { moduleShop } from './moduleShop';
import { moduleExperiencias121 } from './custom/moduleExperiencias121'
import { moduleNavidad121 } from './custom/moduleNavidad121'
import { moduleLgdc } from './custom/moduleLGDC';
import { moduleBlog } from './moduleBlog';
import { moduleHotel } from './moduleHotel';
import { moduleBookings } from './moduleBookings';
import { moduleClients } from './moduleClients';

function getFileBlob(url, cb) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.addEventListener('load', function() {
    cb(xhr.response);
  });
  xhr.send();
}

export const store = createStore({
  state: {
    settings: undefined,
    preferences: undefined,
    evolbeUser: undefined,
  },
  mutations: {
    // Evolbe User
    setUser(state, payload){
      state.evolbeUser = payload
    },

    setPreferences(state, payload){
      state.preferences = payload
    },

    setSettings(state, payload){
      state.settings = payload
    },        

  },
  actions: {
    // Evolbe User
    async loginEmail({commit, dispatch}, usuario){
      try {
          const res = await auth.signInWithEmailAndPassword(usuario.email, usuario.password);
          const user = await db.collection('users').doc(res.user.uid).get();
          if(user.exists){
              const myUser = user.data();
              myUser.uid = res.user.uid;
              
              myUser.lastLogin = new Date().getTime();
              await db.collection('users').doc(myUser.uid).update(myUser);

              commit('setUser', myUser);
              localStorage.setItem('evolbeUser', JSON.stringify(myUser));
              router.go();
          }
      } catch (error) {
          console.log(error);
          return error;
      }
    },

    async logout({commit}){
      await auth.signOut();
      commit('setUser', null);
      localStorage.removeItem('evolbeUser');
      router.push('/login');
    },

    async updatePassword({commit}, email){
      await auth.sendPasswordResetEmail(email).then(() => {
          // Password reset email sent!
      })
      .catch((error) => {
          console.log(error.code, error.message);
          return error;
      });
    },

    async updateUser({commit}, evolbeUser){
      console.log(evolbeUser);
      await db.collection('users').doc(evolbeUser.uid).update(evolbeUser);
      commit('setUser', evolbeUser);
      localStorage.setItem('evolbeUser', JSON.stringify(evolbeUser));
    },

    async changeMail(){
      await db.collection('users').doc('yFUuhsPjWMatsGRqOt9OBvDL84D2').get().then(res => {
        db.collection('users').doc('TpZKM8NbI2ZdxDkQ4z7ei6HST0A2').set(res.data());
      });
    },

    // Preview
    async openDetails({commit}, data){
      const modal = await modalController
          .create({
            component: Preview,
            cssClass: 'modal-details',
            componentProps: {
              previewContent: data
            },
          })

        return modal.present();
    },

    async closeDetails({commit}){
      modalController.dismiss();
    },

    // Others
    loadLocalStorage({commit}){
      // Evolbe User
      if(this.state.evolbeUser === undefined){
        if(localStorage.getItem('evolbeUser')){
          commit('setUser', JSON.parse(localStorage.getItem('evolbeUser')));
        } else {
          commit('setUser', null);
        }
      }

      // Preferences
      if(this.state.preferences === undefined){
        if(localStorage.getItem('preferences')){
          commit('setPreferences', JSON.parse(localStorage.getItem('preferences')));
        } else {
          commit('setPreferences', {});
        }
      }
    },

    uploadImage({commit}, {ruta, img}){
      const storageClient = getStorageClient(this.state.evolbeUser);
      getFileBlob(img.objectURL, blob => {
        storageClient.ref(ruta).child(img.id + '.jpg').put(blob).then((result) => {
          return result.state;
        }).catch((err) => {
          console.log('error:', err);
          return 'error';
        });
      })
    },

    uploadImageMenu({commit}, {ruta, img}){
      const storageClient = getStorageClient(this.state.evolbeUser);
      getFileBlob(img.objectURL, blob => {
        storageClient.ref(ruta).child('menu-dia.jpg').put(blob).then((result) => {
          return result.state;
        }).catch((err) => {
          console.log('error:', err);
          return 'error';
        });
      })
    },

    // Settings
    async getSettings({commit}){
      if(!this.state.settings){
          const dbClient = getFirestoreClient(this.state.evolbeUser);
          let data = [];
          const res = await dbClient.collection('/settings').get();

          data = [];
          res.forEach(item => {
          const e = {
              objectID: item.id,
              ...item.data()
          }
          data.push(e);
          })
          commit('setSettings', data);
      }
    },
    async updateSettings({commit}, {data, id}){
        const dbClient = getFirestoreClient(this.state.evolbeUser);
        await dbClient.collection('/settings').doc(id).update(data);
    },
  },
  getters: {
    isLogin(state){
      return !!state.evolbeUser;
    }
  },
  modules: {
    restaurant: moduleRestaurant,
    shop: moduleShop,
    hotel: moduleHotel,
    bookings: moduleBookings,
    clients: moduleClients,
    blog: moduleBlog,
    // Custom
    experiencias121: moduleExperiencias121,
    navidad121: moduleNavidad121,
    lgdc: moduleLgdc
  }
})
