<template>
        <ion-header class="ion-no-border" id="header">
            <ion-item lines="none" class="container header-item">
                <router-link to="/" class="logo" slot="start">
                    <img src="assets/business_black.png" alt="Evolbe Business"/>
                </router-link>

                <ion-buttons slot="end"  style="margin-right: 0">
                    <ion-button :disabled="true">
                        <ion-icon :icon="notificationsOutline" slot="icon-only"></ion-icon>
                    </ion-button>
                    <ion-button @click="openMenu($event)">
                        <ion-icon :icon="menuOutline" slot="icon-only"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-item>
        </ion-header>
</template>

<script>
import { IonHeader, IonButtons, IonButton, IonIcon, IonItem, modalController } from '@ionic/vue';
import { menuOutline, notificationsOutline, chevronBackOutline, closeOutline } from 'ionicons/icons';

import { defineComponent } from 'vue';
import Menu from './Menu.vue';

export default defineComponent({
  name: 'Header',
  props:['title'],
  components: {
      IonItem,
      IonHeader,
      IonButtons,
      IonButton,
      IonIcon,
  },
  methods:{
      async openMenu(){
        const modal = await modalController
        .create({
            component: Menu,
            cssClass: 'full-modal',
            })
        await modal.present();
      }
  },
  setup() {
    return { menuOutline, notificationsOutline, chevronBackOutline, closeOutline };
  }
});
</script>

<style scoped>
    #header{
        height: 5rem;
        padding: 0;
    }

    .header-item{
        --inner-padding-start: 0;
        --inner-padding-end: 0;
        --inner-padding-top: 0;
        --inner-padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        border-bottom: 1px solid var(--ion-color-medium);
    }

    .logo{
        display: block;
        cursor: pointer;
        width: auto;
        height: 30px;
        margin: 0;

    }

    .logo img{
        height: 100%;
    }
    
</style>