import { getFirestoreClient } from '@/firebaseConfig';

import moment from 'moment';

import algoliasearch from 'algoliasearch/lite';

export const moduleExperiencias121 = {
    namespaced: true,
    state: {
        // Experiencias
        experiencias: [],
        experiencia: {},

        orders: [],
        storedOrders: [],
        abandonedOrders: [],
        order: {},

        experienciasHours: {},
    },
    mutations: { 
        // Experiencia
        setExperiencias(state, payload){
            state.experiencias = payload
        },
        setExperiencia(state, payload){
            state.experiencia = payload
        },
        
        setOrders(state, payload){
            state.orders = payload
        },
        setStoredOrders(state, payload){
            state.storedOrders = payload
        },
        setAbandonedOrders(state, payload){
            state.abandonedOrders = payload
        },
        setOrder(state, payload){
            state.order = payload
        },


        setExperienciasHours(state, payload){
            state.experienciasHours = payload
        },
    },
    actions: {
        // Experiencias


        // EXPERIENCIAS
        // Experiencias
        async getExperiencias({commit}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                
                const res = await dbClient.collection('/evolbe-modules/experiencias/experiencias').orderBy('dateCreated', 'desc').get();
                let data1 = [];

                data1 = [];
                res.forEach(item => {
                const e = {
                    objectID: item.id,
                    ...item.data()
                }
                data1.push(e);
                })


                const res3 = await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').orderBy('dateCreated', 'desc').get();
                const data3 = [];

                res3.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }

                    data3.push(e);
                })

                const data = [].concat(data1, data3);
                console.log('data', data);

                commit('setExperiencias', data);
        },
        async getExperiencia({commit}, id){
            if(!this.state.experiencias121.experiencias.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/experiencias/experiencias').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setExperiencia', data);
            } else {
                const data = this.state.experiencias121.experiencias.find(x => x.objectID === id)
                commit('setExperiencia', data);
            }
        },
            async addExperiencia({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/experiencias').add(data);
            },
            async updateExperiencia({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/experiencias').doc(id).update(data);
            },
            async deleteExperiencia({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/experiencias').doc(id).delete();
            },

        // PEDIDOS
        // Orders
        async getOrders({commit}){
            if(!this.state.experiencias121.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data;
                await dbClient.collection('/evolbe-modules/experiencias/orders').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                        console.log
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                        data.push(e);
                    })

                    commit('setOrders', data);
                });

            }
        },
        async getOrder({commit}, id){
            if(!this.state.experiencias121.orders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/experiencias/orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.experiencias121.orders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                if(id){
                    await dbClient.collection('/evolbe-modules/experiencias/orders').doc(id).set(data);
                } else {
                    await dbClient.collection('/evolbe-modules/experiencias/orders').add(data);
                }
            },
            async updateOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/orders').doc(id).update(data);
            },
            async deleteOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/orders').doc(id).delete();
            },

        // Stored orders
        async getStoredOrders({commit}){
            if(!this.state.experiencias121.storedOrders.length){
                const searchClient = algoliasearch(
                  this.state.evolbeUser.business[0].algoliaConfig.id,
                  this.state.evolbeUser.business[0].algoliaConfig.key
                );
      
                let data = [];
      
                const index = searchClient.initIndex("experiencias_orders");
                await index
                    .search('')
                    .then(async (res) => {
                        data = res.hits;
      
                        if(res.nbPages > 1){
                          for (let page = 1; page <= res.nbPages; page++) {
                            await index.search('', {page: page})
                              .then((res2) => {
                                  data.push(...res2.hits);
                              })
                              .catch(err => {
                                  console.log(err);
                              });
                          }
                        }
      
                    })
                    .catch(err => {
                        console.log(err);
                    });
      
                  commit('setStoredOrders', data);
              }
        },
        async getStoredOrder({commit}, id){
            if(!this.state.experiencias121.storedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/experiencias/stored_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.experiencias121.storedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/stored_orders').doc(id).set(data);
            },
            async updateStoredOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/stored_orders').doc(id).update(data);
            },
            async deleteStoredOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/stored_orders').doc(id).delete();
            },

        // Abandoned Orders
        async getAbandonedOrders({commit}){
            if(!this.state.experiencias121.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                let data = [];
                await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').orderBy('dateCreated', 'desc').onSnapshot(res => {
                    data = [];
                    res.forEach(item => {
                    const e = {
                        objectID: item.id,
                        ...item.data()
                    }
                    data.push(e);
                    })

                    commit('setAbandonedOrders', data);
                });
            }
        },
        async getAbandonedOrder({commit}, id){
            if(!this.state.experiencias121.abandonedOrders.length){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                const res = await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').doc(id).get();

                const item = res.data();
                const data = {
                    objectID: item.id,
                    ...item
                }
                
                commit('setOrder', data);
            } else {
                const data = this.state.experiencias121.abandonedOrders.find(x => x.objectID === id)
                commit('setOrder', data);
            }
        },
            async addAbandonedOrder({commit}, data){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').add(data);
            },
            async updateAbandonedOrder({commit}, {data, id}){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').doc(id).update(data);
            },
            async deleteAbandonedOrder({commit}, id){
                const dbClient = getFirestoreClient(this.state.evolbeUser);
                await dbClient.collection('/evolbe-modules/experiencias/abandoned_orders').doc(id).delete();
            },


        // SETTINGS
        // Opening Hours
        async getExperienciasHours({commit}){
            //Completar 
        },
            async updateExperienciasHours({commit}){
                //Completar
            },



        // DATA TRANSFORM
        // Update Stored Orders
        async updateStoredOrders({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
            let data = [];

            const res = await dbClient.collection('/evolbe-modules/experiencias/stored_orders').orderBy('dateCreated', 'desc').get();

            data = [];
            res.forEach(item => {
            const e = {
                objectID: item.id,
                ...item.data()
            }
            data.push(e);
            })

            console.log('total:', data.length)
            
            setTimeout(async () => {
                for (let index = 0; index < data.length; index++) {
                    const element = data[index];

                    const id = JSON.parse(JSON.stringify(element.objectID));
                    delete element.objectID;
                    element.dateUpdated = 1640204102000;
                    await dbClient.collection('/evolbe-modules/experiencias/stored_orders').doc(id).update(element);
                    console.log('actualizado ',index,' de ',data.length);
                }
            }, 10000);
        },

        // Orders
        async convertExperiencias({commit}){
            const dbClient = getFirestoreClient(this.state.evolbeUser);
      
            const data = []
            await dbClient.collection('/restaurant-module/pedidos/activos').onSnapshot(async res => {
              console.log(res);
              res.forEach(item => {
                const e = {
                  objectID: item.id,
                  ...item.data()
                }
      
                data.push(e);
              });
      
              data.forEach(element => {
                if(element.pedido){
                  const newItems = [];
      
                  element.pedido.forEach(item => {
                    let newPlatos;
      
                    if(item.subplatos){
                        newPlatos = [];
                        item.subplatos.forEach(subitem => {
                            newPlatos.push({
                                quantity: subitem.quantity || 1,
                                name: subitem.nombre,
                            });
                        });
                    }
                    
      
                    newItems.push({
                      quantity: item.cantidad,
                      name: item.nombre,
                      price: item.precio,
                      images: [
                        {
                          "256": item.imagen,
                          "512": item.imagen,
                          "1024": item.imagen,
                          "2048": item.imagen,
                        }
                      ],
                      items: newPlatos
                    });
                  });
      
                  let newAddress;
                  if(element.direccion_entrega && element.direccion_entrega.direccion.toLowerCase() !== 'recogida'){
                      newAddress = element.direccion_entrega.direccion;

                      if(element.direccion_entrega.numero){
                        newAddress = newAddress + ', ' + element.direccion_entrega.especifico;
                      }

                      if(element.direccion_entrega.numero){
                        newAddress = newAddress + ', ' + element.direccion_entrega.numero
                      }
                  }
      
                  const newData = {
                    customer: {
                      firstName: element.cliente && element.cliente.nombre.trim(),
                      lastName: element.cliente && element.cliente.apellidos.trim(),
                      phone: element.cliente && element.cliente.telefono.trim(),
                      address: newAddress,
                      city: element.direccion_entrega && element.direccion_entrega.ciudad,
                      zipCode: element.direccion_entrega && element.direccion_entrega.postal,
                      uid: element.cliente && element.cliente.uid,
                    },
                    items: newItems,
                    payment: {
                      method: element.metodo_pago && element.metodo_pago.metodo && element.metodo_pago.metodo === 'Tarjeta' && 'stripe' || 'cash',
                      status: 'succeeded',
                    },
                    platform: null,
                    observations: element.observaciones,
                    coupon: element.coupon,
                    status: element.estado,
                    dateCreated: element.fecha_creacion,
                    delivery: element.direccion_entrega && element.direccion_entrega.direccion.toLowerCase() === 'recogida' && false || true,
                    deliveryHour: element.hora_entrega && moment(parseInt(element.hora_entrega)).format('HH:mm'),
                    subtotal: element.resumen && element.resumen.subtotal,
                    shipping: element.resumen && element.resumen.envio,
                    surcharge: 0,
                    tax: element.resumen && element.resumen.iva,
                    total: element.resumen && element.resumen.total
                  }
      
                  console.log(newData);
                  //dbClient.collection('/evolbe-modules/experiencias/orders').doc(element.objectID).set(newData);
                }
              });
          });
        },
    },
}