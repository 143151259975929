<template>
  <ion-page>   
    <ion-content :fullscreen="true">
    <div class="item-details">
        <ion-header class="ion-no-border bb header-details" >
          <ion-toolbar color="transparent">
            <ion-buttons slot="start" style="margin: 0">
              <ion-button @click="closeDetails">
                <ion-icon slot="icon-only" :icon="chevronBackOutline"></ion-icon>
              </ion-button>
            </ion-buttons>
            <h5 style="padding-left: 0.5rem">Editar Elemento</h5>
          </ion-toolbar>
        </ion-header>

        <ion-grid class="mt-sm mb-sm">
          <ion-row class="mb-md">
            <h5 class="edit-title">Detalles</h5>
            <ion-col size="12" class="box-input" :class="{ error: v$.element.name.$errors.length }">
                <label for="name">Nombre</label>
                <InputText class="input" id="name" placeholder="Nombre" type="text" v-model.trim.lazy="v$.element.name.$model" />

                <!-- error message -->
                <div class="input-errors" v-for="(error, index) of v$.element.name.$errors" :key="index">
                  <h6 class="error-msg">{{ error.$message }}</h6>
                </div>
            </ion-col>
            <ion-col size="12" class="box-input" :class="{ error: v$.element.price.$errors.length }">
                <label for="price">Precio</label>
                <InputNumber id="price" class="inputNumber secondary-input" v-model.lazy="v$.element.price.$model" :min="0" showButtons mode="currency" currency="EUR" locale="es-ES" />

                <!-- error message -->
                <div class="input-errors" v-for="(error, index) of v$.element.price.$errors" :key="index">
                  <h6 class="error-msg">{{ error.$message }}</h6>
                </div>
            </ion-col>
          </ion-row>

          <ion-row class="mb-md">
            <ion-col size="12">
              <h5 class="edit-title">Imagen</h5>
                  <FileUpload
                      mode="basic"
                      name="image"
                      :multiple="false"
                      accept="image/*"
                      :maxFileSize="5000000"
                      :customUpload="true"
                      @uploader="handleImageUpload"
                      :auto="true"
                      chooseLabel="Subir Imagen"
                      class="uploader"
                      ref="uploader"
                  />
                    <p style="margin-top: 1rem;" v-if="!images.length">No hay imagen</p>
                    <draggable class="imagenes" :list="images" @change="updateImageList">
                      <div
                        class="imagen-container" v-for="image in images" :key="image"
                      >
                        <Image :src="image.objectURL" alt="image preview" class="imagen" preview />
                        <ion-buttons class="imagen-remover">
                            <ion-button @click="handleImageRemove($event, image.objectURL)">
                            <ion-icon :icon="closeCircleOutline" color="danger"></ion-icon>
                            </ion-button>
                        </ion-buttons>
                      </div>
                    </draggable>
              </ion-col>
          </ion-row>

          <ion-button @click="save" :disabled="v$.element.$invalid" color="primary" expand="block" style="margin-top:1rem" shape="round">
              <ion-icon slot="start" :icon="saveOutline"></ion-icon>
              <ion-label>Guardar</ion-label>
          </ion-button>
        </ion-grid>
      </div>
  </ion-content>
</ion-page>
</template>

<script>
import { IonContent, IonPage, IonGrid, IonRow, IonCol, IonIcon, IonLabel, IonButton, IonButtons, IonHeader, IonToolbar, modalController } from '@ionic/vue';
import { saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline, createOutline, closeOutline, printOutline, arrowForwardCircleOutline, chevronBackOutline } from 'ionicons/icons';
import InputText from 'primevue/inputtext';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InputNumber from 'primevue/inputnumber';

import { defineComponent } from 'vue';

import { VueDraggableNext } from 'vue-draggable-next'

import useVuelidate from '@vuelidate/core'
import { required } from '@/utils/i18n-validators'

import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'EditDish',
  props: ['data'],
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonLabel,
    IonButton,
    IonButtons,

    InputText,
    FileUpload,
    Image,
    IonHeader,
    IonToolbar,
    InputNumber,

    draggable: VueDraggableNext,
  },
  data() {
      return {
        element: {
            name: '',
            img: {},
            price: 0,
        },
        language: 'es',
        images: [],

        dialogImageUrl: '',
        dialogVisible: false,
        languages: [
                {name: 'Español', code: 'es'},
        ],
        lastVariationKey: 0,
        expandedRows: [],
      };
    },
    validations() {
        return {
            element: {
                name: {required},
                price: {required},
            },
        }
    },
    computed: {
        ...mapState(['evolbeUser'])
    },
    methods: {
      ...mapActions('restaurant',['uploadImage']),
      ...mapActions(['uploadImage', 'uploadImageMenu']),

      // Images
      handleImageUpload(event){
        event.files.forEach(file => {
          const epoch = new Date().getTime();
          file.id = epoch + '-' + Math.floor(Math.random() * 1000000);
          
          this.images.push(file);
          this.uploadImage({ruta: '/evolbe-modules/experiencias/extras', img: file});
          this.element.img = {
            '256': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fexperiencias%2Fextras%2Fthumbnails%2F' + file.id + '_256x256.webp?alt=media',
            '512': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fexperiencias%2Fextras%2Fthumbnails%2F' + file.id + '_512x512.webp?alt=media',
            '1024': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fexperiencias%2Fextras%2Fthumbnails%2F' + file.id + '_1024x1024.webp?alt=media',
            '2048': 'https://firebasestorage.googleapis.com/v0/b/' + this.evolbeUser.business[0].firebaseConfig.storageBucket + '/o/evolbe-modules%2Fexperiencias%2Fextras%2Fthumbnails%2F' + file.id + '_2048x2048.webp?alt=media',
          };
        });

        this.$refs.uploader.clear();
      },
      handleImageRemove(event, url) {
        const pos0 = this.images.findIndex(x => x.objectURL === url);
        this.images.splice(pos0, 1);

        this.element.img = {};
      },
      handleImagePreview(event, url) {
        this.dialogImageUrl = url;
        this.dialogVisible = true;
      },

      updateImageList(event){
        const temp = this.element.images[event.moved.oldIndex];
        this.element.images.splice(event.moved.oldIndex, 1);
        this.element.images.splice(event.moved.newIndex, 0, temp);
      },


      // Actions
      save(){
          this.element.dateUpdated = new Date().getTime();
          modalController.dismiss(this.element);
      },

      closeDetails(){
          modalController.dismiss();
      },
      
    },
    beforeMount() {
      this.element = this.data;

      if(this.element && this.element.img && !this.images.length){
        this.images.push({objectURL: this.element.img['512'], status: 'upload'});
      }
    },
  setup() {
    return { v$: useVuelidate(), saveOutline, trashOutline, arrowBackOutline, moveOutline, addCircleOutline, removeCircleOutline, closeCircleOutline, createOutline, closeOutline, printOutline, arrowForwardCircleOutline, chevronBackOutline };
  }
});
</script>

<style scoped>

  .header-aside-container{
      max-width: 96rem;
      margin: 0 auto;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }

  .header-details{
    height: 5rem;
    padding: 1rem 0;
  }

  .edit-title{
    padding-bottom: 1rem;
  }

  .imagenes{
    margin-top: 1rem;
    display: flex;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .imagenes .imagen-container{
    position: relative;
    margin: 0.5rem
  }

  .imagenes .imagen-container .imagen{
    width: 100%;
    height: 148px;
    border-radius: 1rem;
    overflow: hidden;
  }

  .imagenes .imagen-container .imagen-remover{
    position: absolute;
    top: 8px;
    right: 8px;
  }


</style>